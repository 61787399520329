import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table } from 'react-bootstrap';
import { RadioButton } from 'react-common/components';

import UniverseListDropdownContainer from '../../UniverseListDropdown/UniverseListDropdownContainer';

class UniverseResults extends Component {
  state = {
    selectedResult: undefined,
    chosenUniverse: '',
    selectedUniverseId: ''
  };

  selectResult = id => {
    this.setState({ selectedResult: id });
  };

  addToUniverse = () => {
    const { onSelect, results } = this.props;
    const { selectedResult, chosenUniverse } = this.state;
    const company = results.find(r => r._id === selectedResult);

    if (company && chosenUniverse && onSelect) {
      onSelect(company, chosenUniverse);
    }
  };

  componentDidMount() {
    this.setState({
      selectedUniverseId: this.props.selectedUniverseId,
      chosenUniverse: [{ value: this.props.selectedUniverseId }]
    });
  }

  renderResult = result => {
    const { selectedResult, chosenUniverse, selectedUniverseId } = this.state;
    const isSelected = result._id === selectedResult;

    return (
      <tr
        key={result._id}
        className={classnames({ active: isSelected })}
        onClick={() => this.selectResult(result._id)}
      >
        <td className="td-radio">
          <RadioButton checked={isSelected} />
        </td>
        <td className="td-legalName">{result.legal_name}</td>
        <td className="td-address">
          {result.address && result.address.street}
        </td>
        <td className="td-Action">
          {isSelected && (
            <div className="Action">
              {!selectedUniverseId && (
                <div className="Position">
                  <UniverseListDropdownContainer
                    onChange={position =>
                      this.setState({ chosenUniverse: position })
                    }
                  />
                </div>
              )}
              <button
                type="button"
                className="Btn-default Btn-default-primary"
                disabled={!chosenUniverse}
                onClick={this.addToUniverse}
              >
                Add
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  };

  render() {
    const { results } = this.props;

    return (
      <div className="SearchResults">
        <Table responsive>
          <thead>
            <tr>
              <th />
              <th>Company Name</th>
              <th>Address</th>
              <th />
            </tr>
          </thead>
          <tbody>{results.map(this.renderResult)}</tbody>
        </Table>
      </div>
    );
  }
}

UniverseResults.propTypes = {
  additionalProps: PropTypes.object,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      duns: PropTypes.string,
      legal_name: PropTypes.string,
      status: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        street: PropTypes.string,
        municipality: PropTypes.string
      })
    })
  )
};

UniverseResults.defaultProps = { results: [] };

export default UniverseResults;
