import merge from 'lodash/merge';
import get from 'lodash/get';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { stringToPhoneInfo } from '../_utils/string';

import { accountRoles, companyUserRoles } from 'react-common/constants';

const defaultUser = {
  info: {
    email: '',
    name: ''
  }
};

export class User {
  constructor(user = defaultUser) {
    const phoneInfo = stringToPhoneInfo(user.info.phone);
    user.info.phone_ext = phoneInfo.ext;
    user.info.phone_work = phoneInfo.phone;

    merge(this, user);
  }

  getId() {
    return this._id;
  }

  getImage() {
    return get(this, 'info.image');
  }

  getEmail() {
    return get(this, 'info.email');
  }

  getTitle() {
    return get(this, 'info.title');
  }

  getAccountRole() {
    return get(this, 'security.role');
  }

  isAdmin() {
    return this.getAccountRole() === accountRoles.ADMIN;
  }

  isAnalyst() {
    return this.getAccountRole() === accountRoles.ANALYST;
  }

  isAdminOrAnalyst() {
    return this.isAdmin() || this.isAnalyst();
  }

  getName() {
    return get(this, 'info.name') || '';
  }

  getFirstName() {
    return first(this.getName().split(' '));
  }

  getLanguage() {
    return get(this, 'preferences.language') || 'en';
  }

  getCompany() {
    return first(this.companies) || {};
  }

  getCompanyId() {
    return this.getCompany().company_id;
  }

  getCompanyRole() {
    return this.getCompany().role;
  }

  isCompanyRole(role) {
    return this.getCompanyRole() === role;
  }

  isCompanyAdmin() {
    return this.isCompanyRole(companyUserRoles.COMPANY_ADMIN);
  }

  isCompanyUser() {
    return this.isCompanyRole(companyUserRoles.COMPANY_USER);
  }

  isCompanyPending() {
    return this.isCompanyRole(companyUserRoles.COMPANY_PENDING);
  }

  hasCompany() {
    return this.isCompanyAdmin() || this.isCompanyUser();
  }

  getOpalCredentials() {
    return get(this, 'subscription.credentials');
  }

  hasOpalCredentials() {
    return !isEmpty(this.getOpalCredentials());
  }
}
