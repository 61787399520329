import { hasPendingClaim } from './claims';
import { hasPendingDuns } from './dunsRequests';
import { hasUncompletedOnboardings } from './workflows';
import { getUpdateRequest } from './updateRequests';
import { urlConstants } from '../_constants/ui';
import { getUser } from './user';

export const getUI = state => state.ui;
export const getDunsFileUploadsState = state => getUI(state).dunsRequest.files;

export const getInitialUrl = state => getUI(state).initialUrl;
export const getCompletedWalkthroughSteps = state =>
  getUI(state).completedWalkthroughSteps;

export const getMountedWalkthroughsCount = state =>
  getUI(state).mountedWalkthroughsCount;

export const getRedirectMap = state => {
  const pendingDuns = hasPendingDuns(state);
  const pendingClaim = hasPendingClaim(state);
  const hasUpdateRequest = getUpdateRequest(state);
  const hasOnboardings = hasUncompletedOnboardings(state);
  const user = getUser(state);
  const hasCompany = user.hasCompany() || hasUpdateRequest;

  let getStartedRedirect = '/';
  if (pendingDuns) {
    getStartedRedirect = urlConstants.DUNS;
  } else if (hasCompany) {
    getStartedRedirect = urlConstants.HOME;
  } else if (pendingClaim) {
    getStartedRedirect = urlConstants.CLAIM;
  } else if (hasOnboardings) {
    getStartedRedirect = urlConstants.ONBOARDING;
  }

  return {
    [urlConstants.ONBOARDING]: urlConstants.HOME,
    [urlConstants.MONITORING]: urlConstants.HOME,
    [urlConstants.DBIA]: urlConstants.HOME,
    [urlConstants.SEARCH]: urlConstants.HOME,
    [urlConstants.UPDATE]: urlConstants.HOME,
    [urlConstants.HOME]: urlConstants.GET_STARTED,
    [urlConstants.DUNS]: urlConstants.GET_STARTED,
    [urlConstants.CLAIM]: urlConstants.GET_STARTED,
    [urlConstants.GET_STARTED]: getStartedRedirect
  };
};
