import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import Wizard from '../_components/Wizard/Wizard';
import { updateHeaderBreadcrumbs } from '../_actions';
import { wizardSteps } from '../_constants/claim';
import {
  claimCompany,
  getClaimsForAccount,
  markClaimRequestAsSeen
} from '../_actions/claims';
import { getClaim } from '../_selectors/claims';
import { getUserEmail, getUserPhone } from '../_selectors/user';
import { viewGrants } from '../_constants/grants';
import Find from './Find/Find';
import Confirmation from './Confirmation/Confirmation';
import Connected from './Connected/Connected';
import './ClaimDunsNumber.css';
import '../GetDunsNumber/Created/Created.css';
import { withPermissions } from '../_components/Grant/Grant';
import PageNotFound from '../PageNotFound';

class ClaimDunsNumberContainer extends Component {
  state = {
    claimedCompany: null,
    claimRequested: false
  };

  steps = [
    {
      key: wizardSteps.FIND,
      title: 'Find company',
      component: Find
    },
    {
      key: wizardSteps.VERIFICATION,
      title: 'Confirmation call',
      component: () => Confirmation(this.props.userPhone)
    },
    {
      key: wizardSteps.DELIVERY,
      title: 'Company connected',
      component: Connected
    }
  ];

  componentDidMount() {
    const {
      dispatchUpdateHeaderBreadcrumbs,
      dispatchGetClaimsForAccount
    } = this.props;
    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Get Started',
        path: '/get-started'
      },
      {
        title: 'Connect your Company'
      }
    ]);
    dispatchGetClaimsForAccount();
  }

  getActiveStepKey = () => {
    const { claim } = this.props;
    const { claimRequested } = this.state;

    if (!claim) {
      return wizardSteps.FIND;
    }

    if (claimRequested || !claim.approved) {
      return wizardSteps.VERIFICATION;
    }

    return wizardSteps.DELIVERY;
  };

  handleClaimRequest = (company, companyRole) => {
    const {
      dispatchClaimCompany,
      dispatchGetClaimsForAccount,
      userEmail
    } = this.props;

    dispatchClaimCompany(company, userEmail, companyRole).then(() => {
      dispatchGetClaimsForAccount();
      this.setState({ claimRequested: true });
    });
    this.setState({ claimedCompany: company });
  };

  markAsSeen = () => {
    const { claim, dispatchMarkClaimRequestAsSeen } = this.props;

    if (claim) {
      dispatchMarkClaimRequestAsSeen(claim._id);
    }
  };

  render() {
    const { claimedCompany } = this.state;

    return (
      <AppWrapper>
        <Wizard
          className="ClaimDunsNumber"
          activeStepKey={this.getActiveStepKey()}
          steps={this.steps}
          props={{
            claimedCompany,
            onClaimRequest: this.handleClaimRequest,
            onClaimSeen: this.markAsSeen
          }}
        />
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => ({
  claim: getClaim(state),
  userEmail: getUserEmail(state),
  userPhone: getUserPhone(state)
});

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchClaimCompany: claimCompany,
  dispatchGetClaimsForAccount: getClaimsForAccount,
  dispatchMarkClaimRequestAsSeen: markClaimRequestAsSeen
};

ClaimDunsNumberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimDunsNumberContainer);

export default withPermissions(viewGrants.CLAIM, PageNotFound)(
  ClaimDunsNumberContainer
);
