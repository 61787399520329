import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './WizardProgress.css';

class WizardProgress extends Component {
  renderStep = (step, index, steps) => {
    const { activeStepKey } = this.props;
    const activeStepIndex = steps.findIndex(step => step.key === activeStepKey);
    const isActiveStep = index === activeStepIndex;
    const isCompletedStep = index < activeStepIndex;
    const isDisabledStep = index > activeStepIndex;

    return (
      <div
        key={step.key}
        className={`WizardProgress-step col-wizard-${steps.length} ${
          isActiveStep ? 'active' : ''
        } ${isDisabledStep ? 'disabled' : ''} ${
          isCompletedStep ? 'complete' : ''
        }`}
      >
        <div className="progress">
          <div className="progress-bar" />
        </div>
        <div className="WizardProgress-dot WizardProgress-stepnum">
          {isCompletedStep ? (
            <span>
              <i className="icon-ic_check" />
            </span>
          ) : (
            <span className="number">{index + 1}</span>
          )}
        </div>
        <div className="WizardProgress-info text-center">{step.title}</div>
      </div>
    );
  };

  render() {
    const { steps } = this.props;

    return (
      <div className="row">
        <div className="col-xs-12 col-lg-offset-2 col-lg-8">
          <div className="WizardProgress">{steps.map(this.renderStep)}</div>
        </div>
      </div>
    );
  }
}

WizardProgress.propTypes = {
  steps: PropTypes.arrayOf(String).isRequired,
  activeStepKey: PropTypes.string.isRequired
};

WizardProgress.defaultProps = {
  steps: [],
  activeStepKey: ''
};

export default WizardProgress;
