import { Auth, Localize, Toast } from 'react-common/services';
import { makeRequest } from './api';
import { push } from 'connected-react-router';
import { urlConstants } from '../_constants/ui';

import UserService from '../_services/User';
import userConstants from '../_constants/user';

export function changePassword(oldPass, newPass) {
  return makeRequest({
    type: userConstants.CHANGE_PASSWORD,
    apiCall: UserService.changePassword,
    params: [oldPass, newPass],
    onSuccess: () => Toast.showSuccess('Password successfully changed!'),
    onFailure: (dispatch, error) => Toast.showWarning(`${error.error}`)
  });
}

function changeAccountSettingsSuccess() {
  Toast.showSuccess('Your account has been successfully updated.');
  return (dispatch, account) => {
    Auth.setUser(account);
    Localize.setLanguage(account.preferences.language);
  };
}

export function changeAccountSettings(account) {
  return makeRequest({
    type: userConstants.UPDATE_USER,
    apiCall: UserService.changeAccountSettings,
    params: [account],
    onSuccess: changeAccountSettingsSuccess(),
    onFailure: (dispatch, error) => Toast.showWarning(`${error.error}`)
  });
}

function reloadSuccess(shouldRedirect = true) {
  return (dispatch, data) => {
    UserService.connectWithPlugins(data.user);
    if (shouldRedirect) {
      dispatch(push(urlConstants.HOME));
    }
  };
}

export function reloadUser(shouldRedirect = true) {
  return makeRequest({
    type: userConstants.RELOAD,
    apiCall: UserService.reload,
    onSuccess: reloadSuccess(shouldRedirect)
  });
}

export function getUserByRegistrationToken(registrationToken) {
  return makeRequest({
    type: userConstants.GET_USER_BY_REGISTRATION_TOKEN,
    apiCall: UserService.getUserByRegistrationToken,
    params: [registrationToken]
  });
}
