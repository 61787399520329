import get from 'lodash/get';

export const getBirRequests = state => state.birRequests.items;

export const getBirRequest = state => {
  const requests = getBirRequests(state);

  return Object.values(requests)[0];
};

export const getBirRequestFiles = state => {
  const birRequest = getBirRequest(state);
  const files = get(birRequest, 'files', {})._files;
  if (!files) {
    return {};
  }
  return files.reduce((map, file) => {
    map[file.type] = file;
    return map;
  }, {});
};
