import React from 'react';
import { DunsNumberAbbr } from 'react-common/components';
import imgGetDuns from '../../../_images/ic_get_duns.svg';
import ProductOptionBox from '../../../_components/ProductOption/ProductOptionBox';
import { dunsRequestIssueDuration } from '../../../_constants/dunsRequest';
import '../Search.css';

const ClaimNoResults = () => (
  <div className="col-xs-12 col-lg-offset-1 col-lg-10 SearchNoResults">
    <div className="row mbot-20 SearchNoResults-header">
      <h4>Ooops! No results found.</h4>
      <p>
        It looks like your company doesn’t have a <DunsNumberAbbr />. You will
        need one in order to claim your business on MyCial. Choose one of the
        options below to get a <DunsNumberAbbr />.
      </p>
    </div>

    <div className="row mtop-50">
      <div className="col-xs-12 col-md-4 col-md-offset-2">
        <ProductOptionBox
          highlighted
          img={imgGetDuns}
          title={
            <span>
              Get <DunsNumberAbbr /> within&nbsp;
              {dunsRequestIssueDuration.EXPRESS} days
            </span>
          }
          body={
            <span>
              You will receive your <DunsNumberAbbr />{' '}
              {dunsRequestIssueDuration.EXPRESS}
              &nbsp;days after CIAL Dun & Bradstreet receives your data.
            </span>
          }
          linkTo="/duns?type=express"
          linkText={
            <span>
              Get <DunsNumberAbbr />
            </span>
          }
        />
      </div>

      <div className="col-xs-12 col-md-4">
        <ProductOptionBox
          img={imgGetDuns}
          title={
            <span>
              Get <DunsNumberAbbr /> within&nbsp;
              {dunsRequestIssueDuration.FREE} days
            </span>
          }
          body={
            <span>
              You will receive your <DunsNumberAbbr />{' '}
              {dunsRequestIssueDuration.FREE}
              &nbsp;days after CIAL Dun & Bradstreet receives your data.
            </span>
          }
          linkTo="/duns?type=free"
          linkText={
            <span>
              Get <DunsNumberAbbr />
            </span>
          }
        />
      </div>
    </div>
  </div>
);

export default ClaimNoResults;
