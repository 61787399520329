const searchConstants = {
  SEARCH_RESET: 'SEARCH_RESET',
  SEARCH_UPDATE: 'SEARCH_UPDATE',
  SEARCH_NAME_UPDATE: 'SEARCH_NAME_UPDATE',
  SEARCH_COUNTRY_UPDATE: 'SEARCH_COUNTRY_UPDATE',
  SEARCH_STATE_UPDATE: 'SEARCH_STATE_UPDATE',
  SEARCH_PAGE_UPDATE: 'SEARCH_PAGE_UPDATE',
  SEARCH_SIZE_UPDATE: 'SEARCH_SIZE_UPDATE',
  SEARCH: 'SEARCH',
  SEARCH_RESET_RESULTS: 'SEARCH_RESET_RESULTS'
};

export default searchConstants;
