import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  createOrder,
  showDbiaReportModal,
  getOrdersWithQuery
} from '../../_actions/dbia';
import { MomentDate, Loader } from 'react-common/components';
import { deliveryCodes, productTypes } from '../../_constants/dbia';
import EmailModal from '../EmailModal/EmailModal';
import { walkthroughSteps } from '../../_constants/walkthrough';
import Walkthrough from '../Walkthrough/Walkthrough';
import ScreenModal from '../Dbia/DbiaScreenModal';
import ConfirmModal from '../Dbia/DbiaConfirmModal';

const availableWalkthroughSteps = [
  {
    target: '#button-order-0-0',
    stepData: walkthroughSteps.companySearch.orderReport
  }
];

class DbiaCompany extends Component {
  state = {};

  createOrder = (
    product = {},
    company = {},
    language,
    {
      view = false,
      emails = [],
      shareholders = [],
      forceInvestigation = false,
      doNotInvestigate = false
    } = {}
  ) => {
    const { dispatchCreateOrder } = this.props;

    this.hideEmails();

    const order = {
      reportType: product.Code,
      countryCode: company.Country,
      dunsNumber: company.Duns,
      companyName: company.FullName,
      deliveryCode: view ? deliveryCodes.VIEW_ON_SCREEN : deliveryCodes.WEBHOOK,
      language,
      emails,
      forceInvestigation,
      doNotInvestigate,
      shareholders
    };
    const options = {
      onStart: () => this.setState({ ordering: true }),
      onEnd: () => this.setState({ ordering: false }),
      onSuccess: order => view && this.viewReport(order)
    };
    dispatchCreateOrder({ order, options });
  };

  handleCreateOrder = order => {
    const { product, company, language, options } = order;

    this.createOrder(product, company, language.Code, options);
  };

  viewReport = order => {
    const { dispatchShowDbiaReportModal } = this.props;
    return dispatchShowDbiaReportModal(order);
  };

  handleOpenScreenModal = order => {
    this.hideEmails();

    this.setState({
      showScreenModal: true,
      currentOrder: order
    });
  };

  handleCloseScreenModal = () =>
    this.setState({
      showScreenModal: false,
      currentOrder: null
    });

  openEmailModal = order =>
    this.setState({
      showEmailModal: true,
      currentOrder: order
    });

  hideEmails = () =>
    this.setState({ showEmailModal: false, currentOrder: null });

  viewOrders = order => {
    const { dispatchGetDbiaOrdersWithQuery } = this.props;
    return dispatchGetDbiaOrdersWithQuery(order);
  };

  handleOpenConfirmModal = (date, order) =>
    this.setState({
      showConfirmModal: true,
      lastOrderDate: date,
      currentOrder: order
    });

  handleCloseConfirmModal = () =>
    this.setState({
      showConfirmModal: false,
      lastOrderDate: null
    });

  handleClickOrder = order => {
    this.viewOrders(order).then(orders => {
      const isCompliance = order.product.Code === productTypes.CFR;

      if (orders.length) {
        return this.handleOpenConfirmModal(orders[0].created_at, order);
      }

      isCompliance
        ? this.handleOpenScreenModal(order)
        : this.handleCreateOrder(order);
    });
  };

  isoLanguageCodes = {
    PO: 'PT'
  };

  renderBadge = language => {
    let badgeText, badgeClass;

    if (!language.IsAvailable) {
      badgeText = 'Not investigated';
      badgeClass = 'badge';
    } else if (language.IsPreliminary) {
      badgeText = 'Preliminary available';
      badgeClass = 'badge danger';
    } else {
      badgeText = 'Available';
      badgeClass = 'badge active';
    }

    return <span className={badgeClass}>{badgeText}</span>;
  };

  renderCompanyDetailsRow = (company, columnCount) => {
    return (
      <tr className="no-border">
        <td colSpan={columnCount}>
          <Table>
            <tbody>
              <tr className="no-border">
                <td colSpan="4">
                  <div>
                    Phone:{' '}
                    {[
                      company.Phone && company.Phone.CountryCode,
                      company.Phone && company.Phone.AreaCode,
                      company.Phone && company.Phone.Number
                    ]
                      .filter(Boolean)
                      .join(' ')}
                  </div>
                  <div>
                    Area:{' '}
                    {[company.Address, company.State, company.Postcode]
                      .filter(Boolean)
                      .join(', ')}
                  </div>
                  <div>Country: {company.Country}</div>
                </td>
                <td colSpan="2">
                  <div>
                    Last Financial Date:
                    {company.FinancialDate ? (
                      <MomentDate
                        format=" DD/MM/YYYY"
                        date={company.FinancialDate}
                      />
                    ) : (
                      'N/A'
                    )}
                  </div>
                  <div>
                    Last Update Date:
                    {company.ReportDate ? (
                      <MomentDate
                        format=" DD/MM/YYYY"
                        date={company.ReportDate}
                      />
                    ) : (
                      'N/A'
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </td>
      </tr>
    );
  };

  renderReportOptionsRow = (company, columnCount) => {
    const {
      showEmailModal,
      showScreenModal,
      showConfirmModal,
      lastOrderDate,
      currentOrder = {}
    } = this.state;

    return (
      <tr>
        <td colSpan={columnCount}>
          <Table className="reportsPurchaseTableInner">
            <thead>
              <tr>
                <th className="th-report">Report</th>
                <th className="th-language">Language</th>
                <th className="th-availability">Availability</th>
                <th className="th-order">Order</th>
              </tr>
            </thead>
            <tbody>
              <EmailModal
                showModal={showEmailModal}
                onHideModal={this.hideEmails}
                onSubmit={emails => {
                  const isCompliance =
                    currentOrder.product.Code === productTypes.CFR;

                  isCompliance
                    ? this.handleOpenScreenModal({
                        ...currentOrder,
                        options: { emails }
                      })
                    : this.handleCreateOrder({
                        product: currentOrder.product,
                        company: currentOrder.company,
                        language: currentOrder.language.Code,
                        options: { emails }
                      });
                }}
                isPreliminary={
                  currentOrder &&
                  currentOrder.language &&
                  currentOrder.language.IsPreliminary
                }
              />

              <ScreenModal
                show={showScreenModal}
                handleClose={this.handleCloseScreenModal}
                onSubmit={shareholders =>
                  this.handleCreateOrder({
                    product: currentOrder.product,
                    company: currentOrder.company,
                    language: currentOrder.language.Code,
                    options: {
                      ...currentOrder.options,
                      shareholders,
                      view: false
                    }
                  })
                }
              />

              <ConfirmModal
                date={lastOrderDate}
                show={showConfirmModal}
                handleClose={this.handleCloseConfirmModal}
                onSubmit={() => {
                  const isCompliance =
                    currentOrder.product.Code === productTypes.CFR;

                  isCompliance
                    ? this.handleOpenScreenModal(currentOrder)
                    : this.handleCreateOrder(currentOrder);
                }}
              />

              {company.Products && company.Products.length ? (
                company.Products.map((product, productIndex) =>
                  product.Languages.map((language, languageIndex) => {
                    const orderBtnId = `button-order-${productIndex}-${languageIndex}`;

                    return (
                      <tr key={product.Code + language.Code}>
                        <td>{product.Description}</td>
                        <td>
                          {this.isoLanguageCodes[language.Code] ||
                            language.Code}
                        </td>
                        <td>{this.renderBadge(language)}</td>
                        <td>
                          {!language.IsAvailable && (
                            <button
                              id={orderBtnId}
                              disabled={this.state.ordering}
                              className="Btn-outline-small Btn-outline-primary"
                              onClick={() =>
                                this.handleClickOrder({
                                  product,
                                  company,
                                  language
                                })
                              }
                            >
                              Order investigation
                            </button>
                          )}
                          {language.IsAvailable && (
                            <button
                              id={orderBtnId}
                              disabled={this.state.ordering}
                              className="Btn-outline-small Btn-outline-primary"
                              onClick={() =>
                                this.handleClickOrder({
                                  product,
                                  company,
                                  language,
                                  options: {
                                    view: true,
                                    doNotInvestigate: language.IsPreliminary
                                  }
                                })
                              }
                            >
                              Order report
                            </button>
                          )}
                          <button
                            disabled={this.state.ordering}
                            className="Btn-outline-small Btn-outline-primary"
                            onClick={() =>
                              this.openEmailModal({
                                product,
                                company,
                                language
                              })
                            }
                          >
                            Order and email report
                          </button>

                          {language.IsAvailable && (
                            <button
                              disabled={this.state.ordering}
                              className="Btn-outline-small Btn-outline-primary"
                              onClick={() =>
                                this.handleClickOrder({
                                  product,
                                  company,
                                  language,
                                  options: {
                                    forceInvestigation: !language.IsPreliminary
                                  }
                                })
                              }
                            >
                              Order re-investigation
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan="7">No reports</td>
                </tr>
              )}
            </tbody>
          </Table>
        </td>
      </tr>
    );
  };

  render() {
    const { company, columnCount } = this.props;

    return (
      company && (
        <tr>
          <td colSpan="7" className="company-rows">
            <Loader loading={this.state.ordering || false} withOverlay />
            <Walkthrough availableSteps={availableWalkthroughSteps} />

            <Table>
              <tbody>
                {this.renderCompanyDetailsRow(company, columnCount)}
                {this.renderReportOptionsRow(company, columnCount)}
              </tbody>
            </Table>
          </td>
        </tr>
      )
    );
  }
}

DbiaCompany.propTypes = {
  company: PropTypes.shape({
    Phone: PropTypes.shape({}),
    Products: PropTypes.array,
    Address: PropTypes.string,
    State: PropTypes.string,
    Country: PropTypes.string,
    Postcode: PropTypes.string,
    FinancialDate: PropTypes.string,
    ReportDate: PropTypes.string
  })
};

DbiaCompany.defaultProps = { company: { Phone: {}, Products: [] } };

const mapDispatchToProps = {
  dispatchCreateOrder: createOrder,
  dispatchShowDbiaReportModal: showDbiaReportModal,
  dispatchGetDbiaOrdersWithQuery: getOrdersWithQuery
};

export default connect(
  null,
  mapDispatchToProps
)(DbiaCompany);
