import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { Link } from 'react-router-dom';

import AppWrapper from '../_components/AppWrapper/AppWrapper';
import PublicHeader from '../_components/Header/PublicHeader';
import notFoundPic from '../_images/404.svg';

import './PageNotFound.css';

class PageNotFound extends Component {
  render() {
    if (!Auth.isLocalStorageSupported()) {
      return (
        <div className="PageNotFound">
          <img src={notFoundPic} alt="Page not found" />
          <p>
            Oops! It seems you have disabled local storage on your browser.
            MyCIAL requires local storage to be enabled in order to provide the
            best experience.
          </p>
        </div>
      );
    }

    if (Auth.isUserAuthenticated()) {
      return (
        <AppWrapper>
          <div className="PageNotFound">
            <img src={notFoundPic} alt="Page not found" />
            <p>Oops! Page not found.</p>
            <Link to="/home">
              <button className="Btn-default Btn-default-primary ">
                Go Home
              </button>
            </Link>
          </div>
        </AppWrapper>
      );
    }

    return (
      <div className="row">
        <PublicHeader className="col-md-12" />
        <div className="PageNotFound">
          <img src={notFoundPic} alt="Page not found" />
          <p>Oops! Page not found.</p>
          <Link to="/login">
            <button className="Btn-default Btn-default-primary ">
              Go to Login
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
