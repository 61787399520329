import { hasPendingDuns } from './dunsRequests';
import { hasPendingClaim } from './claims';
import { hasUncompletedOnboardings } from './workflows';
import { getUser, hasPermissionForPulse } from './user';
import { viewGrants } from '../_constants/grants';
import { getUpdateRequest } from './updateRequests';

export const getActivePermissions = state => {
  const pendingDuns = hasPendingDuns(state);
  const pendingClaim = hasPendingClaim(state);
  const hasOnboardings = hasUncompletedOnboardings(state);
  const hasUpdateRequest = getUpdateRequest(state);
  const hasPulsePermission = hasPermissionForPulse(state);
  const user = getUser(state);
  const hasCompany = user.hasCompany() || hasUpdateRequest;

  const permissions = {
    [viewGrants.ADMIN]: user.isAdmin(),
    [viewGrants.DBIA_ORDERS]:
      user.hasOpalCredentials() && user.isAdminOrAnalyst(),
    [viewGrants.HOME]: hasCompany,
    [viewGrants.DBIA]: hasCompany,
    [viewGrants.SEARCH]: hasCompany,
    [viewGrants.MONITORING]: hasPulsePermission,
    [viewGrants.ONBOARDINGS]: hasOnboardings,
    [viewGrants.GET_STARTED]:
      !hasUpdateRequest &&
      !hasOnboardings &&
      !hasCompany &&
      !pendingClaim &&
      !pendingDuns,
    [viewGrants.DUNS]:
      (!hasCompany && !pendingClaim) || (hasCompany && pendingDuns),
    [viewGrants.CLAIM]: !hasCompany && !pendingDuns,
    [viewGrants.UPDATE]: hasUpdateRequest
  };

  return Object.keys(permissions).filter(k => permissions[k]);
};
