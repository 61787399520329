import universeConstants from '../_constants/universe';
import { Util } from 'react-common/services';

const defaultState = {
  isFetching: false,
  items: {},
  changes: {},
  errors: ''
};

export function universe(state = defaultState, action) {
  switch (action.type) {
    case universeConstants.FETCH_ALL:
      return {
        ...state,
        items: Util.convertArrayToMap(action.data.universes),
        duns: Util.convertArrayToMap(action.data.duns, 'duns')
      };
    case universeConstants.FETCH_BY_ID:
      return {
        ...state,
        items: Util.convertArrayToMap([action.data])
      };
    case universeConstants.FETCH_CHANGES_BY_UNIVERSE_ID:
      return {
        ...state,
        changes: action.data.changes
      };
    case universeConstants.ADD_DUNS_TO_UNIVERSE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.data.universe['_id']]: action.data.universe
        },
        duns: {
          ...state.duns,
          [action.data.duns['duns']]: action.data.duns
        }
      };
    case universeConstants.REMOVE_DUNS_FROM_UNIVERSE:
      state.items[action.data.universe['_id']].duns = state.items[
        action.data.universe['_id']
      ].duns.filter(dn => dn !== action.data.duns.duns);
      return {
        ...state
      };
    case universeConstants.FETCH_ALL_CHANGES:
      return {
        ...state,
        changes: Util.convertArrayToMap(action.data, 'id')
      };
    case universeConstants.DELETE_UNIVERSE:
      const { [action.data.universeId]: value, ...items } = state.items;
      return {
        ...state,
        items
      };
    default:
      return state;
  }
}
