import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import phoneImg from '../../_images/phone.svg';

class DunsNumberConfirmation extends Component {
  render() {
    const { userPhone } = this.props;
    return (
      <div className="GetDunsNumber WizardPage">
        <div className="call-verification-step">
          <h1>
            Great! Your information has been submitted. Next, we will call you
            to confirm your information.
          </h1>
          <p>
            One of our business consultants will be in touch with you to confirm
            your details. <br />
            <br />
            You should expect a call at{' '}
            <strong notranslate="true">{userPhone}</strong>
            . <br />
            To change your number go to{' '}
            <Link to="/settings/profile">account settings</Link>.
          </p>
          <p className="text-center">
            <img
              alt="Verification call"
              className="svg-confirmation-call"
              src={phoneImg}
            />
          </p>
        </div>
      </div>
    );
  }
}

export default connect()(DunsNumberConfirmation);
