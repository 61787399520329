import userConstants from '../_constants/user';
import updateRequestConstants from '../_constants/updateRequest';
import { Util } from 'react-common/services';

export function updateRequests(
  state = {
    items: {}
  },
  action
) {
  switch (action.type) {
    case userConstants.RELOAD:
      return {
        ...state,
        items: {
          ...state.items,
          ...Util.convertArrayToMap(action.data.update_requests || [])
        }
      };
    case updateRequestConstants.GET_UPDATE_REQUESTS_FOR_ACCOUNT:
      return {
        ...state,
        items: Util.convertArrayToMap(action.data)
      };
    case updateRequestConstants.DELETE_UPDATE_REQUEST_FILE:
    case updateRequestConstants.UPLOAD_UPDATE_REQUEST_FILE:
    case updateRequestConstants.GET_ADMIN_UPDATE_REQUEST:
      return {
        ...state,
        items: Util.convertArrayToMap(Array.of(action.data))
      };
    case updateRequestConstants.SUBMIT_UPDATE_REQUEST:
      const updateRequest = action.data;
      return {
        ...state,
        items: {
          ...state.items,
          [updateRequest._id]: updateRequest
        }
      };
    default:
      return state;
  }
}
