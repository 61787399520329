import get from 'lodash/get';
import { hasSubmittedUpdateRequest } from './updateRequests';

export const getUser = state => state.user.user;

export const getUserInfo = state => get(getUser(state), 'info', {});

export const getUserCompanies = state => get(getUser(state), 'companies', []);

export const getUserEmail = state => get(getUserInfo(state), 'email', '');

export const getUserPhone = state => get(getUserInfo(state), 'phone_work', '');

export const hasPermissionForPulse = state =>
  get(getUser(state), 'pulse.enabled', false);

export const hasCompany = state => {
  const submittedUpdateRequest = hasSubmittedUpdateRequest(state);

  return getUserCompanies(state).length || submittedUpdateRequest;
};
