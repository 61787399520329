import React, { Component } from 'react';
import { MenuItem, NavDropdown } from 'react-bootstrap';
import { languages } from 'react-common/constants';
import { Localize } from 'react-common/services';

class LanguageComponent extends Component {
  state = {
    selectedLanguage: Localize.getLanguage()
  };

  handleOnLanguageSelect = language => {
    const { onLanguageSelect } = this.props;
    this.setState({ selectedLanguage: language });
    Localize.setLanguage(language);
    onLanguageSelect && onLanguageSelect(language);
  };

  render() {
    return (
      <NavDropdown
        id="language-nav-dropdown"
        eventKey={1}
        title={languages[this.state.selectedLanguage].short}
        onSelect={this.handleOnLanguageSelect}
      >
        {Object.keys(languages)
          .filter(key => languages[key].enabled)
          .map(key => (
            <MenuItem eventKey={key} key={key}>
              {languages[key].full}
            </MenuItem>
          ))}
      </NavDropdown>
    );
  }
}

export default LanguageComponent;
