import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BirFormContainer } from 'react-common/components';
import {
  getUpdateRequest,
  getUpdateRequestFiles
} from '../_selectors/updateRequests';
import { getUser } from '../_selectors/user';
import {
  updateUpdateRequest,
  fieldUpdateUpdateRequest,
  submitUpdateRequest,
  uploadUpdateRequestFile,
  deleteUpdateRequestFile
} from '../_actions/updateRequests';
import get from 'lodash/get';
import includes from 'lodash/includes';

class UpdateRequest extends Component {
  constructor(props) {
    super(props);

    let initialValues = { ...props.company };

    this.state = {
      uploadingFiles: [],
      initialValues
    };
  }

  updateForm = (name, value, file) => {
    const {
      files,
      updateRequestId,
      dispatchFieldUpdateUpdateRequest,
      dispatchDeleteUpdateRequestFile
    } = this.props;

    if (!name) {
      return;
    }
    if (file) {
      return this.uploadFile(name, file);
    }
    if (!value && files[name]) {
      return dispatchDeleteUpdateRequestFile(updateRequestId, name);
    }

    return dispatchFieldUpdateUpdateRequest(updateRequestId, { [name]: value });
  };

  uploadFile = (name, file) => {
    const { updateRequestId, dispatchUploadUpdateRequestFile } = this.props;

    const fileData = new FormData();
    fileData.append(name, file);
    this.setState({ uploadingFiles: [...this.state.uploadingFiles, name] });

    return dispatchUploadUpdateRequestFile(
      updateRequestId,
      name,
      fileData,
      this.removeFromUploadingList
    );
  };

  removeFromUploadingList = fileName => {
    this.setState({
      uploadingFiles: this.state.uploadingFiles.filter(
        field => !includes(fileName, field)
      )
    });
  };

  submitStepForm = (updateRequest, step) => {
    const { updateRequestId, dispatchUpdateUpdateRequest } = this.props;
    return dispatchUpdateUpdateRequest(updateRequestId, {
      company: updateRequest,
      step: step
    });
  };

  submitForm = updateRequest => {
    const { updateRequestId, dispatchSubmitUpdateRequest } = this.props;

    return dispatchSubmitUpdateRequest(updateRequestId, {
      company: updateRequest
    });
  };

  render() {
    const { uploadingFiles, initialValues } = this.state;
    const { files, currentStep, user } = this.props;

    return (
      <BirFormContainer
        initialValues={initialValues}
        country="CIALOnlineInvestigation"
        onChange={this.updateForm}
        onSubmit={this.submitForm}
        onStepSubmit={this.submitStepForm}
        currentStep={currentStep}
        uploadingFiles={uploadingFiles}
        files={files}
        language={user.getLanguage()}
      />
    );
  }
}

const mapStateToProps = state => {
  const updateRequest = getUpdateRequest(state);
  const updateRequestId = updateRequest._id;

  return {
    updateRequestId,
    files: getUpdateRequestFiles(state),
    company: get(updateRequest, 'company', {}),
    currentStep: get(updateRequest, 'step', ''),
    user: getUser(state)
  };
};

const mapDispatchToProps = {
  dispatchUpdateUpdateRequest: updateUpdateRequest,
  dispatchFieldUpdateUpdateRequest: fieldUpdateUpdateRequest,
  dispatchSubmitUpdateRequest: submitUpdateRequest,
  dispatchUploadUpdateRequestFile: uploadUpdateRequestFile,
  dispatchDeleteUpdateRequestFile: deleteUpdateRequestFile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateRequest);
