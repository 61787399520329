import userConstants from '../_constants/user';
import { Workflow } from '../_models/workflow';

export function workflows(
  state = {
    active_onboardings: []
  },
  action
) {
  switch (action.type) {
    case userConstants.RELOAD:
      return {
        ...state,
        active_onboardings: (action.data.active_onboardings || []).map(w => {
          return Object.assign(new Workflow(w));
        })
      };
    default:
      return state;
  }
}
