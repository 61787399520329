import React, { Component } from 'react';
import { MomentDate } from 'react-common/components';
import { Localize } from 'react-common/services';

import { Link } from 'react-router-dom';
import './UniverseList.css';

class UniverseList extends Component {
  render() {
    const { universes } = this.props;

    return (
      <div className="UniverseList">
        {universes &&
          Object.keys(universes).map(universeId => {
            return (
              <div key={universeId}>
                <Link to={`/universes/${universeId}`}>
                  <div className="Universe-button">
                    <div className="row">
                      <div className="col-xs-12 col-lg-6">
                        <span className="Title">
                          {universes[universeId].name}
                        </span>
                      </div>
                    </div>
                    <div className="row Info">
                      {/*TODO matej add this*/}
                      {/*<div className="col-xs-12 col-lg-4">*/}
                      {/*<span>*/}
                      {/*<b>Last update:</b>*/}
                      {/*<MomentDate*/}
                      {/*format="MMMM DD, YYYY"*/}
                      {/*locale={Localize.getLanguage()}*/}
                      {/*date={universes[universeId].created_at}*/}
                      {/*/>*/}
                      {/*</span>*/}
                      {/*</div>*/}
                      <div className="col-xs-12 col-lg-6">
                        <span>
                          <b>Created at: </b>
                          <MomentDate
                            format="MMMM DD, YYYY"
                            locale={Localize.getLanguage()}
                            date={universes[universeId].created_at}
                          />
                        </span>
                      </div>
                      <div className="col-xs-12 col-lg-6">
                        <span>
                          <b>Companies:</b> {universes[universeId].duns.length}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    );
  }
}

export default UniverseList;
