import React, { Component } from 'react';
import UpdateRequest from './UpdateRequest';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { birRequestStatuses } from 'react-common/constants';

import Wizard from '../_components/Wizard/Wizard';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import phoneImg from '../_images/phone.svg';
import { getUserPhone } from '../_selectors/user';
import { getUpdateRequest } from '../_selectors/updateRequests';
import { withPermissions } from '../_components/Grant/Grant';
import { viewGrants } from '../_constants/grants';
import PageNotFound from '../PageNotFound';

const CallStep = userPhone => (
  <div className="call-verification-step">
    <h4>Thank you for submitting your information!</h4>

    <p>
      One of our business consultants will be in touch with you to confirm your
      details. <br />
      <br />
      You should expect a call at{' '}
      <strong notranslate="true">{userPhone}</strong>
      . <br />
      To change your number go to{' '}
      <Link to="/settings/profile">account settings</Link>.
    </p>
    <p className="text-center">
      <img
        alt="Verification call"
        className="svg-confirmation-call"
        src={phoneImg}
      />
    </p>
  </div>
);

const CompletedStep = () => (
  <div className="delivery-step">
    <p className="delivery-step-text text-center">
      Thank you for filling out the form!
    </p>
  </div>
);

class UpdateRequestContainer extends Component {
  steps = [
    {
      key: birRequestStatuses.DRAFT,
      title: 'Fill the form',
      component: UpdateRequest
    },
    {
      key: birRequestStatuses.REVIEW,
      title: 'Verification call',
      component: () => CallStep(this.props.userPhone)
    },
    {
      key: birRequestStatuses.COMPLETED,
      title: 'Completed',
      component: CompletedStep
    }
  ];

  getActiveStepKey = () => {
    const { updateRequest } = this.props;
    return updateRequest && updateRequest.status;
  };

  render() {
    return (
      <AppWrapper>
        {<Wizard activeStepKey={this.getActiveStepKey()} steps={this.steps} />}
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => {
  const updateRequest = getUpdateRequest(state);

  return {
    userPhone: getUserPhone(state),
    updateRequest
  };
};

UpdateRequestContainer = connect(mapStateToProps)(UpdateRequestContainer);

export default withPermissions(viewGrants.UPDATE, PageNotFound)(
  UpdateRequestContainer
);
