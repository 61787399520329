import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { hideDbiaReportModal } from '../../_actions/dbia';
import { connect } from 'react-redux';
import { getDbiaOrder, getDbiaOrderReport } from '../../_selectors/dbia';
import ReportContainer from '../../Public/ReportContainer';
import './DbiaReportModal.css';

class DbiaReportModal extends Component {
  state = {};

  closeReport = () => {
    const { dispatchHideDbiaReport } = this.props;
    dispatchHideDbiaReport();
  };

  render() {
    const { order } = this.props;

    return (
      <Modal
        className="report-modal"
        show={!!order}
        onHide={this.closeReport}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>DBIA Report</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {order && <ReportContainer orderId={order._id} />}
        </Modal.Body>

        <Modal.Footer>
          <button
            className="Btn-outline-small Btn-outline-primary"
            onClick={this.closeReport}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  order: getDbiaOrder(state),
  report: getDbiaOrderReport(state)
});

const mapDispatchToProps = {
  dispatchHideDbiaReport: hideDbiaReportModal
};

DbiaReportModal.defaultProps = { order: {} };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DbiaReportModal);
