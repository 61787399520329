import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import WizardProgress from './WizardProgress/WizardProgress';

const Wizard = ({ className, activeStepKey, steps, props: componentProps }) => {
  const activeStep = steps.find(step => step.key === activeStepKey);
  const StepComponent = activeStep && activeStep.component;

  return (
    <div className={classnames('Wizard', className)}>
      <WizardProgress activeStepKey={activeStepKey} steps={steps} />

      <div className="WizardPage">{<StepComponent {...componentProps} />}</div>
    </div>
  );
};

Wizard.propTypes = {
  className: PropTypes.string,
  activeStepKey: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
        .isRequired
    })
  ).isRequired,
  props: PropTypes.object
};

Wizard.defaultProps = {
  activeStepKey: '',
  steps: [],
  props: {}
};

export default Wizard;
