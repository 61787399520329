import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { register, getUserByRegistrationToken } from '../_actions';
import { getUser } from '../_selectors/user';
import { withEnums } from 'react-common/decorators';
import Register from './Register';
import { urlConstants } from '../_constants/ui';

class RegisterContainer extends Component {
  componentDidMount() {
    const { dispatchGetUserByRegistrationToken, token } = this.props;

    if (token) {
      dispatchGetUserByRegistrationToken(token);
    }
  }

  submit = user => {
    const { dispatchRegister, token } = this.props;

    if (token) {
      user.token = token;
    }

    return dispatchRegister(user);
  };

  render() {
    // redirect to home if already authorized
    if (Auth.isUserAuthenticated() && Auth.isAccountConfirmed()) {
      return <Redirect to={urlConstants.HOME} />;
    }
    if (Auth.isUserAuthenticated() && !Auth.isAccountConfirmed()) {
      return <Redirect to={urlConstants.VERIFY} />;
    }
    return <Register {...this.props} onSubmit={this.submit} />;
  }
}

function mapStateToProps(state, ownProps) {
  const { form } = state;
  const { countries } = ownProps;
  const user = getUser(state);

  const { values: formValues } = form['register'] || {};
  const { country, phone } = formValues || {};

  const { location } = ownProps;
  const { token } = queryString.parse(location.search);

  // if only country is selected, set the country phone code into the phone field
  if (
    countries &&
    formValues &&
    country &&
    (!phone || countries.find(c => c.phone_code === phone))
  ) {
    const countryItem = countries.find(c => c.iso_alpha_3 === country);
    if (countryItem) {
      formValues.phone = countryItem.phone_code;
    }
  }

  let initialValues = {};

  if (user) {
    initialValues = {
      name: user.info.name,
      email: user.info.email,
      // TODO set ISO 3 alpha country code on admin invite side
      country:
        user.info.country && user.info.country.length === 3
          ? user.info.country
          : undefined,
      phone: user.info.phone_work,
      phone_ext: user.info.phone_ext
    };
  }

  return {
    initialValues,
    formValues,
    token,
    user
  };
}

const mapDispatchToProps = {
  dispatchRegister: register,
  dispatchGetUserByRegistrationToken: getUserByRegistrationToken
};

export default withEnums('countries')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterContainer)
);
