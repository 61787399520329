import { Fetch } from 'react-common/services';

class CompanyService {
  getCompanyByIdOrDuns(companyIdOrDuns) {
    return Fetch.getJSON(`/api/companies/${companyIdOrDuns}`);
  }

  changeCompanyLogo(companyId, companyLogo) {
    return Fetch.putJSON(`/api/companies/${companyId}`, { logo: companyLogo });
  }
}

export default new CompanyService();
