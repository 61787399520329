import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import { ComponentWithLoader, DunsAbbr } from 'react-common/components';
import './Usage.css';
import Alert from '../_components/Alert';

const dateFormat = 'DD/MM/YYYY';

function Usage({
  fromDate,
  toDate,
  isFetchingDbiaTransactions,
  dbiaAccount,
  dbiaTransactions,
  onFromChange,
  onToChange,
  findCountry
}) {
  return (
    <>
      <Row className="UsageHeader">
        {dbiaAccount && (
          <Col xs={12} sm={8}>
            <Row>
              <Col xs={12}>
                <h4>Subscription information</h4>
              </Col>
              <Col xs={12} sm={4}>
                <label>Contract start date</label>
                <p>{moment(dbiaAccount.StartDate).format(dateFormat)}</p>
              </Col>
              <Col xs={12} sm={4}>
                <label>Contract end date</label>
                <p>{moment(dbiaAccount.EndDate).format(dateFormat)}</p>
              </Col>
              <Col xs={12} sm={4}>
                <label>Balance</label>
                <p>{dbiaAccount.Balance}</p>
              </Col>
              <Col xs={12} sm={4}>
                <label>Unit Rate</label>
                <p>{dbiaAccount.UnitRate}</p>
              </Col>
              <Col xs={12} sm={4}>
                <label>Currency</label>
                <p>{dbiaAccount.Currency}</p>
              </Col>
              <Col xs={12} sm={4}>
                <label>Units Purchased</label>
                <p>{dbiaAccount.UnitsPurchased}</p>
              </Col>
            </Row>
          </Col>
        )}

        <Col xs={12} sm={4}>
          <Row>
            <Col xs={12}>
              <h4>Filter transactions</h4>
            </Col>
            {/* By default show last 7 days */}
            <Col xs={12} sm={6}>
              <label>Start date</label>
              <input
                className="form-control"
                type="date"
                value={fromDate}
                onChange={onFromChange}
                max={toDate}
              />
            </Col>
            <Col xs={12} sm={6}>
              <label>End date</label>
              <input
                className="form-control"
                type="date"
                value={toDate}
                onChange={onToChange}
                max={moment().format('YYYY-MM-DD')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <ComponentWithLoader showLoader={isFetchingDbiaTransactions}>
          {dbiaTransactions && !!dbiaTransactions.length && (
            <Col xs={12}>
              <Table>
                <thead>
                  <tr>
                    <th className="th-duns">
                      <DunsAbbr />
                    </th>
                    <th className="th-company">Company Name</th>
                    <th className="th-country">Country</th>
                    <th className="th-product">Product</th>
                    <th className="th-date">Order Date</th>
                    <th className="th-date">Delivery Date</th>
                    <th className="th-units">Units</th>
                  </tr>
                </thead>
                <tbody>
                  {dbiaTransactions.map(transaction => (
                    <tr key={transaction.OrderId}>
                      <td>{transaction.Duns}</td>
                      <td>{transaction.CompanyName}</td>
                      <td>{findCountry(transaction.CountryCode)}</td>
                      <td>{transaction.Product}</td>
                      <td>{transaction.Date}</td>
                      <td>{transaction.DeliveryDate}</td>
                      <td>{transaction.Units}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </ComponentWithLoader>
      </Row>
      {fromDate.length > 0 &&
        toDate.length > 0 &&
        dbiaTransactions &&
        dbiaTransactions.length === 0 && (
          <Alert type="info" icon="eye" closeIconVisible={false}>
            Only orders for the past 7 days are displayed by default.
          </Alert>
        )}
      {fromDate.length === 0 &&
        toDate.length === 0 &&
        dbiaTransactions &&
        dbiaTransactions.length === 0 && (
          <Alert type="info" icon="eye" closeIconVisible={false}>
            You have no orders.
          </Alert>
        )}
    </>
  );
}

export default Usage;
