import React, { Component } from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { BirFormContainer } from 'react-common/components';
import { withEnums } from 'react-common/decorators';
import { Localize } from 'react-common/services';
import { workflowReportTypes } from 'react-common/constants';

import {
  getAdminBirRequestById,
  updateAdminBirRequest,
  updateAdminBirRequestField,
  uploadAdminBirRequestFile,
  deleteAdminBirRequestFile
} from '../_actions/birRequests';
import { getBirRequest, getBirRequestFiles } from '../_selectors/birRequests';

class AdminBirRequest extends Component {
  state = {
    uploadingFiles: []
  };

  componentDidMount() {
    const {
      dispatchGetBirRequestById,
      birRequestId,
      accountId,
      language
    } = this.props;

    if (birRequestId && accountId) {
      dispatchGetBirRequestById(birRequestId, accountId);
    }

    if (language) {
      Localize.setLanguage(language);
    }
  }

  updateForm = (name, value, file) => {
    const {
      files,
      birRequestId,
      accountId,
      dispatchUpdateAdminBirRequestField,
      dispatchDeleteAdminBirRequestFile
    } = this.props;

    if (!name) {
      return;
    }
    if (file) {
      return this.uploadFile(name, file);
    }
    if (!value && files[name]) {
      return dispatchDeleteAdminBirRequestFile(birRequestId, name, accountId);
    }

    return dispatchUpdateAdminBirRequestField(
      birRequestId,
      {
        [name]: value
      },
      accountId
    );
  };

  uploadFile = (name, file) => {
    const {
      accountId,
      birRequestId,
      dispatchUploadAdminBirRequestFile
    } = this.props;

    this.setState({ uploadingFiles: [...this.state.uploadingFiles, name] });

    const fileData = new FormData();
    fileData.append(name, file);

    return dispatchUploadAdminBirRequestFile(
      birRequestId,
      name,
      fileData,
      accountId,
      this.removeFromUploadingList
    );
  };

  removeFromUploadingList = fileName => {
    this.setState({
      uploadingFiles: this.state.uploadingFiles.filter(
        field => !includes(fileName, field)
      )
    });
  };

  onStepSubmit = formData => {
    const {
      accountId,
      birRequestId,
      dispatchUpdateAdminBirRequest
    } = this.props;
    return dispatchUpdateAdminBirRequest(
      birRequestId,
      {
        company: formData
      },
      accountId
    );
  };

  render() {
    const { birRequest, files, ccsCountries, language } = this.props;

    if (!birRequest || !ccsCountries.length) {
      return <div> BIR REQUEST NOT FOUND </div>;
    }

    let birFormCountry = 'INT';
    const reportType = get(birRequest, 'report.type', null);

    if (
      [
        workflowReportTypes.HOM_9_ASPECTS,
        workflowReportTypes.HOM_8_ASPECTS,
        workflowReportTypes.HOM_7_ASPECTS,
        workflowReportTypes.HOM_FALLLABELLLA,
        workflowReportTypes.HOM_FALLLABELLLA_MID
      ].includes(reportType)
    ) {
      birFormCountry = reportType.toUpperCase();
    } else if (birRequest.country === 'PER' && reportType === 'sqr') {
      birFormCountry = 'PER_SQR';
    } else if (birRequest.country === 'ARG' && reportType === 'sqr') {
      birFormCountry = 'ARG_SQR';
    } else if (['BRA', 'MEX', 'PER', 'ARG'].includes(birRequest.country)) {
      birFormCountry = birRequest.country;
    } else if (map(ccsCountries, 'iso_alpha_3').includes(birRequest.country)) {
      birFormCountry = 'CCS';
    }

    let initialValues = { ...birRequest.company };
    for (let key in files) {
      initialValues[key] = files[key];
    }

    return (
      <BirFormContainer
        initialValues={get(birRequest, 'company', {})}
        country={birFormCountry}
        currentStep={birRequest.step}
        files={files}
        onChange={this.updateForm}
        onStepSubmit={this.onStepSubmit}
        onSubmit={() => {}}
        hideSubmitButton={true}
        language={language}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const birRequestId = urlParams.get('birRequestId');
  const accountId = urlParams.get('accountId');
  const language = urlParams.get('language');

  return {
    birRequestId,
    accountId,
    language,
    birRequest: getBirRequest(state),
    files: getBirRequestFiles(state),
    ccsCountries: props.ccsCountries
  };
};

const mapDispatchToProps = {
  dispatchGetBirRequestById: getAdminBirRequestById,
  dispatchUpdateAdminBirRequest: updateAdminBirRequest,
  dispatchUpdateAdminBirRequestField: updateAdminBirRequestField,
  dispatchUploadAdminBirRequestFile: uploadAdminBirRequestFile,
  dispatchDeleteAdminBirRequestFile: deleteAdminBirRequestFile
};

export default withEnums('ccsCountries')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminBirRequest)
);
