import uiConstants from '../_constants/ui';
import WalkthroughService from '../_services/Walkthrough';

export function updateHeaderBreadcrumbs(headerBreadcrumbs) {
  return dispatch => {
    dispatch({
      type: uiConstants.UI_HEADER_BREADCRUMBS_UPDATE,
      headerBreadcrumbs
    });
  };
}

export function dunsRequestFileUploadStart(fileName) {
  return {
    type: uiConstants.UI_DUNS_REQUEST_FILE_UPLOAD_START,
    fileName
  };
}

export function dunsRequestFileUploadEnd(fileName) {
  return {
    type: uiConstants.UI_DUNS_REQUEST_FILE_UPLOAD_END,
    fileName
  };
}

export function setInitialUrl(url = '', urlOptions = {}) {
  // if exist, append query parameters
  url = urlOptions.search ? `${url}${urlOptions.search}` : url;

  return {
    type: uiConstants.INITIAL_URL,
    url
  };
}

export function refreshCompletedWalkthroughSteps() {
  const completedSteps = WalkthroughService.getCompletedSteps();

  return {
    type: uiConstants.UI_WALKTHROUGH_REFRESH_COMPLETED_STEPS,
    completedSteps
  };
}

export function saveCompletedWalkthroughStep(stepId) {
  WalkthroughService.saveCompletedStep(stepId);

  return {
    type: uiConstants.UI_WALKTHROUGH_SAVE_COMPLETED_STEP,
    stepId
  };
}

export function clearCompletedWalkthroughSteps() {
  WalkthroughService.clearCompletedSteps();

  return {
    type: uiConstants.UI_WALKTHROUGH_CLEAR_COMPLETED_STEPS
  };
}

export function uiWalkthroughMounted() {
  return {
    type: uiConstants.UI_WALKTHROUGH_MOUNTED
  };
}

export function uiWalkthroughUnmounted() {
  return {
    type: uiConstants.UI_WALKTHROUGH_UNMOUNTED
  };
}
