import React, { Component } from 'react';
import { DunsAbbr } from 'react-common/components';
import './CompanyInfo.css';

class CompanyInfo extends Component {
  state = {
    hover: false
  };

  hoverOn = () => {
    this.setState({ hover: true });
  };

  hoverOff = () => {
    this.setState({ hover: false });
  };

  render() {
    const { company, removeDuns } = this.props;

    return (
      <div
        className="Company-info"
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
      >
        {this.state.hover && (
          <button
            className="remove"
            onClick={() => removeDuns(company.duns, company.legal_name)}
          >
            <i className="icon-ic_close" />
          </button>
        )}
        <h4>{company.legal_name}</h4>
        <span>
          <DunsAbbr />: {company.duns}
        </span>
        {company.address && (
          <span>
            <b>Address:</b> {company.address.main.address_line_1},{' '}
            {company.address.main.country}
          </span>
        )}
      </div>
    );
  }
}

export default CompanyInfo;
