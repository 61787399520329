import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as qs from 'query-string';
import { confirmEmail } from '../_actions';

class ConfirmEmail extends Component {
  getToken = () => {
    return qs.parse(this.props.location.search).token;
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const token = this.getToken();
    const { registration: { token: accountToken } = {} } = Auth.getUser();
    if (!Auth.isAccountConfirmed() && token && token === accountToken) {
      return dispatch(confirmEmail(token));
    }
  }

  render() {
    const token = this.getToken();
    const { registration: { token: accountToken } = {} } = Auth.getUser();

    if (Auth.isAccountConfirmed()) {
      return <Redirect to="/home" />;
    }
    if (!token || token !== accountToken) {
      return <Redirect to="/verify" />;
    }
    return null;
  }
}

export default connect()(ConfirmEmail);
