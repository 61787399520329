import dunsRequestConstants from '../_constants/dunsRequest';
import userConstants from '../_constants/user';
import { Util } from 'react-common/services';

export function dunsRequests(
  state = {
    items: {}
  },
  action
) {
  switch (action.type) {
    case dunsRequestConstants.GET_DUNS_REQUESTS_FOR_ACCOUNT:
      return {
        ...state,
        items: Util.convertArrayToMap(action.data)
      };
    case dunsRequestConstants.CREATE_DUNS_REQUEST:
    case dunsRequestConstants.PATCH_DUNS_REQUEST:
    case dunsRequestConstants.SUBMIT_DUNS_REQUEST:
    case dunsRequestConstants.UPLOAD_DUNS_REQUEST_FILE:
    case dunsRequestConstants.DELETE_DUNS_REQUEST_FILE:
    case dunsRequestConstants.MARK_DUNS_REQUEST_AS_SEEN:
      return {
        ...state,
        items: {
          ...state.items,
          [action.data._id]: action.data
        }
      };
    case userConstants.RELOAD:
      return {
        ...state,
        items: {
          ...state.items,
          ...Util.convertArrayToMap(action.data && action.data.duns_requests)
        }
      };
    default:
      return state;
  }
}
