import companyConstants from '../_constants/company';
import { Company } from '../_models/company';
import { UDM } from '../_models/udm';

const initialState = {
  company: new Company({ udm: new UDM() })
};

export function company(state = initialState, action) {
  switch (action.type) {
    case companyConstants.COMPANY:
      return {
        ...state,
        company: new Company({
          ...action.data,
          udm: new UDM({ ...action.data.udm })
        })
      };
    default:
      return state;
  }
}
