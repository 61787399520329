const updateRequestConstants = {
  GET_ADMIN_UPDATE_REQUEST: 'GET_ADMIN_UPDATE_REQUEST',
  UPDATE_ADMIN_UPDATE_REQUEST: 'UPDATE_ADMIN_UPDATE_REQUEST',
  GET_UPDATE_REQUESTS_FOR_ACCOUNT: 'GET_UPDATE_REQUESTS_FOR_ACCOUNT',
  SUBMIT_UPDATE_REQUEST: 'SUBMIT_UPDATE_REQUEST',
  UPLOAD_UPDATE_REQUEST_FILE: 'UPLOAD_UPDATE_REQUEST_FILE',
  DELETE_UPDATE_REQUEST_FILE: 'DELETE_UPDATE_REQUEST_FILE'
};

export default updateRequestConstants;
