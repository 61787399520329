const apiConstants = {
  API: 'API',
  KEY: 'API_KEY',
  RESET: 'API_RESET',
  START: '_API_START',
  END: '_API_END',
  SUCCESS: '_API_SUCCESS',
  FAILURE: '_API_FAILURE'
};

export default apiConstants;
