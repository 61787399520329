import PropTypes from 'prop-types';
import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';

function Multiselect({
  options,
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  classNamePrefix,
  isClearable,
  multiSelect
}) {
  if (!options) {
    return null;
  }

  const selectedOption = options.find(option => option.value === value);

  return (
    <CreatableSelect
      isClearable={isClearable}
      placeholder={placeholder}
      isMulti={multiSelect}
      options={options}
      value={selectedOption}
      onChange={option => {
        onChange && onChange((option && option.value) || option);
      }}
      onFocus={() => {
        onFocus && onFocus();
      }}
      onBlur={() => {
        onBlur && onBlur();
      }}
      classNamePrefix={classNamePrefix}
      allowCreate={true}
    />
  );
}

Multiselect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  classNamePrefix: PropTypes.string,
  isClearable: PropTypes.bool,
  multiSelect: PropTypes.bool
};

Multiselect.defaultProps = {
  isClearable: true,
  multiSelect: true
};

export default Multiselect;
