import {
  getFormValues,
  getFormSyncErrors,
  isDirty
} from 'react-common/libs/reduxForm';

import { REGIONS_MAP } from '../_utils/mappings';
import { isCountry, isCountryCCS } from '../_utils/dunsRequest';

const FORM_KEY = 'dunsRequest';

export const getHasErrors = state => {
  const errors = getFormSyncErrors(FORM_KEY)(state);

  return Object.entries(errors).length !== 0;
};

export const getSyncErrors = state => getFormSyncErrors(FORM_KEY)(state);

export const getIsDirty = state => isDirty(FORM_KEY)(state);

export const getSelectedCountryCode = state => {
  const formValues = getFormValues(FORM_KEY)(state);
  return formValues && formValues.country;
};

export const getSelectedCountry = (state, countriesEnum) => {
  if (!countriesEnum) {
    return;
  }

  const countryCode = getSelectedCountryCode(state);
  return countriesEnum.find(country => country.iso_alpha_3 === countryCode);
};

export const getSelectedCountryRegionName = (state, countriesEnum) => {
  const country = getSelectedCountry(state, countriesEnum);
  const countryTitle = country && country.title.toLowerCase();

  return REGIONS_MAP[countryTitle] || 'ccs';
};

export const getSelectedReasonOfRequest = (
  state,
  countriesEnum,
  reasonsOfRequest
) => {
  const possibleReasons = reasonsOfRequest;
  const regionName = getSelectedCountryRegionName(state, countriesEnum);
  const formValues = getFormValues(FORM_KEY)(state);
  const selectedReasonOfRequest = formValues && formValues.reason_of_request;

  if (!possibleReasons || !selectedReasonOfRequest) {
    return;
  }

  return possibleReasons[regionName][selectedReasonOfRequest];
};

export const getSelectedCialCountry = (state, countriesEnum) => {
  const countries = countriesEnum;
  const formValues = getFormValues(FORM_KEY)(state);
  const selectedCompanyCountry = formValues && formValues.country;

  return countries.some(
    country =>
      country.iso_alpha_3 === selectedCompanyCountry &&
      country.labels &&
      country.labels.includes('cial')
  );
};

export const getSelectedLegalStructure = state => {
  const formValues = getFormValues(FORM_KEY)(state);
  return (
    formValues &&
    formValues.company &&
    formValues.company.business &&
    formValues.company.business.legal_structure
  );
};

export const getMetadata = (state, countriesEnum, countryRegions) => {
  const country = getSelectedCountry(state, countriesEnum);
  const regions = countryRegions;
  const CCSRegion = regions && regions.find(region => region.title === 'CCS');
  const CCSRegionCountries = CCSRegion && CCSRegion.countries;

  const metadata = {};

  if (!country) {
    return metadata;
  }

  metadata.isDunsForBrazil = isCountry(country, 'Brazil');
  metadata.isDunsForMexico = isCountry(country, 'Mexico');
  metadata.isDunsForArgentinaArea = isCountry(
    country,
    'Argentina',
    'Uruguay',
    'Paraguay',
    'Chile'
  );
  metadata.isDunsForPeruArea = isCountry(country, 'Peru', 'Ecuador', 'Bolivia');
  metadata.isDunsForCCS = isCountryCCS(country, CCSRegionCountries);

  return metadata;
};

export const getAcceptLegal = state => {
  const formValues = getFormValues(FORM_KEY)(state);
  return formValues && formValues.acceptLegal;
};

export const getIsUserLegalRepresentative = state => {
  const formValues = getFormValues(FORM_KEY)(state);
  return (
    formValues &&
    formValues.company &&
    formValues.company.user_is_legal_representative
  );
};
