import { Fetch } from 'react-common/services';

class ClaimService {
  claimCompany(company, email, companyRole) {
    const claim = {
      duns: company.duns,
      email: email,
      company_role: companyRole || 'Employee'
    };

    return Fetch.postJSON('/api/claims', claim);
  }

  getClaimsForAccount() {
    return Fetch.getJSON('/api/claims');
  }

  markAsSeen(claimId) {
    return Fetch.patchJSON(`/api/claims/${claimId}/seen`, {});
  }
}

export default new ClaimService();
