import apiConstants from '../_constants/api';

function mergeApiResults(previousState = {}, newState = {}) {
  return { ...previousState, ...newState };
}

export function api(state = {}, action) {
  if (action.type.indexOf(apiConstants.START) > -1) {
    return {
      ...state,
      [action.key]: mergeApiResults(state[action.key], { isFetching: true })
    };
  }
  if (action.type.indexOf(apiConstants.END) > -1) {
    return {
      ...state,
      [action.key]: mergeApiResults(state[action.key], { isFetching: false })
    };
  }
  if (action.type.indexOf(apiConstants.SUCCESS) > -1) {
    return {
      ...state,
      [action.key]: mergeApiResults(state[action.key], {
        success: true,
        error: ''
      })
    };
  }
  if (action.type.indexOf(apiConstants.FAILURE) > -1) {
    return {
      ...state,
      [action.key]: mergeApiResults(state[action.key], {
        success: false,
        error:
          action.error && action.error.error ? action.error.error : action.error
      })
    };
  }

  if (action.type === apiConstants.RESET) {
    return {
      ...state,
      [action.key]: {}
    };
  }

  return state;
}
