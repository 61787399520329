import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Localize } from 'react-common/services';
import { NavLink } from 'react-router-dom';
import { viewGrants } from '../../_constants/grants';
import logo from '../../_images/mycial-logo.svg';
import './NavigationMenu.css';
import MyCialAdminDomains from '../../_constants/domain';
import AdminLink from '../AdminLink/AdminLink';
import Grant from '../Grant/Grant';
import get from 'lodash/get';

class NavigationMenu extends Component {
  state = {
    dropdownOpen: false,
    privacyPolicy: {
      en: 'https://www.cialdnb.com/en/privacy-policy/',
      es: 'https://www.cialdnb.com/es/aviso-de-privacidad/',
      'pt-BR': 'https://www.cialdnb.com/pt-br/politica-de-privacidade/'
    },
    termsOfUse: {
      en: 'https://www.cialdnb.com/en/terms-of-use/',
      es: 'https://www.cialdnb.com/es/terminos-de-uso/',
      'pt-BR': 'https://www.cialdnb.com/pt-br/termos-de-uso/'
    }
  };

  render() {
    const { className = '' } = this.props;
    const myCialAdminDomain = MyCialAdminDomains[process.env.REACT_APP_ENV];

    return (
      <div className={`NavigationMenu hidden-xs ${className}`}>
        <ul className="NavigationMenu-list nav nav-pills nav-stacked no-border">
          <li>
            <NavLink to="/">
              <img src={logo} alt="MyCIAL" className="NavigationMenu-logo" />
            </NavLink>
          </li>

          <Grant permissions={viewGrants.GET_STARTED}>
            <li>
              <NavLink to="/get-started">
                <i className="icon-ic_claim" />
                Get Started
              </NavLink>
            </li>
          </Grant>

          <Grant permissions={viewGrants.DBIA}>
            <li>
              <NavLink to="/dbia">
                <i className="icon-ic_dbia" />
                DBIA Access
              </NavLink>
            </li>
          </Grant>

          <Grant permissions={viewGrants.SEARCH}>
            <li>
              <NavLink to="/search">
                <i className="icon-ic_search" />
                Company Search
              </NavLink>
            </li>
          </Grant>

          <Grant permissions={viewGrants.ONBOARDINGS}>
            <li>
              <NavLink to="/onboardings">
                <i className="icon-ic_eye" />
                Onboardings
              </NavLink>
            </li>
          </Grant>

          <Grant permissions={viewGrants.DBIA_ORDERS}>
            <li>
              <NavLink to="/orders">
                <i className="icon-ic_orders" />
                My Orders
              </NavLink>
            </li>
          </Grant>

          <Grant permissions={viewGrants.DBIA_ORDERS}>
            <li>
              <NavLink to="/usage">
                <i className="icon-ic_credits" />
                Usage
              </NavLink>
            </li>
          </Grant>

          <hr className="nav-hr" />
          <Grant permissions={viewGrants.MONITORING}>
            <li>
              <NavLink to="/universes">
                <i className="icon-ic_talk-to-us" />
                Monitoring
              </NavLink>
            </li>
          </Grant>

          <Grant permissions={viewGrants.ADMIN}>
            <li>
              <AdminLink link={myCialAdminDomain}>{'Admin Panel'}</AdminLink>
            </li>
          </Grant>

          <li className="fixed-bottom">
            <NavLink to="/contact">
              <i className="icon-ic_talk-to-us" />
              Contact us
            </NavLink>
            <div className="tos">
              <a
                href={get(
                  this.state,
                  `privacyPolicy[${Localize.getLanguage()}]`
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <span className="middot">&middot;</span>
              <a
                href={get(this.state, `termsOfUse[${Localize.getLanguage()}]`)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of use
              </a>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

NavigationMenu.propTypes = {
  className: PropTypes.string
};

export default NavigationMenu;
