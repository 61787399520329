import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { PrivateRoute, Loader, Toast } from 'react-common/components';
import { Auth, Segment } from 'react-common/services';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import ChangePassword from '../ChangePassword';
import ConfirmEmail from '../ConfirmEmail';
import Contact from '../Contact';
import ForgotPassword from '../ForgotPassword';
import GetStarted from '../GetStarted';
import Search from '../Search';
import Landing from '../Landing';
import Login from '../Login';
import Home from '../Home';
import PageNotFound from '../PageNotFound';
import Register from '../Register';
import VerifyEmail from '../VerifyEmail';
import Settings from '../Settings';
import DunsNumberForm from '../GetDunsNumber';
import ClaimDunsNumberForm from '../ClaimDunsNumber';
import DBIA from '../Dbia';
import Onboarding from '../Onboarding';
import UpdateRequestContainer from '../UpdateRequest/UpdateRequestContainer';
import AdminUpdateRequest from '../Admin/AdminUpdateRequest';
import CompanyProfile from '../CompanyProfile/CompanyProfileContainer';
import Universe from '../Universe';
import Orders from '../Orders/OrdersContainer';
import ReportContainer from '../Public/ReportContainer';
import Usage from '../Usage/UsageContainer';

import { getDunsRequestsForAccount } from '../_actions/dunsRequests';
import { getClaimsForAccount } from '../_actions/claims';
import { getUpdateRequestForAccount } from '../_actions/updateRequests';
import { reloadUser } from '../_actions/user';
import { nextIdentifyValidation } from '../_actions/nextIdentify';
import { refreshCompletedWalkthroughSteps } from '../_actions/ui';
import configureStore from '../store';
import './App.css';
import AdminBirRequest from '../Admin/AdminBirRequest';
import { setInitialUrl } from '../_actions';
import { urlConstants } from '../_constants/ui';

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();
const store = configureStore(history);

history.listen(() => {
  const currentDateTime = new Date();
  const {
    nextIdentify: { nextIdentify }
  } = store.getState();
  if (currentDateTime > nextIdentify || !nextIdentify) {
    store.dispatch(nextIdentifyValidation());
    store.dispatch(reloadUser());
  } else {
    Segment.page();
  }
});

class App extends Component {
  state = {
    dataLoaded: false
  };

  componentDidMount() {
    if (!Auth.isLocalStorageSupported() || !Auth.isUserAuthenticated()) {
      return;
    }

    let initialUrl = history.location.pathname;
    const urlOptions = {
      search: history.location.search
    };
    const requestFromAdmin = initialUrl.includes('/public/');
    const isConfirmPage = initialUrl.startsWith('/register/confirmation');

    if (
      initialUrl !== urlConstants.CONFIRMATION &&
      Auth.isUserAuthenticated() &&
      !Auth.isAccountConfirmed()
    ) {
      initialUrl = urlConstants.VERIFY;
    }

    if (requestFromAdmin) {
      return this.setState({ dataLoaded: true });
    }

    Promise.all([
      store.dispatch(setInitialUrl(initialUrl, urlOptions)),
      store.dispatch(reloadUser(!isConfirmPage)),
      store.dispatch(getDunsRequestsForAccount()),
      store.dispatch(getClaimsForAccount()),
      store.dispatch(getUpdateRequestForAccount()),
      store.dispatch(refreshCompletedWalkthroughSteps())
    ]).then(() => this.setState({ dataLoaded: true }));
  }

  render() {
    const { dataLoaded } = this.state;

    if (!Auth.isLocalStorageSupported()) {
      return <PageNotFound />;
    } else if (Auth.isUserAuthenticated() && !dataLoaded) {
      return <Loader loading />;
    }

    return (
      <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <ConnectedRouter history={history}>
          <>
            <Toast />
            <Switch>
              <PrivateRoute
                path="/register/confirmation"
                exact
                component={ConfirmEmail}
              />
              <PrivateRoute path="/verify" exact component={VerifyEmail} />
              <Route path="/register" exact component={Register} />
              <Route path="/contact" exact component={Contact} />
              <Route
                path="/public/update-request"
                exact
                component={AdminUpdateRequest}
              />
              <Route
                path="/public/bir-request"
                exact
                component={AdminBirRequest}
              />
              <Route
                path="/public/orders/:orderId/report"
                exact
                component={ReportContainer}
              />
              <Route
                render={props => {
                  // if user account is not confirmed redirect to VerifyEmail
                  return (
                    <Switch>
                      <Route path="/" exact component={Landing} />
                      <Route path="/login" exact component={Login} />
                      <Route
                        path="/reset-password"
                        exact
                        component={ForgotPassword}
                      />
                      <Route
                        path="/change-password"
                        exact
                        component={ChangePassword}
                      />
                      <PrivateRoute path="/home" exact component={Home} />
                      <PrivateRoute
                        path="/update"
                        exact
                        component={UpdateRequestContainer}
                      />
                      <PrivateRoute
                        path="/companies/:duns"
                        exact
                        component={CompanyProfile}
                      />
                      <PrivateRoute
                        path="/get-started"
                        exact
                        component={GetStarted}
                      />
                      <PrivateRoute path="/search" component={Search} />
                      <PrivateRoute
                        path="/settings/(profile|company|password)"
                        exact
                        component={Settings}
                      />
                      <PrivateRoute
                        path="/duns"
                        exact
                        component={DunsNumberForm}
                      />
                      <PrivateRoute
                        path="/claims"
                        exact
                        component={ClaimDunsNumberForm}
                      />
                      <PrivateRoute path="/dbia" exact component={DBIA} />
                      <PrivateRoute
                        path="/dbia/credentials"
                        exact
                        component={DBIA}
                      />
                      <PrivateRoute
                        path="/onboardings"
                        exact
                        component={Onboarding}
                      />
                      <PrivateRoute path="/universes" component={Universe} />
                      <PrivateRoute path="/orders" exact component={Orders} />
                      <PrivateRoute path="/usage" exact component={Usage} />
                      <Route component={PageNotFound} />
                    </Switch>
                  );
                }}
              />
            </Switch>
          </>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
