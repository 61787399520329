import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dbia from './Dbia';
import { withPermissions } from '../_components/Grant/Grant';
import { viewGrants } from '../_constants/grants';
import PageNotFound from '../PageNotFound';

class DbiaController extends Component {
  render() {
    return <Dbia {...this.props} />;
  }
}

function mapStateToProps(state) {
  const {
    user: { user }
  } = state;
  return {
    user
  };
}

DbiaController = connect(mapStateToProps)(DbiaController);

export default withPermissions(viewGrants.DBIA, PageNotFound)(DbiaController);
