import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Alert.css';

function Alert({ type, icon, children, onClick, closeIconVisible = true }) {
  const alertBoxClassNames = classnames('Alert', `Alert-${type}`, {
    'Alert-with-icon': !!icon
  });

  return (
    <div className={alertBoxClassNames} onClick={onClick}>
      {icon && <i className={`icon-ic_${icon}`} />}
      <p>{children}</p>
      {closeIconVisible && <i className="icon-ic_close" />}
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'danger', 'info', 'pointer'])
    .isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func
};

export default Alert;
