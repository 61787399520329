import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { grantTypes } from '../../_constants/grants';
import { getActivePermissions } from '../../_selectors/permissions';
import { connect } from 'react-redux';
import { getRedirectMap } from '../../_selectors/ui';

export const withPermissions = (
  permissions,
  FallbackPage = null
) => WrappedComponent => {
  class WithPermissions extends Component {
    has = permission => {
      const { activePermissions } = this.props;
      return activePermissions.includes(permission);
    };

    every = () => {
      const { permissions } = this.props;
      return permissions.every(p => this.has(p));
    };

    any = () => {
      const { permissions } = this.props;
      return permissions.some(p => this.has(p));
    };

    grantTypeToCheck = {
      [grantTypes.ANY]: this.any,
      [grantTypes.EVERY]: this.every
    };

    render() {
      const { type, redirectMap, location = {} } = this.props;
      const hasPermission = this.grantTypeToCheck[type]();

      if (hasPermission) {
        return <WrappedComponent {...this.props} />;
      }
      if (redirectMap[location.pathname]) {
        return <Redirect to={redirectMap[location.pathname]} />;
      }
      return FallbackPage && <FallbackPage />;
    }
  }

  const mapStateToProps = (state, props) => {
    const componentPermissions = permissions || props.permissions;
    return {
      type: grantTypes[props.type] || grantTypes.ANY,
      permissions: Array.isArray(componentPermissions)
        ? componentPermissions
        : [componentPermissions],
      activePermissions: getActivePermissions(state),
      redirectMap: getRedirectMap(state)
    };
  };

  return connect(mapStateToProps)(WithPermissions);
};

class Grant extends Component {
  render() {
    return this.props.children;
  }
}

export default withPermissions()(Grant);
