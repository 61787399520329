import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { fetchAllUniverses } from '../../_actions';
import UniverseListDropdown from './UniverseListDropdown';

class UniverseListDropdownContainer extends Component {
  componentDidMount() {
    const { universes, dispatchFetchAllUniverses } = this.props;

    // Only fetch items when we don't already have the list
    if (!universes || isEmpty(universes.items)) {
      dispatchFetchAllUniverses();
    }
  }

  render() {
    return <UniverseListDropdown {...this.props} />;
  }
}

function mapStateToProps(state) {
  const { universe } = state;
  return {
    universes: universe
  };
}

const mapDispatchToProps = {
  dispatchFetchAllUniverses: fetchAllUniverses
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniverseListDropdownContainer);
