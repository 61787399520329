import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Collapse, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCompany } from '../../../_actions/dbia';
import { ComponentWithLoader, DunsAbbr } from 'react-common/components';
import { getDbiaCompanies } from '../../../_selectors/dbia';
import DbiaCompany from '../../Dbia/DbiaCompany';
import Grant from '../../Grant/Grant';
import { viewGrants } from '../../../_constants/grants';
import { walkthroughSteps } from '../../../_constants/walkthrough';
import Walkthrough from '../../Walkthrough/Walkthrough';
import { withEnums } from 'react-common/decorators';

const availableWalkthroughSteps = [
  {
    target: '#first-company-details-button',
    stepData: walkthroughSteps.companySearch.showDetails
  }
];

class SearchResults extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = reduce(
      props.results,
      (state, { duns }) => ({ ...state, [duns]: {} }),
      {}
    );
  }

  handleToggle = company => {
    const { dispatchGetCompany, dbiaCompanies } = this.props;

    const dunsNumber = company.getDunsNumber();
    if (dbiaCompanies[dunsNumber]) {
      return this.setState({
        [dunsNumber]: { open: !this.state[dunsNumber].open, loading: false }
      });
    }

    dispatchGetCompany(
      company.getCountry(),
      dunsNumber,
      () =>
        this.setState({
          ...this.state,
          [dunsNumber]: { open: !this.state[dunsNumber].open, loading: true }
        }),
      () =>
        this.setState({
          ...this.state,
          [dunsNumber]: { open: this.state[dunsNumber].open, loading: false }
        })
    );
  };

  getColumns = () => {
    const { searchParams } = this.props;

    return [
      <DunsAbbr />,
      'Company',
      !searchParams.country && 'Country',
      'City',
      this.isUdmData() && 'Address',
      this.isUdmData() && 'Area',
      ' ' // This is for 'Actions', but we don't want a heading for them
    ].filter(Boolean);
  };

  getCountryName = countryCode => {
    if (!this.props.countries) return countryCode;
    return this.props.countries.find(country => country.code === countryCode)
      .title_en;
  };

  renderResult = (result, index) => {
    const { dbiaCompanies, searchParams } = this.props;
    const searchResult = this.state[result.duns] || {};

    return (
      <Table key={index}>
        <tbody className="companyResults">
          <tr>
            <td className="td-duns">{result.getDunsNumber()}</td>
            <td className="td-legalName">{result.getLegalName()}</td>
            {!searchParams.country && (
              <td className="td-country">
                {this.getCountryName(result.getCountry())}
              </td>
            )}
            <td className="td-address">{result.getCity()}</td>
            {this.isUdmData() && (
              <td className="td-address">{result.getAddressLine1()}</td>
            )}
            {this.isUdmData() && (
              <td className="td-municipality">{result.getArea()}</td>
            )}
            <td className="td-actions">
              <Grant permissions={viewGrants.DBIA_ORDERS}>
                <>
                  <button
                    id={
                      index === 0 ? 'first-company-details-button' : undefined
                    }
                    className="Btn-outline-small Btn-outline-primary"
                    aria-controls={result.duns}
                    aria-expanded={searchResult.open}
                    onClick={() => this.handleToggle(result)}
                  >
                    {searchResult.open ? 'Close' : 'Details'}
                  </button>
                  {index === 0 && (
                    <Walkthrough availableSteps={availableWalkthroughSteps} />
                  )}
                </>
              </Grant>
            </td>
          </tr>
        </tbody>

        <Collapse in={searchResult.open} id={result.duns}>
          <tbody className="reportsPurchaseTable">
            {searchResult.loading && (
              <tr>
                <td colSpan={this.getColumns().length}>
                  <div className="reportsPurchaseLoader">
                    <ComponentWithLoader showLoader={true} />
                  </div>
                </td>
              </tr>
            )}
            {!searchResult.loading && (
              <DbiaCompany
                company={dbiaCompanies[result.duns]}
                columnCount={this.getColumns().length}
              />
            )}
          </tbody>
        </Collapse>
      </Table>
    );
  };

  isUdmData = () => {
    const { cialCountries, searchParams } = this.props;
    return (
      !searchParams.country ||
      cialCountries.find(
        country => country.iso_alpha_3 === searchParams.country
      )
    );
  };

  render() {
    const { results } = this.props;

    return (
      <div className="DefaultSearchResults">
        <Table responsive>
          <thead>
            <tr>
              {this.getColumns().map((columnText, index) => (
                <th key={index}>{columnText}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={this.getColumns().length} className="td-results">
                {results.map(this.renderResult)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

SearchResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      duns: PropTypes.string,
      legal_name: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        address_line_1: PropTypes.string,
        municipality: PropTypes.string,
        state: PropTypes.string
      })
    })
  ),
  searchParams: PropTypes.shape({
    country: PropTypes.string,
    state: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

SearchResults.defaultProps = { results: [] };

const mapStateToProps = state => {
  return {
    dbiaCompanies: getDbiaCompanies(state)
  };
};

const mapDispatchToProps = {
  dispatchGetCompany: getCompany
};

export default withEnums('cialCountries')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchResults)
);
