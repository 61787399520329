import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ComponentWithLoader } from 'react-common/components';

import { getChangesForDuns } from '../../_selectors/universe';
import Changes from './Changes';

class ChangesContainer extends Component {
  render() {
    const { apiState, changes, duns } = this.props;

    return (
      <ComponentWithLoader showLoader={apiState.isFetching}>
        <Changes changes={changes} duns={duns} />
      </ComponentWithLoader>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    changes: getChangesForDuns(state, props.duns),
    apiState: state.api[getChangesForDuns.name] || {}
  };
};

export default connect(mapStateToProps)(ChangesContainer);
