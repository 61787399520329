import birRequestConstants from '../_constants/birRequest';
import BirRequest from '../_services/BirRequest';
import { makeRequest } from './api';

export function getAdminBirRequestById(birRequestId, accountId) {
  return makeRequest({
    type: birRequestConstants.GET_ADMIN_BIR_REQUESTS,
    apiCall: BirRequest.getAdminBirRequestById,
    params: [birRequestId, accountId]
  });
}

export function updateAdminBirRequestField(
  birRequestId,
  changedData,
  accountId,
  onSuccess
) {
  return makeRequest({
    type: birRequestConstants.UPDATE_ADMIN_BIR_REQUEST,
    apiCall: BirRequest.updateAdminBirRequestFieldById,
    params: [birRequestId, changedData, accountId],
    onSuccess
  });
}

export function updateAdminBirRequest(
  birRequestId,
  changedData,
  accountId,
  onSuccess
) {
  return makeRequest({
    type: birRequestConstants.UPDATE_ADMIN_BIR_REQUEST,
    apiCall: BirRequest.updateAdminBirRequestById,
    params: [birRequestId, changedData, accountId],
    onSuccess
  });
}

export function uploadAdminBirRequestFile(
  birRequestId,
  fileName,
  fileData,
  accountId,
  onUploadEnd
) {
  return makeRequest({
    type: birRequestConstants.UPLOAD_ADMIN_BIR_REQUEST_FILE,
    apiCall: BirRequest.uploadAdminBirRequestFile,
    params: [birRequestId, fileData, accountId],
    onRequestEnd: () => onUploadEnd(fileName),
    onFailure: () => onUploadEnd(fileName)
  });
}

export function deleteAdminBirRequestFile(birRequestId, fileName, accountId) {
  return makeRequest({
    type: birRequestConstants.DELETE_ADMIN_BIR_REQUEST_FILE,
    apiCall: BirRequest.deleteAdminBirRequestFile,
    params: [birRequestId, fileName, accountId]
  });
}
