import { enumsConstants } from 'react-common/constants';

export function enums(
  state = {
    items: {}
  },
  action
) {
  switch (action.type) {
    case enumsConstants.ENUMS:
      return {
        ...state,
        items: action.data
      };
    default:
      return state;
  }
}
