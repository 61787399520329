import React, { Component } from 'react';
import SingleChange from './SingleChange/SingleChangeContainer';
import isEmpty from 'lodash/isEmpty';

import './Changes.css';

class Changes extends Component {
  render() {
    const { changes } = this.props;
    return changes && !isEmpty(changes) ? (
      Object.entries(changes).map(change => (
        <SingleChange key={change[1].id} change={change[1]} />
      ))
    ) : (
      <p className="no-updates">Currently there are no updates...</p>
    );
  }
}

export default Changes;
