import birRequestConstants from '../_constants/birRequest';
import { Util } from 'react-common/services';

export function birRequests(
  state = {
    items: {}
  },
  action
) {
  switch (action.type) {
    case birRequestConstants.GET_ADMIN_BIR_REQUESTS:
    case birRequestConstants.UPDATE_ADMIN_BIR_REQUEST:
    case birRequestConstants.UPLOAD_ADMIN_BIR_REQUEST_FILE:
    case birRequestConstants.DELETE_ADMIN_BIR_REQUEST_FILE:
      return {
        ...state,
        items: Util.convertArrayToMap(Array.of(action.data))
      };
    default:
      return state;
  }
}
