import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { fetchAllUniverses, fetchAllChanges } from '../../_actions';
import UniverseList from './UniverseList';
import Changes from '../Changes/Changes';
import { ComponentWithLoader } from 'react-common/components';

class UniverseListContainer extends Component {
  componentDidMount() {
    this.props.dispatchFetchAllChanges();
    this.props.dispatchFetchAllUniverses();
  }

  render() {
    const items = this.props.universe && this.props.universe.items;
    const changes = this.props.universe && this.props.universe.changes;
    const { apiState } = this.props;

    return (
      <ComponentWithLoader showLoader={apiState.isFetching}>
        <div className="UniverseList">
          <div className="row">
            <Link
              to="/universes/new"
              className="Btn-default Btn-default-primary mtop-30 mbot-20 add-universe-btn"
            >
              <i className="icon-ic_plus" /> Create New Monitoring List
            </Link>
          </div>
          <div className="row">
            {items && !isEmpty(items) && (
              <div className="col-xs-12 col-md-6">
                <UniverseList universes={items} />
              </div>
            )}
            {changes && !isEmpty(items) && (
              <div className="col-xs-12 col-md-6">
                <div className="Changes">
                  <div className="Title-table">
                    <b>Latest Changes</b>
                  </div>
                  <div className="Content-table-last">
                    <Changes changes={changes} />
                  </div>
                </div>
              </div>
            )}
            {isEmpty(items) && (
              <div className="Empty-list-message">
                <h1>You currently don't have any monitoring lists.</h1>
                <h3>To create first one click button "Create New"</h3>
              </div>
            )}
          </div>
        </div>
      </ComponentWithLoader>
    );
  }
}

function mapStateToProps(state) {
  const { universe, api } = state;
  const apiState = api[fetchAllUniverses.name] || {};
  return {
    universe,
    apiState
  };
}

const mapDispatchToProps = {
  dispatchFetchAllUniverses: fetchAllUniverses,
  dispatchFetchAllChanges: fetchAllChanges
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniverseListContainer);
