import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hasPendingDuns } from '../../_selectors/dunsRequests';
import { hasUncompletedOnboardings } from '../../_selectors/workflows';
import { supplierDomains } from '../../_constants/supplier';
import Alert from './Alert';
import './Alert.css';
import { hasDraftUpdateRequest } from '../../_selectors/updateRequests';
import { push } from 'connected-react-router';

const supplierUrl = supplierDomains[process.env.REACT_APP_ENV];
const alertFlags = {
  UPDATE_ALERT: 'alertUpdateRequest',
  COMPANY_ALERT: 'alertConnectCompany',
  ONBOARDING_ALERT: 'alertOnboardingApproved'
};

class AlertContainer extends Component {
  state = {
    [alertFlags.COMPANY_ALERT]: !JSON.parse(
      localStorage.getItem(alertFlags.COMPANY_ALERT)
    ),
    [alertFlags.ONBOARDING_ALERT]: !JSON.parse(
      localStorage.getItem(alertFlags.ONBOARDING_ALERT)
    ),
    [alertFlags.UPDATE_ALERT]: !JSON.parse(
      localStorage.getItem(alertFlags.UPDATE_ALERT)
    )
  };

  goToOnboarding = workflow => {
    window.location.assign(
      `${supplierUrl}/onboarding/${workflow._id}/suppliers/${
        workflow.suppliers[0]._id
      }`
    );
  };

  goToUpdateRequest = () => {
    const { dispatch } = this.props;
    return dispatch(push('/update'));
  };

  handleCloseAlert = alert => {
    localStorage.setItem(alert, 'true');
    this.setState({ [`${alert}`]: false });
  };

  render() {
    const {
      hasPendingDuns,
      hasUncompletedOnboardings,
      hasUpdateRequest,
      user,
      workflows
    } = this.props;

    if (hasUncompletedOnboardings) {
      return workflows.active_onboardings.map(
        workflow =>
          workflow.getOwnerCompanyName() && (
            <Alert
              key={workflow.getId()}
              type="pointer"
              icon="info"
              onClick={() => this.goToOnboarding(workflow)}
              closeIconVisible={false}
            >
              <>
                Finish your onboarding for{' '}
                <var>{workflow.getOwnerCompanyName()}</var>
              </>
            </Alert>
          )
      );
    }

    if (hasUpdateRequest) {
      return (
        this.state[alertFlags.UPDATE_ALERT] && (
          <Alert
            type="pointer"
            icon="info"
            onClick={() => this.goToUpdateRequest()}
          >
            <>
              You have an open request to update information about your company.
              Complete it here.
            </>
          </Alert>
        )
      );
    }

    if (hasPendingDuns) {
      return (
        this.state[alertFlags.ONBOARDING_ALERT] && (
          <Alert
            type="info"
            icon="info"
            onClick={() => this.handleCloseAlert(alertFlags.ONBOARDING_ALERT)}
          >
            <>
              Welcome <var>{user.getFirstName()}</var>! This is a limited view.
              You will be able to see more options after your onboarding is
              approved.
            </>
          </Alert>
        )
      );
    }

    return (
      this.state[alertFlags.COMPANY_ALERT] &&
      !user.hasCompany() && (
        <Alert
          type="info"
          icon="info"
          onClick={() => this.handleCloseAlert(alertFlags.COMPANY_ALERT)}
        >
          <>
            Welcome <var>{user.getFirstName()}</var>! This is a limited view.
            You will be able to see more options after you claim your company.
          </>
        </Alert>
      )
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  workflows: state.workflows,
  hasPendingDuns: hasPendingDuns(state),
  hasUncompletedOnboardings: hasUncompletedOnboardings(state),
  hasUpdateRequest: hasDraftUpdateRequest(state)
});

export default connect(mapStateToProps)(AlertContainer);
