import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class BoxHeader extends Component {
  render() {
    const { title } = this.props;
    const link = title
      .split(' ')
      .pop()
      .toLowerCase();

    return (
      <div className="panel-heading">
        <Fragment>
          <h5>
            {title}
            <Link to={`/settings/${link}`} className="Link pull-right">
              Edit
            </Link>
          </h5>
        </Fragment>
      </div>
    );
  }
}

export default BoxHeader;
