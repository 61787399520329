const uiConstants = {
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  INITIAL_URL: 'UI_INITIAL_URL',
  UI_HEADER_BREADCRUMBS_UPDATE: 'UI_HEADER_BREADCRUMBS_UPDATE',
  UI_DUNS_REQUEST_FILE_UPLOAD_START: 'UI_DUNS_REQUEST_FILE_UPLOAD_START',
  UI_DUNS_REQUEST_FILE_UPLOAD_END: 'UI_DUNS_REQUEST_FILE_UPLOAD_END',
  UI_WALKTHROUGH_REFRESH_COMPLETED_STEPS:
    'UI_WALKTHROUGH_REFRESH_COMPLETED_STEPS',
  UI_WALKTHROUGH_SAVE_COMPLETED_STEP: 'UI_WALKTHROUGH_SAVE_COMPLETED_STEP',
  UI_WALKTHROUGH_CLEAR_COMPLETED_STEPS: 'UI_WALKTHROUGH_CLEAR_COMPLETED_STEPS',
  UI_WALKTHROUGH_MOUNTED: 'UI_WALKTHROUGH_MOUNTED',
  UI_WALKTHROUGH_UNMOUNTED: 'UI_WALKTHROUGH_UNMOUNTED'
};

export const urlConstants = {
  VERIFY: '/verify',
  LOGIN: '/login',
  HOME: '/home',
  GET_STARTED: '/get-started',
  CLAIM: '/claims',
  DUNS: '/duns',
  UPDATE: '/update',
  ONBOARDING: '/onboardings',
  SEARCH: '/search',
  MONITORING: '/monitoring',
  DBIA: '/dbia',
  CONFIRMATION: '/register/confirmation'
};

export default uiConstants;
