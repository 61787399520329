import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Auth } from 'react-common/services';
import { Loader } from 'react-common/components';
import { dunsRequestType } from 'react-common/constants';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import Wizard from '../_components/Wizard/Wizard';
import DunsFormContainer from './DunsForm/DunsFormContainer';
import Confirmation from './Confirmation';
// import Payment from './Payment';
import Verification from './Verification';
import Created from './Created';
import { wizardSteps } from '../_constants/dunsRequest';
import { getDunsRequestsForAccount, createDunsRequest } from '../_actions/';
import { getDunsRequest } from '../_selectors/dunsRequests';
import { getUserPhone } from '../_selectors/user';
import { viewGrants } from '../_constants/grants';
import { updateHeaderBreadcrumbs } from '../_actions';
import './GetDunsNumber.css';
import { withPermissions } from '../_components/Grant/Grant';
import PageNotFound from '../PageNotFound';
import {
  DunsAbbrText,
  DunsNumberAbbrText
} from 'react-common/components/DunsAbbr/DunsAbbr';

class GetDunsNumberContainer extends Component {
  steps = [
    {
      key: wizardSteps.FORM,
      title: 'Fill the form',
      component: DunsFormContainer
    },
    {
      key: wizardSteps.CALL,
      title: 'Confirmation call',
      component: Confirmation
    },
    // {
    //   key: wizardSteps.PAYMENT,
    //   title: 'Paymend',
    //   component: Payment
    // },
    {
      key: wizardSteps.VERIFICATION,
      title: 'Verification call',
      component: Verification
    },
    {
      key: wizardSteps.DELIVERY,
      title: `${DunsAbbrText} Created`,
      component: Created
    }
  ];

  componentDidMount() {
    const {
      dispatchGetDunsRequestsForAccount,
      dispatchCreateDunsRequest,
      dispatchUpdateHeaderBreadcrumbs,
      dunsRequest
    } = this.props;
    const dunsRequestType = this.getDunsType();

    if (!dunsRequest) {
      dispatchGetDunsRequestsForAccount().then(() => {
        if (!this.props.dunsRequest) {
          return dispatchCreateDunsRequest({
            duns_type: dunsRequestType,
            country: Auth.getUser().info.country
          });
        }
      });
    }

    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Get Started',
        path: '/get-started'
      },
      {
        title: `Get ${DunsNumberAbbrText}`
      }
    ]);
  }

  getDunsType = () => {
    const {
      location: { search },
      dunsRequest
    } = this.props;
    const query = queryString.parse(search);

    if (query.type) {
      return query.type;
    }

    if (dunsRequest) {
      return dunsRequest.duns_type;
    }

    return dunsRequestType.FREE;
  };

  render() {
    const { activeStepKey, dunsRequest, userPhone } = this.props;

    if (!dunsRequest) {
      return <Loader loading />;
    }

    return (
      <AppWrapper>
        <Wizard
          className="GetDunsNumber"
          activeStepKey={activeStepKey}
          steps={this.steps}
          props={{
            userPhone
          }}
        />
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => {
  const dunsRequest = getDunsRequest(state);
  const userPhone = getUserPhone(state);
  return {
    activeStepKey: dunsRequest && dunsRequest.step,
    userPhone,
    dunsRequest
  };
};

const mapDispatchToProps = {
  dispatchGetDunsRequestsForAccount: getDunsRequestsForAccount,
  dispatchCreateDunsRequest: createDunsRequest,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs
};

GetDunsNumberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetDunsNumberContainer);

export default withPermissions(viewGrants.DUNS, PageNotFound)(
  GetDunsNumberContainer
);
