const loginConstants = {
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  RESEND_EMAIL: 'RESEND_EMAIL',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  LOGOUT: 'LOGOUT'
};

export default loginConstants;
