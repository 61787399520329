import userConstants from '../_constants/user';
import { User } from '../_models/user';

const initialUserState = {
  user: new User()
};

export function user(state = initialUserState, action) {
  switch (action.type) {
    case userConstants.REGISTER:
    case userConstants.LOGIN:
    case userConstants.RELOAD:
      return {
        ...state,
        user: new User(action.data.user)
      };
    case userConstants.GET_USER_BY_REGISTRATION_TOKEN:
      return {
        ...state,
        user: new User(action.data)
      };
    case userConstants.UPDATE_USER:
      return {
        ...state,
        user: new User(action.data)
      };
    default:
      return state;
  }
}
