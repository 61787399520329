import DbiaService from '../_services/Dbia';
import { makeRequest } from './api';
import dbiaConstants from '../_constants/dbia';
import { Toast } from 'react-common/services';

export function getCountryProducts(countryCode, { onSuccess }) {
  return makeRequest({
    type: dbiaConstants.COUNTRY_PRODUCTS,
    apiCall: DbiaService.getCountryProducts,
    params: [countryCode],
    onSuccess: (dispatch, data) => onSuccess(data)
  });
}

export function getCompany(countryCode, dunsNumber, onStart, onEnd) {
  return makeRequest({
    type: dbiaConstants.PRODUCTS,
    apiCall: DbiaService.getCompany,
    params: [countryCode, dunsNumber],
    onRequestStart: onStart,
    onRequestEnd: onEnd
  });
}

export function showDbiaReportModal(order) {
  return dispatch => {
    dispatch({
      type: dbiaConstants.SHOW_REPORT,
      order
    });
  };
}

export function hideDbiaReportModal() {
  return dispatch => {
    dispatch({
      type: dbiaConstants.HIDE_REPORT
    });
  };
}

export function getDbiaReport(orderId, onFailure) {
  return makeRequest({
    type: dbiaConstants.REPORT,
    apiCall: DbiaService.getDbiaReport,
    params: [orderId],
    onFailure
  });
}

export function getOrders() {
  return makeRequest({
    type: dbiaConstants.ORDERS,
    apiCall: DbiaService.getOrders
  });
}

export function createOrder({ order, options = {} }) {
  const { onStart, onEnd, onSuccess } = options;

  return makeRequest({
    type: dbiaConstants.ORDER,
    apiCall: DbiaService.createOrder,
    params: [order],
    onRequestStart: onStart,
    onRequestEnd: onEnd,
    onSuccess: (dispatch, data) => {
      Toast.showSuccess('You have successfully ordered a report');
      onSuccess(data);
    },
    onFailure: (dispatch, error) => Toast.showWarning(error.error)
  });
}

export function emailDbiaReport(orderId, emails) {
  return makeRequest({
    type: dbiaConstants.EMAIL_REPORT,
    apiCall: DbiaService.emailReport,
    params: arguments
  });
}

export function getDbiaAccount() {
  return makeRequest({
    type: dbiaConstants.ACCOUNT,
    apiCall: DbiaService.getAccount,
    params: []
  });
}

export function getDbiaTransactions(fromDate, toDate) {
  return makeRequest({
    type: dbiaConstants.TRANSACTIONS,
    apiCall: DbiaService.getTransactions,
    params: [fromDate, toDate]
  });
}

export function getOrdersWithQuery(order) {
  return makeRequest({
    type: dbiaConstants.CHECK,
    apiCall: DbiaService.getOrdersWithQuery,
    params: [order],
    onSuccess: (dispatch, data) => data,
    onFailure: (dispatch, error) => Toast.showWarning(error.error)
  });
}
