import React, { Component, Fragment } from 'react';
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import './Dbia.css';
const dbiaVariants = {
  global: {
    url: 'https://dbia.dnb.com',
    title: 'Global Customer Access'
  },
  brazil: {
    url: 'https://www.dnbbra.com.br/pt/default.aspx',
    title: 'Brazil-Specific Access'
  },
  peru: {
    url: 'http://www.dnbperu.com/login.php',
    title: 'Peru-Specific Access'
  }
};

class DbiaAccess extends Component {
  state = {
    country: 'global'
  };

  setIframeUrl = country => this.setState({ country });

  render() {
    const { opalCredentialsExist } = this.props;
    const { country } = this.state;
    return (
      <Fragment>
        <div className="col-md-6 dbia-dropdown">
          <DropdownButton
            id="dropdown-basic-button"
            onSelect={value => this.setIframeUrl(value)}
            title={dbiaVariants[country].title}
          >
            {Object.keys(dbiaVariants).map(country => (
              <MenuItem eventKey={country} key={country}>
                {dbiaVariants[country].title}
              </MenuItem>
            ))}
          </DropdownButton>
        </div>
        {opalCredentialsExist && (
          <div className="col-md-6 dbia-credentials-link">
            <Link className="signup-link" to="/dbia/credentials">
              DBIA Credentials
            </Link>
          </div>
        )}
        <div className="dbia-login">
          <Iframe
            url={dbiaVariants[country].url}
            width="100%"
            height="100vh"
            position="relative"
          />
        </div>
      </Fragment>
    );
  }
}

export default DbiaAccess;
