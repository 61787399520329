export function getErrorFromResponse(response) {
  if (!response.text) {
    return { error: response };
  }

  return response.text().then(errorMessage => {
    try {
      return JSON.parse(errorMessage);
    } catch (err) {
      return { error: errorMessage };
    }
  });
}

export function getCallerName() {
  try {
    let stack = new Error().stack.split('\n');
    stack.splice(stack.indexOf('Error'), 1);

    if (!stack || !stack.length || stack.length < 3) {
      return undefined;
    }

    stack = stack[2];

    const caller = stack
      .replace(/Object./, '')
      .replace(/^\s+(@|at)/, '')
      .replace(/@.*/, '')
      .replace(/ \(.+\)$/, '')
      .trim();

    return caller || undefined;
  } catch (error) {
    return undefined;
  }
}
