import { dunsRequestStatuses } from '../_constants/dunsRequest';

export const isForCountry = (dunsRequest, ...countries) =>
  countries.findIndex(dunsRequest.country) > -1;

export const isCountry = (country, ...countryNames) => {
  if (!country) {
    return false;
  }

  return countryNames.some(name => {
    return name.toLowerCase() === country.title.toLowerCase();
  });
};

export const isCountryCCS = (country, CCSCountryList) => {
  if (country.title === 'CCS') {
    return true;
  }

  if (!CCSCountryList) {
    return false;
  }

  return CCSCountryList.indexOf(country.title) > -1;
};

export const isDunsRequestDraft = dunsRequest =>
  dunsRequest.status === dunsRequestStatuses.DRAFT;
