import React, { Component } from 'react';
import { connect } from 'react-redux';
import Home from './Home';
import { hasUncompletedOnboardings } from '../_selectors/workflows';
import { reloadUser, updateHeaderBreadcrumbs } from '../_actions';
import { withPermissions } from '../_components/Grant/Grant';
import { viewGrants } from '../_constants/grants';
import PageNotFound from '../PageNotFound';
import { ComponentWithLoader } from 'react-common/components';
import { getInitialUrl } from '../_selectors/ui';
import { Redirect } from 'react-router-dom';

class HomeContainer extends Component {
  componentDidMount() {
    const { dispatchUpdateHeaderBreadcrumbs } = this.props;

    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Home'
      }
    ]);
  }

  render() {
    const { apiState, initialUrl } = this.props;

    if (initialUrl) {
      return <Redirect to={initialUrl} />;
    }

    return (
      <ComponentWithLoader showLoader={apiState.isFetching}>
        <Home {...this.props} />
      </ComponentWithLoader>
    );
  }
}

function mapStateToProps(state) {
  const {
    user: { user },
    api,
    workflows
  } = state;
  const company = user.getCompany();
  const apiState = api[reloadUser.name] || {};

  return {
    user,
    workflows,
    company,
    apiState,
    initialUrl: getInitialUrl(state),
    hasUncompletedOnboardings: hasUncompletedOnboardings(state)
  };
}

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs
};

HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainer);

export default withPermissions(viewGrants.HOME, PageNotFound)(HomeContainer);
