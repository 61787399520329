import { birRequestStatuses } from 'react-common/constants';
import get from 'lodash/get';

export const getUpdateRequests = state => state.updateRequests.items;

export const getUpdateRequest = state => {
  const requests = getUpdateRequests(state);

  return Object.values(requests)[0];
};

export const isDraftUpdateRequest = updateRequest => {
  return !!(updateRequest && updateRequest.status === birRequestStatuses.DRAFT);
};

export const hasSubmittedUpdateRequest = state => {
  const updateRequest = getUpdateRequest(state);
  return !!(
    updateRequest && updateRequest.status === birRequestStatuses.REVIEW
  );
};

export const hasDraftUpdateRequest = state => {
  const updateRequest = getUpdateRequest(state);
  return isDraftUpdateRequest(updateRequest);
};

export const getUpdateRequestFiles = state => {
  const updateRequest = getUpdateRequest(state);
  const files = get(updateRequest, 'files', {})._files;
  if (!files) {
    return {};
  }
  return files.reduce((map, file) => {
    map[file.type] = file;
    return map;
  }, {});
};
