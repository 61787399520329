import React, { Component } from 'react';
import { MomentDate } from 'react-common/components';
import { Localize } from 'react-common/services';

import './SingleChange.css';

const DIFF_TYPE = {
  CHANGE: 'change',
  INSERT: 'insert'
};

class SingleChange extends Component {
  render() {
    const { change, company } = this.props;

    const getDiff = () => {
      const diffs = change.changes;

      if (diffs && diffs.length) {
        return diffs.map(diff => (
          <div key={diff.title}>
            {diff.type === DIFF_TYPE.CHANGE && (
              <>
                <h5>{diff.title}</h5>
                <span className="Old-change">{diff.values.old}</span>
                <span className="New-change">{diff.values.new}</span>
              </>
            )}
            {diff.type === DIFF_TYPE.INSERT && (
              <>
                <h5>{diff.title}</h5>
                {diff.values.map(value => (
                  <span key={value} className="New-change">
                    {value}
                  </span>
                ))}
              </>
            )}
          </div>
        ));
      }
    };

    return (
      <div className="Change">
        <div className="Change-info">
          <span className="Company-name">
            {company && <b>{company.legal_name}</b>}
          </span>
          <span className="Created-at">
            <MomentDate
              format="MMMM DD, YYYY"
              locale={Localize.getLanguage()} // TODO mateo improve this
              date={change.created_at}
            />
          </span>
        </div>
        <div className="Change-details">{getDiff()}</div>
      </div>
    );
  }
}

export default SingleChange;
