import { Auth, Fetch, Localize } from 'react-common/services';

import { bugsnagClient } from '../index';

class UserService {
  reload() {
    if (!Auth.isUserAuthenticated()) {
      return Promise.resolve();
    }

    const { _id: accountId } = Auth.getUser();
    return Fetch.getJSON(`/api/accounts/${accountId}?populate=true`).then(
      account => {
        const {
          _id,
          info,
          companies,
          preferences,
          registration,
          duns_requests,
          security,
          claims,
          subscription,
          active_onboardings,
          pulse,
          update_requests
        } = account;
        const user = {
          _id,
          info,
          subscription,
          companies,
          preferences,
          registration,
          security,
          pulse
        };

        Auth.setUser(user);
        if (preferences && preferences.language) {
          Localize.setLanguage(preferences.language);
        }
        return {
          user,
          duns_requests,
          claims,
          subscription,
          update_requests,
          active_onboardings
        };
      }
    );
  }

  changeAccountSettings(account) {
    return Fetch.putJSON(`/api/accounts/${Auth.getUser()._id}`, account);
  }

  changePassword(oldPassword, newPassword) {
    const { email } = Auth.getUser().info;
    return Fetch.postJSON('/api/registration/local/password-change', {
      email,
      oldPassword,
      newPassword
    });
  }

  connectWithPlugins(user) {
    if (window.analytics) {
      const lastLogin = Math.floor(Date.now() / 1000);
      window.analytics.identify(user.info.email, {
        name: user.info.name,
        email: user.info.email,
        phone: user.info.phone,
        mycialLastLogin: lastLogin
      });
    }

    if (bugsnagClient) {
      bugsnagClient.user = {
        id: user._id,
        name: user.info.name,
        email: user.info.email
      };
    }

    if (user.preferences && user.preferences.language) {
      Localize.setLanguage(user.preferences.language);
    }
  }

  getUserByRegistrationToken(registrationToken = '') {
    return Fetch.getJSON(`/auth/accounts?ref=login&token=${registrationToken}`);
  }
}

export default new UserService();
