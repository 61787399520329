import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCompanyByDunsNumber } from '../../../_selectors/universe';

import CompanyInfo from './CompanyInfo';

class CompanyInfoContainer extends Component {
  render() {
    const { company, removeDuns } = this.props;
    return company && <CompanyInfo company={company} removeDuns={removeDuns} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    company: getCompanyByDunsNumber(state, props.duns)
  };
};

export default connect(mapStateToProps)(CompanyInfoContainer);
