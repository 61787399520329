export const getDbiaCompanies = state => state.dbia.companies;

export const getDbiaOrders = state => state.dbia.orders;

export const getDbiaOrder = state => state.dbia.order;

export const getDbiaOrderReport = state => state.dbia.report;

export const getDbiaAccount = state => state.dbia.account;

export const getDbiaTransactions = state => state.dbia.transactions;
