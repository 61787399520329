import { Fetch } from 'react-common/services';

class UniverseService {
  fetchAll() {
    return Fetch.get(`/api/universes`);
  }

  fetchById(universeId) {
    return Fetch.get(`/api/universes/${universeId}`);
  }

  fetchChangesByUniverseId(universeId) {
    return Fetch.get(`/api/universes/${universeId}/changes`);
  }

  fetchAllChanges() {
    return Fetch.get(`/api/universes/changes`);
  }

  createUniverse(name, fields = [], duns = []) {
    return Fetch.postJSON(`/api/universes`, {
      name,
      source: 'mycial',
      duns,
      fields
    });
  }

  deleteUniverse(universeId) {
    return Fetch.deleteJSON(`/api/universes/${universeId}`);
  }

  async addDunsToUniverse(universeId, duns, isNew) {
    if (isNew) {
      let newUniverse = await this.createUniverse(universeId, [], [duns]);
      universeId = newUniverse['_id'];
    }

    return Fetch.patchJSON(`/api/universes/${universeId}/duns`, {
      duns,
      operation: 'add'
    });
  }

  removeDunsFromUniverse(universeId, duns) {
    return Fetch.patchJSON(`/api/universes/${universeId}/duns`, {
      duns,
      operation: 'remove'
    });
  }
}

export default new UniverseService();
