import { Fetch } from 'react-common/services';

class UpdateRequest {
  getAdminUpdateRequestById(updateRequestId, accountId) {
    return Fetch.getJSON(`/api/admin/update-requests/` + updateRequestId, {
      headers: { account_id: accountId }
    });
  }

  updateAdminUpdateRequestById(updateRequestId, changedData, accountId) {
    return Fetch.putJSON(
      `/api/admin/update-requests/${updateRequestId}`,
      changedData,
      { headers: { account_id: accountId } }
    );
  }

  getUpdateRequestForAccount() {
    return Fetch.getJSON(`/api/update-requests`);
  }

  updateUpdateRequestById(updateRequestId, changedData) {
    return Fetch.putJSON(
      `/api/update-requests/${updateRequestId}`,
      changedData
    );
  }
  fieldUpdateUpdateRequestById(updateRequestId, changedData) {
    return Fetch.patchJSON(
      `/api/update-requests/${updateRequestId}`,
      changedData
    );
  }

  submitUpdateRequestById(updateRequestId, updateRequestData) {
    return Fetch.postJSON(
      `/api/update-requests/${updateRequestId}/submit`,
      updateRequestData
    );
  }

  uploadFile(updateRequestId, file) {
    return Fetch.patch(`/api/update-requests/${updateRequestId}/files`, file);
  }

  deleteFile(updateRequestId, fileName) {
    return Fetch.deleteJSON(
      `/api/update-requests/${updateRequestId}/files?fileName=${encodeURIComponent(
        fileName
      )}`
    );
  }
}

export default new UpdateRequest();
