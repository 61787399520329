import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { resendEmail } from '../_actions';
import VerifyEmail from './VerifyEmail';

class VerifyEmailContainer extends Component {
  onResendEmail = () => {
    const { dispatch } = this.props;
    const { _id: accountId } = Auth.getUser();
    return dispatch(resendEmail(accountId));
  };

  componentDidMount() {
    // force update of this component every second
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    // redirect to home if already authorized
    if (Auth.isUserAuthenticated() && Auth.isAccountConfirmed()) {
      return <Redirect to="/home" />;
    }
    return <VerifyEmail {...this.props} onResendEmail={this.onResendEmail} />;
  }
}

function mapStateToProps(state) {
  const { api } = state;
  const apiState = api[resendEmail.name] || {};

  return {
    apiState
  };
}

export default connect(mapStateToProps)(VerifyEmailContainer);
