import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import { updateHeaderBreadcrumbs } from '../_actions';
import Orders from './Orders';
import { getDbiaOrders } from '../_selectors/dbia';
import { getOrders } from '../_actions/dbia';
import { ComponentWithLoader } from 'react-common/components';
import './Orders.css';
import { viewGrants } from '../_constants/grants';
import { withPermissions } from '../_components/Grant/Grant';
import PageNotFound from '../PageNotFound';

class OrdersContainer extends Component {
  componentDidMount() {
    const {
      dispatchUpdateHeaderBreadcrumbs,
      dispatchGetDbiaOrders
    } = this.props;
    dispatchGetDbiaOrders();
    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'My orders'
      }
    ]);
  }

  render() {
    const { apiState, orders } = this.props;

    return (
      <AppWrapper>
        <ComponentWithLoader showLoader={apiState.isFetching}>
          <Orders orders={orders} dateFormat={'YYYY-MM-DD'} />
        </ComponentWithLoader>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { api } = state;
  const apiState = api[getOrders.name] || {};
  return {
    orders: getDbiaOrders(state),
    apiState
  };
}

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchGetDbiaOrders: getOrders
};

OrdersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersContainer);

export default withPermissions(viewGrants.DBIA_ORDERS, PageNotFound)(
  OrdersContainer
);
