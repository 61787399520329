import React from 'react';
import PropTypes from 'prop-types';

function AdminLink({ link, children }) {
  return (
    <a href={link}>
      <i className="icon-ic_admin-panel" />
      {children}
    </a>
  );
}

AdminLink.propTypes = {
  children: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default AdminLink;
