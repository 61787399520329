import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import opalDelivery from '../_images/opal-delivery.svg';

class DBIACredentials extends Component {
  render() {
    const { opalCredentials } = this.props;

    return (
      <Fragment>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="padding-lg">
              <div className="row text-center">
                <div className="col-xs-12 mtop-30 mbot-20">
                  <img
                    src={opalDelivery}
                    alt="solution-1"
                    className="mtop-30 mbot-20"
                  />
                </div>
                <div className="col-xs-12 mbot-20">
                  <h4>Your DBIA credentials have been created!</h4>
                </div>
                <div className="col-xs-12 col-lg-6 col-lg-offset-3">
                  <div className="row">
                    <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                      <div className="form-group mtop-10">
                        <label>Subscriber Number</label>
                        <input
                          type="number"
                          className="form-control"
                          name="subscriber-number"
                          value={opalCredentials.number}
                          readOnly="readOnly"
                        />
                      </div>

                      <div className="form-group mtop-10">
                        <label>User ID</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subscriber-user-id"
                          value={opalCredentials.user_id}
                          readOnly="readOnly"
                        />
                      </div>

                      <div className="form-group mtop-10">
                        <label>Password</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subscriber-password"
                          value={opalCredentials.password}
                          readOnly="readOnly"
                        />
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <Link
                        className="Btn-default Btn-default-primary"
                        to="/dbia"
                      >
                        DBIA Access
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DBIACredentials;
