import React, { Component } from 'react';
import {
  CountryDropdownField,
  DunsNumberAbbr,
  PasswordField,
  Sidebar,
  TextField
} from 'react-common/components';
import { Link } from 'react-router-dom';
import { reduxForm } from 'react-common/libs/reduxForm';

import PublicHeader from '../_components/Header/PublicHeader';
import logo from '../_images/mycial-logo.svg';
import logoColor from '../_images/mycial-logo-color.svg';

import './Register.css';
import { Validation } from 'react-common/services';

const minPhoneLength = Validation.minLength(
  10,
  'Please include the country code.'
);
const minPasswordLength = Validation.minLength(7);
const phoneExtLength = Validation.exactLength(
  [4, 6],
  'Phone extension must have 4 or 6 numbers'
);

class RegisterForm extends Component {
  render() {
    const { handleSubmit, submitting, invalid, token, user } = this.props;

    return (
      <div className="Register">
        <Sidebar
          logo={logo}
          logoAlt="MyCIAL"
          className="col-md-3 hidden-sm hidden-xs"
          headerText="Create account"
          descriptionText={
            <>
              <p>
                Fill out the information to create a new account & access your
                commercial profile.
              </p>
              <p>By creating an account, you will be able to:</p>
              <ul>
                <li>Verify & update your company’s profile</li>
                <li>
                  Look up & request a <DunsNumberAbbr />
                </li>
                <li>
                  Search for other organizations' <DunsNumberAbbr />
                </li>
              </ul>
            </>
          }
          showContact={true}
        />
        <div className="col-md-9">
          <div className="row">
            <PublicHeader />
            <div className="col-md-6 col-md-offset-3 col-lg-5 col-lg-offset-3">
              <form onSubmit={handleSubmit} className="Login-form">
                <div>
                  <img
                    src={logoColor}
                    alt="MyCIAL"
                    className="logo2 visible-sm visible-xs"
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    name="name"
                    className="form-control"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    validate={[Validation.required]}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    disabled={token}
                    type="email"
                    name="email"
                    className="form-control"
                    label="Work Email"
                    placeholder="Enter Work Email"
                    autoComplete="off"
                    validate={[Validation.required]}
                  />
                </div>

                <div className="form-group">
                  <PasswordField
                    name="password"
                    className="form-control"
                    label="Password"
                    placeholder="7+ characters"
                    validate={[Validation.required, minPasswordLength]}
                  />
                </div>

                <CountryDropdownField
                  validate={Validation.required}
                  label="Your Company's Country"
                  name="country"
                  user={user}
                />

                <div className="row">
                  <div className="form-group col-xs-7 col-lg-7">
                    <TextField
                      type="text"
                      name="phone"
                      className="form-control"
                      label="Contact Phone"
                      placeholder="example: +58 98375545"
                      autoComplete="off"
                      validate={[Validation.required, minPhoneLength]}
                    />
                  </div>

                  <div className="form-group col-xs-5 col-lg-5 phone-extension">
                    <TextField
                      type="text"
                      className="form-control"
                      label="Phone Ext."
                      name="phone_ext"
                      placeholder="4 or 6 digits"
                      autoComplete="off"
                      showOptionalLabel="true"
                      validate={[Validation.isInteger, phoneExtLength]}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    disabled={submitting || invalid}
                    className="Btn-default Btn-default-primary form-control"
                  >
                    Create an account
                  </button>
                </div>
                <div className="link-div visible-sm visible-xs">
                  <Link className="signup-link" to="/login">
                    Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Register = reduxForm({
  form: 'register',
  enableReinitialize: true
})(RegisterForm);

export default Register;
