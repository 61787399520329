import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthorizedHeader from '../Header/AuthorizedHeader';
import NavigationMenu from '../NavigationMenu';

import './AppWrapper.css';
import DbiaReportModal from '../Dbia/DbiaReportModal';

class AppWrapper extends Component {
  state = {
    sidebarOpened: true
  };

  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened
    });
  };

  render() {
    const { sidebarOpened } = this.state;
    const { className } = this.props;
    return (
      <div className={className}>
        <NavigationMenu
          className={`col-sm-3 col-lg-2 Navigation-animated ${
            sidebarOpened ? 'Sidebar-visible' : 'Sidebar-hidden'
          }`}
        />
        <div
          className={`col-xs-12 Container-animated ${
            sidebarOpened
              ? 'col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'
              : 'col-xs-12'
          }`}
        >
          <div className="row">
            <AuthorizedHeader
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={sidebarOpened}
              className="col-lg-10 col-sm-9 col-xs-12"
            />
            <div className="AppWrapper-content container-fluid">
              {this.props.children}
            </div>
            <DbiaReportModal />
          </div>
        </div>
      </div>
    );
  }
}

AppWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default AppWrapper;
