import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import UserComponent from '../_components/UserComponent';
import CompanyComponent from '../_components/CompanyComponent';
import OnboardingComponent from '../_components/Onboarding/Onboarding';
import SearchComponent from '../_components/Search/Search';
import AlertContainer from '../_components/Alert/AlertContainer';
import './Home.css';
import Grant from '../_components/Grant/Grant';
import { viewGrants } from '../_constants/grants';

function Home({ user, company, workflows, hasUncompletedOnboardings }) {
  return (
    <AppWrapper>
      <AlertContainer />
      <div className="row">
        {hasUncompletedOnboardings && (
          <div className="col-xs-12 col-md-8">
            <div className="panel panel-onboarding">
              <div className="panel-heading">
                <h5>
                  Active onboardings
                  <Link className="pull-right" to="/onboardings">
                    View
                  </Link>
                </h5>
              </div>
              <div className="panel-body">
                <OnboardingComponent workflows={workflows.active_onboardings} />
              </div>
            </div>
          </div>
        )}
        <div className="clearfix" />
        <div className="col-xs-12 col-sm-6 col-md-4">
          <UserComponent user={user} className={'panel'} showBoxHeader={true} />
        </div>
        {company && (
          <div className="col-xs-12 col-sm-6 col-md-4">
            <CompanyComponent
              showBoxHeader={true}
              className={'panel'}
              company={company.company}
            />
          </div>
        )}
        <div className="clearfix" />
        <Grant permissions={viewGrants.SEARCH}>
          <div className="col-xs-12 col-md-8">
            <div className="panel">
              <div className="panel-heading">
                <h5>
                  Quick company search
                  <Link className="pull-right" to="/search">
                    Advanced search
                  </Link>
                </h5>
              </div>
              <div className="panel-body">
                <SearchComponent itemsPerPage={10} />
              </div>
            </div>
          </div>
        </Grant>
      </div>
    </AppWrapper>
  );
}

export default connect()(Home);
