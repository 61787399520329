import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, change } from 'react-common/libs/reduxForm';
import { getCompanyByIdOrDuns } from '../../_actions/company';
import CompanyProfile from './CompanyProfile';
import { changeCompanyLogo } from '../../_actions/company';

class CompanyProfileContainer extends Component {
  componentDidMount() {
    const { dispatchGetCompanyById, user } = this.props;
    const companyId = user.getCompanyId();

    if (companyId) {
      dispatchGetCompanyById(companyId);
    }
  }

  onUploadComplete = info => {
    const { dispatch } = this.props;
    dispatch(change('company', 'info.logo', info.cdnUrl));
    dispatch(submit('company'));
  };

  submitAccount = company => {
    const { dispatch } = this.props;
    dispatch(changeCompanyLogo(company._id, company.info.logo));
  };

  render() {
    const { company } = this.props;

    return (
      company && (
        <CompanyProfile
          onUploadComplete={this.onUploadComplete}
          onSubmit={this.submitAccount}
          {...this.props}
        />
      )
    );
  }
}

function mapStateToProps(state) {
  const {
    user: { user }
  } = state;
  const { form: { company: { values } = {} } = {} } = state;
  const company = user.getCompany();
  const language = user.getLanguage();

  return {
    initialValues: company.company,
    formValues: values,
    user,
    company,
    language
  };
}

const mapDispatchToProps = {
  dispatchGetCompanyById: getCompanyByIdOrDuns
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyProfileContainer);
