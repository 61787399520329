import React, { Component } from 'react';
import { reduxForm } from 'react-common/libs/reduxForm';
import { PasswordField } from 'react-common/components';
import { Validation } from 'react-common/services';

const minPasswordLength = Validation.minLength(7);

class PasswordForm extends Component {
  render() {
    const { handleSubmit, invalid } = this.props;

    return (
      <div className="panel Password">
        <h2 className="panel-heading Title-table">Change Password</h2>
        <div className="panel-content Content-table-last">
          <form onSubmit={handleSubmit}>
            <PasswordField
              name="oldPassword"
              className="form-control"
              label="Old Password"
              placeholder="Old Password"
              validate={[Validation.required, minPasswordLength]}
            />
            <PasswordField
              name="password"
              className="form-control"
              label="New Password"
              placeholder="New Password"
              validate={[Validation.required, minPasswordLength]}
            />

            <PasswordField
              name="confirmPassword"
              className="form-control"
              label="Confirm Password"
              placeholder="Confirm Password"
              validate={[Validation.required, Validation.passwordsMatch]}
            />

            <button
              disabled={invalid}
              type="submit"
              className="Btn-default Btn-default-primary"
            >
              Change
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const Password = reduxForm({
  form: 'reset-password'
})(PasswordForm);

export default Password;
