import React, { Component } from 'react';

import SearchComponent from '../../_components/Search/Search';
import UniverseResults from '../../_components/Search/Templates/UniverseResults';

class CreateUniverse extends Component {
  addDuns = (company, universes) => {
    universes.map(async universe => {
      this.props.addDunsToUniverse(
        universe.value,
        company.duns,
        universe['__isNew__']
      );
    });
  };

  render() {
    const { selectedUniverseId } = this.props;
    return (
      <div>
        <h4>Search companies to add them to monitoring list:</h4>
        <SearchComponent
          ResultsTemplate={UniverseResults}
          onSelect={this.addDuns}
          additionalProps={{ selectedUniverseId }}
        />
      </div>
    );
  }
}

export default CreateUniverse;
