import dunsRequestConstants from '../_constants/dunsRequest';
import DunsRequest from '../_services/DunsRequest';
import { dunsRequestFileUploadStart, dunsRequestFileUploadEnd } from './ui';
import { makeRequest } from './api';

export function getDunsRequestsForAccount() {
  return makeRequest({
    type: dunsRequestConstants.GET_DUNS_REQUESTS_FOR_ACCOUNT,
    apiCall: DunsRequest.getRequestsForAccount
  });
}

export function createDunsRequest(dunsRequest = {}) {
  return makeRequest({
    type: dunsRequestConstants.CREATE_DUNS_REQUEST,
    apiCall: DunsRequest.create,
    params: [dunsRequest]
  });
}

export function patchDunsRequest(id, dunsRequest = {}) {
  return makeRequest({
    type: dunsRequestConstants.PATCH_DUNS_REQUEST,
    apiCall: DunsRequest.patch,
    params: [id, dunsRequest]
  });
}

export function uploadDunsRequestFile(id, fileName, fileData) {
  return makeRequest({
    type: dunsRequestConstants.UPLOAD_DUNS_REQUEST_FILE,
    apiCall: DunsRequest.uploadFile,
    params: [id, fileData],
    onRequestStart: dispatch => dispatch(dunsRequestFileUploadStart(fileName)),
    onRequestEnd: dispatch => dispatch(dunsRequestFileUploadEnd(fileName))
  });
}

export function deleteDunsRequestFile(id, fileId) {
  return makeRequest({
    type: dunsRequestConstants.DELETE_DUNS_REQUEST_FILE,
    apiCall: DunsRequest.deleteFile,
    params: [id, fileId]
  });
}

export function submitDunsRequest(id, dunsRequest = {}) {
  return makeRequest({
    type: dunsRequestConstants.SUBMIT_DUNS_REQUEST,
    apiCall: DunsRequest.submit,
    params: [id, dunsRequest]
  });
}

export function markDunsRequestAsSeen(id) {
  return makeRequest({
    type: dunsRequestConstants.MARK_DUNS_REQUEST_AS_SEEN,
    apiCall: DunsRequest.markAsSeen,
    params: [id]
  });
}

/**
 * TODO: Find a better solution
 * This is a HACK used to cleanup the redux form "syncErrors" state.
 * Problem happens when 2nd address' fields get added and removed dynamically,
 * causing the redux form to keep the values even if the fields are not there
 * anymore. We solve this by dispatching unregister field action. But still,
 * redux form fails to cleanup 2nd address syncError state completely and
 * leaves dangling empty {} which it later recognizes as an error.
 * This uses redux-form's internal action to clean up an empty object from syncErrors
 */
export function forceAddressErrorCleanup(syncErrors = {}) {
  return dispatch => {
    syncErrors.company.addresses.splice(1, 1);

    // cleanup addresses object if it doesn't contain error fields
    if (!syncErrors.company.addresses[0]) {
      delete syncErrors.company.addresses;
    }

    // cleanup company error object if it doesn't contain error fields
    if (Object.keys(syncErrors.company).length === 0) {
      delete syncErrors.company;
    }

    // Internal redux-form action.
    // Forces redux form to set up new syncErrors object
    dispatch({
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'dunsRequest'
      },
      payload: {
        syncErrors
      }
    });
  };
}
