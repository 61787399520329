import { Auth, Toast } from 'react-common/services';
import { push } from 'connected-react-router';

import LoginService from '../_services/Login';
import loginConstants from '../_constants/login';
import { reloadUser } from './user';
import { makeRequest } from './api';
import { getDunsRequestsForAccount } from './dunsRequests';
import { getClaimsForAccount } from './claims';
import { getUpdateRequestForAccount } from './updateRequests';
import { urlConstants } from '../_constants/ui';
import { setInitialUrl } from './ui';

const getUserData = dispatch => {
  dispatch(getDunsRequestsForAccount());
  dispatch(getClaimsForAccount());
  dispatch(getUpdateRequestForAccount());
  dispatch(reloadUser());
};

function registerSuccess(email, password) {
  return dispatch => {
    return LoginService.login(email, password).then(() => {
      getUserData(dispatch);
      dispatch(setInitialUrl(urlConstants.VERIFY));
    });
  };
}

export function register(user) {
  return makeRequest({
    type: loginConstants.REGISTER,
    apiCall: LoginService.register,
    params: [user],
    onSuccess: registerSuccess(user.email, user.password),
    onFailure: (dispatch, error) => Toast.showWarning(`${error.error}`)
  });
}

export function resendEmail(accountId) {
  return makeRequest({
    type: loginConstants.RESEND_EMAIL,
    apiCall: LoginService.resendEmail,
    params: [accountId]
  });
}

function confirmEmailSuccess() {
  return dispatch => {
    const user = Auth.getUser();
    user.registration.confirmed = true;
    Auth.setUser(user);
    dispatch(push(urlConstants.HOME));
  };
}

export function confirmEmail(token) {
  return makeRequest({
    type: loginConstants.CONFIRM_EMAIL,
    apiCall: LoginService.confirmEmail,
    params: [token],
    onSuccess: confirmEmailSuccess()
  });
}

function loginSuccess() {
  return dispatch => {
    getUserData(dispatch);
  };
}

export function login(user) {
  const { email, password } = user;
  return makeRequest({
    type: loginConstants.LOGIN,
    apiCall: LoginService.login,
    params: [email, password],
    onSuccess: loginSuccess(),
    onFailure: (dispatch, error) => Toast.showWarning(`${error.error}`)
  });
}

function logoutRequest() {
  return {
    type: loginConstants.LOGOUT
  };
}

export function logout() {
  return dispatch => {
    dispatch(logoutRequest());
    LoginService.logout();
    return dispatch(push('/'));
  };
}

export function forgotPassword(email) {
  return makeRequest({
    type: loginConstants.FORGOT_PASSWORD,
    apiCall: LoginService.forgotPassword,
    params: [email],
    onSuccess: () =>
      Toast.showSuccess(
        `A link to reset your password has been sent to ${email}. Please check your inbox.`
      ),
    onFailure: (dispatch, error) => Toast.showWarning(`${error.error}`)
  });
}

function resetPasswordSuccess() {
  Toast.showSuccess(
    'Your password has been changed successfully. We will get you to login in a second.'
  );
  return dispatch => setTimeout(() => dispatch(push(urlConstants.LOGIN)), 2000);
}

export function resetPassword(token, password) {
  return makeRequest({
    type: loginConstants.RESET_PASSWORD,
    apiCall: LoginService.resetPassword,
    params: [token, password],
    onSuccess: resetPasswordSuccess(),
    onFailure: (dispatch, error) => Toast.showWarning(`${error.error}`)
  });
}
