import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { ComponentWithLoader } from 'react-common/components';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import { updateSearchResults } from '../_actions/search';
import { getCompanyByIdOrDuns } from '../_actions/company';

import CompanyProfile from './CompanyProfile';

class CompanyProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    const duns = this.props.match.params.duns;
    const { dispatchGetCompanyByIdOrDuns } = this.props;
    if (duns) {
      dispatchGetCompanyByIdOrDuns(duns, this.handleFetchingCompanyEnd);
    }
  }

  handleFetchingCompanyEnd = () => {
    const { company, dispatchGetUDMCompany } = this.props;
    const duns = this.props.match.params.duns;
    if (isEmpty(company.udm)) {
      dispatchGetUDMCompany({ name: duns });
    }
  };

  onScroll = reference => {
    window.scrollTo({ top: reference.clientY, behavior: 'smooth' });
  };

  render() {
    const { company, udm, apiState } = this.props;

    return (
      <AppWrapper>
        <ComponentWithLoader showLoader={apiState.isFetching || !udm}>
          <CompanyProfile
            scrollRef={this.scrollRef}
            onScroll={this.onScroll}
            udm={udm}
            company={company}
          />
        </ComponentWithLoader>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    search: { results },
    company: { company },
    api
  } = state;
  const apiState = api.updateSearchResults || {};
  const udm = results.length ? results[0] : company.udm;

  return {
    udm,
    company,
    apiState
  };
}

const mapDispatchToProps = {
  dispatchGetUDMCompany: updateSearchResults,
  dispatchGetCompanyByIdOrDuns: getCompanyByIdOrDuns
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyProfileContainer);
