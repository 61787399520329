import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getFormValues } from 'react-common/libs/reduxForm';

import { forgotPassword } from '../_actions';
import ForgotPassword from './ForgotPassword';

class ForgotPasswordContainer extends Component {
  submit = formData => {
    const { dispatch } = this.props;
    return dispatch(forgotPassword(formData.email));
  };

  render() {
    // redirect to home if already authorized
    if (Auth.isUserAuthenticated()) {
      return <Redirect to="/home" />;
    }
    return <ForgotPassword {...this.props} onSubmit={this.submit} />;
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('forgot-password')(state) || {}
});

export default connect(mapStateToProps)(ForgotPasswordContainer);
