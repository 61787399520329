import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './DbiaConfirmModal.css';

const ConfirmModal = ({ date, show, handleClose, onSubmit }) => (
  <Modal className="Modal-confirm" show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title className="ModalTitle">Order report</Modal.Title>
      <i
        className="icon-ic_close pull-right Close-modal-icon"
        onClick={handleClose}
      />
    </Modal.Header>
    <Modal.Body>
      <div className="Info-text">
        <p>
          You ordered this report on <b>{moment(date).format('DD/MM/YYYY')}</b>.
          Do you want to order again?
        </p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="pull-right">
        <Link
          type="button"
          className="Btn-default-small Btn-default-neutral DbiaConfirmModal-btn-cancel"
          onClick={handleClose}
          to={'/orders'}
        >
          View my orders
        </Link>
        <button
          type="button"
          className="Btn-default-small Btn-default-success"
          onClick={() => {
            handleClose();
            onSubmit();
          }}
        >
          Order report
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default ConfirmModal;
