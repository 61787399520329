import React, { Component } from 'react';
import { Sidebar } from 'react-common/components';

import AuthorizedHeader from '../_components/Header/AuthorizedHeader';

import logo from '../_images/mycial-logo.svg';
import logoColor from '../_images/mycial-logo-color.svg';

import './VerifyEmail.css';

class VerifyEmail extends Component {
  render() {
    const {
      onResendEmail,
      apiState: { success, error }
    } = this.props;

    return (
      <div className="VerifyEmail">
        <Sidebar
          logo={logo}
          logoAlt="MyCIAL"
          className="col-md-3 hidden-sm hidden-xs"
          headerText="Verify email"
          descriptionText="Please check your email and click on the link to verify your account."
          showContact={true}
        />
        <div className="col-md-9">
          <div className="row">
            <AuthorizedHeader
              sidebarOpened={true}
              className="col-md-9"
              showSidebarToggle={false}
            />
            <div className="col-md-6 col-md-offset-3 col-lg-5 col-lg-offset-3 VerifyEmail-content">
              <div>
                <img
                  src={logoColor}
                  alt="MyCIAL"
                  className="logo2 visible-sm visible-xs"
                />
              </div>

              <label>Didn't get the email?</label>
              <button
                className="Btn-default Btn-default-primary btn-resend form-control"
                onClick={onResendEmail}
              >
                Resend email
              </button>

              {error && (
                <div>
                  <div className="alert fade in alert-danger" role="alert">
                    <p>{error}</p>
                  </div>
                </div>
              )}

              {success && (
                <div>
                  <div className="alert alert-success" role="alert">
                    <p>
                      A link to verify your account has been sent to your email
                      address. Please check your inbox.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyEmail;
