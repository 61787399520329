import uniq from 'lodash/uniq';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Creatable } from 'react-select';

const components = {
  DropdownIndicator: null
};

const createOption = label => ({
  label,
  value: label
});

class EmailModal extends Component {
  state = {
    inputValue: '',
    emails: []
  };

  clearEmails = () => this.setState({ emails: [], inputValue: '' });

  handleChange = emails => {
    this.setState({ emails });
  };

  handleInputChange = inputValue => {
    this.setState({ inputValue });
  };

  handleKeyDown = event => {
    const { inputValue, emails } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          inputValue: '',
          emails: [...emails, createOption(inputValue)]
        });
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  handleBlur = () => {
    const { inputValue, emails } = this.state;
    if (!inputValue) return;

    this.setState({
      inputValue: '',
      emails: [...emails, createOption(inputValue)]
    });
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { emails, inputValue } = this.state;
    const uniqEmails = uniq([...emails.map(e => e.value), inputValue]).filter(
      Boolean
    );

    this.clearEmails();
    onSubmit(uniqEmails);
  };

  onHide = () => {
    const { onHideModal } = this.props;
    this.clearEmails();
    onHideModal();
  };

  render() {
    const { showModal, isPreliminary } = this.props;
    const { inputValue, emails } = this.state;

    return (
      <Modal
        className="emails-modal"
        show={showModal}
        onHide={this.onHide}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send via Email</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isPreliminary && (
            <div className="alert alert-info Alert-with-icon">
              Preliminary reports will be sent in an email once the
              investigation is completed.
            </div>
          )}
          <Creatable
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            placeholder="Type an email and press enter..."
            value={emails}
            className="CreatableEmail"
          />
        </Modal.Body>

        <Modal.Footer>
          <button
            className="Btn-default-neutral Btn-default-small"
            onClick={this.onHide}
          >
            Close
          </button>
          <button
            className="Btn-default-small Btn-default-success"
            onClick={this.onSubmit}
          >
            Send
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EmailModal;
