import every from 'lodash/every';

export const hasCompletedAllOnboarding = ({ workflows }) => {
  return every(
    workflows.active_onboardings,
    w => w.hasCompleted && w.hasCompleted()
  );
};

export const hasActiveOnboardings = ({ workflows }) => {
  return !!workflows.active_onboardings.length;
};

export const hasUncompletedOnboardings = state => {
  return hasActiveOnboardings(state) && !hasCompletedAllOnboarding(state);
};
