import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { viewGrants } from '../_constants/grants';
import { walkthroughSteps } from '../_constants/walkthrough';
import { updateHeaderBreadcrumbs, updateSearch } from '../_actions';
import SearchComponent from '../_components/Search/Search';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import { withPermissions } from '../_components/Grant/Grant';
import Walkthrough from '../_components/Walkthrough/Walkthrough';
import PageNotFound from '../PageNotFound';
import './Search.css';

const walkthroughCountryStep = [
  {
    target: '#input-search-select-country',
    stepData: walkthroughSteps.companySearch.selectCountry
  }
];
const walkthroughSearchStep = [
  {
    target: '#input-search-query',
    stepData: walkthroughSteps.companySearch.enterSearchText
  }
];

class Search extends Component {
  componentDidMount() {
    const {
      dispatchUpdateHeaderBreadcrumbs,
      dispatchUpdateSearch,
      location
    } = this.props;
    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Company Search'
      }
    ]);

    dispatchUpdateSearch(queryString.parse(location.search));
  }

  componentDidUpdate() {
    const { name, country, state } = this.props;

    const query = queryString.stringify({ name, country, state });
    window.history.pushState(null, '', '/search?' + query);
  }

  render() {
    return (
      <AppWrapper>
        <div className="Search">
          {/* Show steps as 2 independent walkthroughs */}
          <Walkthrough availableSteps={walkthroughCountryStep} />
          <Walkthrough availableSteps={walkthroughSearchStep} />

          <p>Search across companies and access their company information</p>
          <div className="row">
            <div className="col-xs-12">
              <SearchComponent />
            </div>
          </div>
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  return state.search;
}

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchUpdateSearch: updateSearch
};

Search = connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);

export default withPermissions(viewGrants.SEARCH, PageNotFound)(Search);
