import searchConstants from '../_constants/search';
import SearchService from '../_services/Search';
import { makeRequest } from './api';
import { Toast } from 'react-common/services';

export function resetSearch(fieldName) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_RESET,
      fieldName
    });
  };
}
export function resetSearchResults(params) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_RESET_RESULTS,
      params
    });
  };
}

export function updateSearch(params) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_UPDATE,
      params
    });
  };
}

export function updateSearchName(name) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_NAME_UPDATE,
      name
    });
  };
}

export function updateSearchCountry(country) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_COUNTRY_UPDATE,
      country
    });
  };
}

export function updateSearchState(state) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_STATE_UPDATE,
      state
    });
  };
}

export function updateSearchPage(page) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_PAGE_UPDATE,
      page
    });
  };
}

export function updateSearchSize(size) {
  return dispatch => {
    dispatch({
      type: searchConstants.SEARCH_SIZE_UPDATE,
      size
    });
  };
}

export function updateSearchResults(params) {
  const { name, country, state, size, page, cialOnly = true } = params;

  if (!name) {
    if (country || state) {
      return dispatch => {};
    }
    return resetSearch();
  }

  const stateRequired = ['USA', 'CAN'].includes(country);
  if (!state && stateRequired) {
    return resetSearchResults(params);
  }

  return makeRequest({
    type: searchConstants.SEARCH,
    apiCall: SearchService.searchCompanies,
    params: [{ name, country, state, size, page, cialOnly }],
    onFailure: (dispatch, error) => {
      Toast.showWarning(
        `${error.error}`.length > 500
          ? 'Oops! Something went wrong. Please try again.'
          : `${error.error}`
      );
      return dispatch(resetSearchResults(params));
    }
  });
}
