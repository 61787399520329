import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { supplierDomains } from '../../_constants/supplier';
import { MomentDate } from 'react-common/components';
import { capitalize } from '../../_utils/string';
import './Onboarding.css';

const supplierUrl = supplierDomains[process.env.REACT_APP_ENV];

class OnboardingComponent extends Component {
  goToOnboarding = workflow => {
    window.location.assign(
      `${supplierUrl}/onboarding/${workflow._id}/suppliers/${
        workflow.suppliers[0]._id
      }`
    );
  };

  render() {
    const { workflows } = this.props;

    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Company</th>
            <th>Status</th>
            <th>Started</th>
            <th>Completed</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {workflows.map(
            workflow =>
              workflow.hasSuppliers() && (
                <tr key={workflow.getId()}>
                  <td>{workflow.getOwnerCompanyName()}</td>
                  <td>
                    <span
                      className={`badge badge-${workflow.suppliers[0].status}`}
                    >
                      {capitalize(workflow.suppliers[0].status)}
                    </span>
                  </td>
                  <td>
                    <MomentDate
                      format="MMMM DD, YYYY"
                      date={workflow.suppliers[0].created_at}
                    />
                  </td>
                  <td>
                    {workflow.suppliers[0].status === 'completed' && (
                      <MomentDate
                        format="MMMM DD, YYYY"
                        date={workflow.suppliers[0].modified_at}
                      />
                    )}
                  </td>
                  <td>
                    {workflow.suppliers[0].status === 'in_progress' && (
                      <button
                        type="button"
                        className="Btn-default-small Btn-default-success"
                        onClick={() => this.goToOnboarding(workflow)}
                      >
                        continue
                      </button>
                    )}
                    {workflow.suppliers[0].status === 'pending' && (
                      <button
                        type="button"
                        className="Btn-default-small Btn-default-success"
                        onClick={() => this.goToOnboarding(workflow)}
                      >
                        start
                      </button>
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
    );
  }
}

OnboardingComponent.propTypes = {
  workflows: PropTypes.arrayOf(PropTypes.object)
};

OnboardingComponent = connect()(OnboardingComponent);
OnboardingComponent.defaultProps = { workflows: [] };

export default OnboardingComponent;
