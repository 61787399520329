const universeConstants = {
  FETCH_ALL: 'UNIVERSE_FETCH_ALL',
  FETCH_BY_ID: 'UNIVERSE_FETCH_BY_ID',
  FETCH_CHANGES_BY_UNIVERSE_ID: 'UNIVERSE_FETCH_CHANGES_BY_UNIVERSE_ID',
  FETCH_ALL_CHANGES: 'UNIVERSE_FETCH_ALL_CHANGES',
  ADD_DUNS_TO_UNIVERSE: 'UNIVERSE_ADD_DUNS_TO_UNIVERSE',
  REMOVE_DUNS_FROM_UNIVERSE: 'REMOVE_DUNS_FROM_UNIVERSE',
  CREATE_UNIVERSE: 'UNIVERSE_CREATE_UNIVERSE',
  DELETE_UNIVERSE: 'UNIVERSE_DELETE_UNIVERSE'
};

export default universeConstants;
