import React from 'react';
import PropTypes from 'prop-types';
import { Util } from 'react-common/services';
import BoxHeader from '../BoxHeader';

const UserComponent = ({ user, className = '', showBoxHeader }) => (
  <div className={className}>
    {showBoxHeader && <BoxHeader title={'My Profile'} />}
    <div className="panel-body">
      <div className="align-items-center">
        <div>
          {user.getImage() && (
            <img
              className="User-image"
              src={user.getImage()}
              alt={user.getName()}
            />
          )}
          {!user.getImage() && (
            <div className="Colored-circle">
              <var>{Util.getInitials(user.getName())}</var>
            </div>
          )}
        </div>
        <div className="User-details" notranslate="true">
          <h4>{user.getName()}</h4>
          {showBoxHeader && <span>{user.getTitle()}</span>}
          <p title={user.getEmail()}>{user.getEmail()}</p>
        </div>
      </div>
    </div>
  </div>
);

UserComponent.propTypes = {
  className: PropTypes.string,
  showBoxHeader: PropTypes.bool,
  user: PropTypes.object
};

export default UserComponent;
