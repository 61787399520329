import React, { Component } from 'react';
import { Field, reduxForm } from 'react-common/libs/reduxForm';
import { DunsNumberAbbr, UploadcareDialog } from 'react-common/components';
import { DunsNumberAbbrText } from 'react-common/components/DunsAbbr/DunsAbbr';
import { Util } from 'react-common/services';
import { Link } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

class CompanyForm extends Component {
  render() {
    const {
      handleSubmit,
      onUploadComplete,
      formValues = { info: {} },
      company,
      language
    } = this.props;

    if (isEmpty(company)) {
      return (
        <div className="panel Company">
          <h2 className="panel-heading Title-table">Company</h2>
          <div className="panel-content Content-table-last">
            <p>
              You don't have a company set up yet.{' '}
              <Link to="/get-started">Set it up</Link>.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="panel Company">
        <h2 className="panel-heading Title-table">Company</h2>
        <div className="panel-content Content-table-last">
          <form onSubmit={handleSubmit}>
            <label>Company Logo</label>

            <div
              className="Image-container"
              style={
                formValues.info.logo && {
                  backgroundImage: `url(${formValues.info.logo})`
                }
              }
              onClick={() => this.uploader.openDialog()}
            >
              {!formValues.info.logo && (
                <div className="Colored-circle">
                  <var>
                    {Util.getInitials(
                      formValues.udm && formValues.udm.legal_name
                    )}
                  </var>
                </div>
              )}
              <div className="Image-overlay">
                <div className="Overlay-text">
                  {(formValues.info.logo && 'Change') || 'Upload'}
                </div>
              </div>
              <UploadcareDialog
                value={formValues.info.logo}
                ref={uploader => (this.uploader = uploader)}
                onUploadComplete={onUploadComplete}
                language={language}
              />
            </div>

            <label htmlFor="udm.legal_name">Company Name</label>
            <Field
              type="text"
              name="udm.legal_name"
              className="form-control"
              component="input"
              placeholder="Company Name"
              disabled={true}
            />

            <label htmlFor="udm.duns">
              <DunsNumberAbbr />
            </label>
            <Field
              type="text"
              name="udm.duns"
              className="form-control"
              component="input"
              placeholder={`${DunsNumberAbbrText}`}
              disabled={true}
            />

            <label htmlFor="udm.address.main.address_line_1">Address</label>
            <Field
              type="text"
              name="udm.address.main.address_line_1"
              className="form-control"
              component="input"
              placeholder="Address"
              disabled={true}
            />

            <label htmlFor="udm.address.main.city">City</label>
            <Field
              type="text"
              name="udm.address.main.city"
              className="form-control"
              component="input"
              placeholder="City"
              disabled={true}
            />

            <label htmlFor="udm.address.main.country">Country</label>
            <Field
              type="text"
              name="udm.address.main.country"
              className="form-control"
              component="input"
              placeholder="Country"
              disabled={true}
            />
          </form>
        </div>
      </div>
    );
  }
}

const CompanyProfile = reduxForm({
  form: 'company'
})(CompanyForm);

export default CompanyProfile;
