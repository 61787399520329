import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Util } from 'react-common/services';
import isEmpty from 'lodash/isEmpty';
import BoxHeader from '../BoxHeader';
import { DunsAbbr } from 'react-common/components';

class CompanyComponent extends Component {
  render() {
    const { company, className = '', showBoxHeader } = this.props;

    if (isEmpty(company)) {
      return (
        <div className={className}>
          {showBoxHeader && <BoxHeader title={'My Company'} />}
          <div className="panel-body">
            <span>
              You don't have a company set up yet.{' '}
              <Link to="/get-started">Set it up</Link>.
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        {showBoxHeader && <BoxHeader title={'My Company'} />}
        <div className="panel-body">
          <div className="align-items-center">
            <div>
              {company.info.logo && (
                <img
                  className="User-image"
                  src={company.info.logo}
                  alt={company.legal_name}
                />
              )}
              {!company.info.logo && (
                <div className="Colored-circle">
                  <var>{Util.getInitials(company.legal_name)}</var>
                </div>
              )}
            </div>
            <div className="Company-details" notranslate="true">
              <h4>{company.legal_name}</h4>
              {/*{showBoxHeader && <p>{companyAddress}</p>}*/}
              {showBoxHeader && (
                <p>
                  <DunsAbbr />: {company.info.duns}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CompanyComponent.propTypes = {
  showBoxHeader: PropTypes.bool,
  company: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
};

export default CompanyComponent;
