import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import { Redirect } from 'react-router-dom';

import { resetPassword } from '../_actions';
import ChangePassword from './ChangePassword';

class ChangePasswordContainer extends Component {
  getToken = () => {
    return qs.parse(this.props.location.search).token;
  };

  submit = formData => {
    const { dispatch } = this.props;
    const token = this.getToken();
    const { password } = formData;

    return dispatch(resetPassword(token, password));
  };

  render() {
    if (!this.getToken()) {
      return <Redirect to="/login" />;
    }

    // redirect to home if already authorized
    if (Auth.isUserAuthenticated()) {
      return <Redirect to="/home" />;
    }

    return <ChangePassword {...this.props} onSubmit={this.submit} />;
  }
}

export default connect()(ChangePasswordContainer);
