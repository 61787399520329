import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ComponentWithLoader } from 'react-common/components';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import { updateHeaderBreadcrumbs } from '../_actions';
import { getDbiaAccount, getDbiaTransactions } from '../_actions/dbia';
import * as dbiaSelectors from '../_selectors/dbia';
import Usage from './Usage';
import { viewGrants } from '../_constants/grants';
import { withPermissions } from '../_components/Grant/Grant';
import PageNotFound from '../PageNotFound';
import { getCountriesList } from '../_services/Cometa';

class UsageContainer extends Component {
  state = {
    fromDate: moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  };

  componentDidMount() {
    const { dispatchUpdateHeaderBreadcrumbs } = this.props;
    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Usage'
      }
    ]);
    this.loadAccountData();
    this.loadTransactionData();

    getCountriesList()
      .then(countries => this.setState({ countries }))
      .catch(error => {
        console.error('Error during GET from /api/cometa/country:', error);
        this.setState({ countries: null });
      });
  }

  loadAccountData = () => {
    const { dispatchGetAccount } = this.props;
    return dispatchGetAccount();
  };

  loadTransactionData = () => {
    const { dispatchGetTransactions } = this.props;
    const { fromDate, toDate } = this.state;

    return dispatchGetTransactions(fromDate, toDate);
  };

  handleFromChange = e => {
    this.setState({ fromDate: e.target.value }, this.loadTransactionData);
  };

  handleToChange = e => {
    this.setState({ toDate: e.target.value }, this.loadTransactionData);
  };

  findCountry = countryCode => {
    const { countries } = this.state;
    return countries
      ? this.state.countries.find(country => country.code === countryCode)
          .title_en
      : countryCode;
  };

  render() {
    const {
      isFetchingDbiaAccount,
      isFetchingDbiaTransactions,
      dbiaAccount,
      dbiaTransactions
    } = this.props;
    const { fromDate, toDate } = this.state;

    return (
      <AppWrapper>
        <ComponentWithLoader
          showLoader={isFetchingDbiaAccount && isFetchingDbiaTransactions}
        >
          <Usage
            fromDate={fromDate}
            toDate={toDate}
            isFetchingDbiaTransactions={isFetchingDbiaTransactions}
            dbiaAccount={dbiaAccount}
            dbiaTransactions={dbiaTransactions}
            onFromChange={this.handleFromChange}
            onToChange={this.handleToChange}
            findCountry={this.findCountry}
          />
        </ComponentWithLoader>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { api } = state;
  const apiStateTransaction = api[getDbiaTransactions.name] || {};
  const apiStateAccount = api[getDbiaAccount.name] || {};
  return {
    dbiaAccount: dbiaSelectors.getDbiaAccount(state),
    dbiaTransactions: dbiaSelectors.getDbiaTransactions(state),
    isFetchingDbiaTransactions: apiStateTransaction.isFetching,
    isFetchingDbiaAccount: apiStateAccount.isFetching
  };
}

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchGetAccount: getDbiaAccount,
  dispatchGetTransactions: getDbiaTransactions
};

UsageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsageContainer);

export default withPermissions(viewGrants.DBIA_ORDERS, PageNotFound)(
  UsageContainer
);
