import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DunsAbbr, DunsNumberAbbr } from 'react-common/components';
import { markDunsRequestAsSeen } from '../../_actions/dunsRequests';
import {
  dunsRequestIssueDuration,
  dunsRequestStatuses
} from '../../_constants/dunsRequest';
import { dunsRequestType } from 'react-common/constants';
import { getDunsRequest } from '../../_selectors/dunsRequests';
import dunsDeliveryImg from '../../_images/duns-delivery.svg';
import trackImg from '../../_images/track.svg';
import shareImg from '../../_images/share.svg';
import buyImg from '../../_images/buy.svg';
import hourglassImg from '../../_images/hourglass.svg';
import './Created.css';

class DunsNumberCreated extends Component {
  markAsSeen = () => {
    const {
      dunsRequest,
      dispatchMarkDunsRequestAsSeen,
      dispatchPush
    } = this.props;

    if (dunsRequest) {
      dispatchMarkDunsRequestAsSeen(dunsRequest._id).then(() =>
        dispatchPush('/home')
      );
    }
  };

  renderPendingStep = () => {
    const { dunsRequest } = this.props;
    const dunsNumberIssueDuration =
      dunsRequest.duns_type === dunsRequestType.EXPRESS
        ? dunsRequestIssueDuration.EXPRESS
        : dunsRequestIssueDuration.FREE;

    return (
      <div className="GetDunsNumber WizardPage">
        <div className="delivery-step">
          <div className="row">
            <div className="col-md-12">
              <img alt="Thank you" src={hourglassImg} />
              <h1>Thank you!</h1>
              <p>
                You’ll get your <DunsNumberAbbr /> in {dunsNumberIssueDuration}{' '}
                days
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { dunsRequest } = this.props;

    if (!dunsRequest) {
      return;
    }

    if (dunsRequest && dunsRequest.status === dunsRequestStatuses.PENDING) {
      return this.renderPendingStep();
    }

    return (
      <div className="GetDunsNumber WizardPage">
        <div className="delivery-step">
          <div className="row">
            <div
              className="col-xs-12 delivery-step-certificate mtop-30 mbot-20"
              style={{
                backgroundImage: `url(${dunsDeliveryImg})`
              }}
            >
              <div className="delivery-step-heading">
                <span className="no-wrap">
                  <DunsAbbr />
                </span>
              </div>
              <div className="delivery-step-subheading">
                {dunsRequest && dunsRequest.duns_number}
              </div>
            </div>
            <div className="col-xs-12">
              <h4>
                Your{' '}
                <span className="no-wrap">
                  <DunsNumberAbbr />
                </span>{' '}
                has been created.
              </h4>
              <h4>You now have full access to MyCIAL!</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <img
                src={trackImg}
                alt="track-data"
                className="mtop-30 mbot-20"
              />
              <p className="duns-delivery-text text-center">
                Track your company's data on the dashboard.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <img src={buyImg} alt="buy-reports" className="mtop-30 mbot-20" />
              <p className="duns-delivery-text text-center">
                Request reports and get to know your clients better.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <img
                src={shareImg}
                alt="share-reports"
                className="mtop-30 mbot-20"
              />
              <p className="duns-delivery-text text-center">
                Download and share all your reports.
              </p>
            </div>
            <div className="col-xs-12">
              <p className="text-center">
                <button
                  onClick={this.markAsSeen}
                  className="Btn-default Btn-default-primary connect-button mtop-30 mbot-20"
                >
                  Access MyCIAL
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dunsRequest: getDunsRequest(state)
});

const mapDispatchToProps = {
  dispatchMarkDunsRequestAsSeen: markDunsRequestAsSeen,
  dispatchPush: push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DunsNumberCreated);
