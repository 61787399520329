import claimConstants from '../_constants/claim';
import { Util } from 'react-common/services';

export function claims(
  state = {
    items: {}
  },
  action
) {
  switch (action.type) {
    case claimConstants.GET_CLAIMS_FOR_ACCOUNT:
      const items = Util.convertArrayToMap(action.data);

      if (!items) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          ...items
        }
      };
    default:
      return state;
  }
}
