import React, { Component } from 'react';
import { connect } from 'react-redux';

import CreateUniverse from './CreateUniverse';
import {
  fetchAllUniverses,
  addDunsToUniverse,
  createUniverse
} from '../../_actions';

class CreateUniverseContainer extends Component {
  componentDidMount() {
    const { dispatchFetchAllUniverses } = this.props;
    return dispatchFetchAllUniverses();
  }

  render() {
    const {
      dispatchAddDunsToUniverse,
      dispatchCreateUniverse,
      selectedUniverseId
    } = this.props;

    return (
      <CreateUniverse
        addDunsToUniverse={dispatchAddDunsToUniverse}
        createUniverse={dispatchCreateUniverse}
        selectedUniverseId={selectedUniverseId}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchAddDunsToUniverse: addDunsToUniverse,
  dispatchFetchAllUniverses: fetchAllUniverses,
  dispatchCreateUniverse: createUniverse
};

export default connect(
  null,
  mapDispatchToProps
)(CreateUniverseContainer);
