import React, { Component } from 'react';
import Multiselect from '../Multiselect/Multiselect';

import PropTypes from 'prop-types';

class UniverseListDropdown extends Component {
  render() {
    const {
      universes,
      placeholder,
      classNamePrefix,
      onBlur,
      onFocus,
      onChange,
      value
    } = this.props;
    const universeOptions =
      universes &&
      universes.items &&
      Object.entries(universes.items).map(u => ({
        label: u[1].name,
        value: u[1]['_id']
      }));

    return (
      <Multiselect
        placeholder={placeholder}
        options={universeOptions}
        classNamePrefix={classNamePrefix}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        multiSelect={true}
      />
    );
  }
}

UniverseListDropdown.propTypes = {
  universes: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  classNamePrefix: PropTypes.string
};

UniverseListDropdown.defaultProps = {
  placeholder: 'Choose universe',
  classNamePrefix: 'SearchableDropdown'
};

export default UniverseListDropdown;
