import updateRequestConstants from '../_constants/updateRequest';
import UpdateRequest from '../_services/UpdateRequest';
import { makeRequest } from './api';

export function getAdminUpdateRequestById(updateRequestId, accountId) {
  return makeRequest({
    type: updateRequestConstants.GET_ADMIN_UPDATE_REQUEST,
    apiCall: UpdateRequest.getAdminUpdateRequestById,
    params: [updateRequestId, accountId]
  });
}

export function updateAdminUpdateRequestById(
  updateRequestId,
  changedData,
  accountId,
  onSuccess
) {
  return makeRequest({
    type: updateRequestConstants.UPDATE_ADMIN_UPDATE_REQUEST,
    apiCall: UpdateRequest.updateAdminUpdateRequestById,
    params: [updateRequestId, changedData, accountId],
    onSuccess
  });
}

export function getUpdateRequestForAccount() {
  return makeRequest({
    type: updateRequestConstants.GET_UPDATE_REQUESTS_FOR_ACCOUNT,
    apiCall: UpdateRequest.getUpdateRequestForAccount,
    params: []
  });
}

export function updateUpdateRequest(updateRequestId, changedData) {
  return makeRequest({
    type: updateRequestConstants.UPDATE_UPDATE_REQUEST,
    apiCall: UpdateRequest.updateUpdateRequestById,
    params: [updateRequestId, changedData]
  });
}

export function fieldUpdateUpdateRequest(updateRequestId, changedData) {
  return makeRequest({
    type: updateRequestConstants.UPDATE_UPDATE_REQUEST,
    apiCall: UpdateRequest.fieldUpdateUpdateRequestById,
    params: [updateRequestId, changedData]
  });
}

export function submitUpdateRequest(updateRequestId, changedData) {
  return makeRequest({
    type: updateRequestConstants.SUBMIT_UPDATE_REQUEST,
    apiCall: UpdateRequest.submitUpdateRequestById,
    params: [updateRequestId, changedData]
  });
}

export function uploadUpdateRequestFile(
  updateRequestId,
  fileName,
  fileData,
  onUploadEnd
) {
  return makeRequest({
    type: updateRequestConstants.UPLOAD_UPDATE_REQUEST_FILE,
    apiCall: UpdateRequest.uploadFile,
    params: [updateRequestId, fileData, fileName],
    onRequestEnd: () => onUploadEnd(fileName),
    onFailure: () => onUploadEnd(fileName)
  });
}

export function deleteUpdateRequestFile(updateRequestId, fileName) {
  return makeRequest({
    type: updateRequestConstants.DELETE_UPDATE_REQUEST_FILE,
    apiCall: UpdateRequest.deleteFile,
    params: [updateRequestId, fileName]
  });
}
