import { Fetch } from 'react-common/services';
import { dunsRequestType } from 'react-common/constants';

class DunsRequest {
  getInitialDunsRequest() {
    return {
      duns_type: dunsRequestType.FREE,
      company: {
        user_is_legal_representative: 'yes',
        addresses: [{ is_main_address: true }]
      }
    };
  }

  getRequestsForAccount() {
    return Fetch.getJSON('/api/duns-requests');
  }

  create = (dunsRequest = {}) => {
    const initialDunsRequest = this.getInitialDunsRequest();

    return Fetch.postJSON('/api/duns-requests', {
      ...initialDunsRequest,
      ...dunsRequest
    });
  };

  patch(dunsRequestId, dunsRequest) {
    return Fetch.patchJSON(`/api/duns-requests/${dunsRequestId}`, dunsRequest);
  }

  uploadFile(dunsRequestId, file) {
    return Fetch.patch(`/api/duns-requests/${dunsRequestId}/files`, file);
  }

  deleteFile(dunsRequestId, fileId) {
    return Fetch.deleteJSON(
      `/api/duns-requests/${dunsRequestId}/files/${fileId}`
    );
  }

  submit(dunsRequestId, dunsRequest) {
    return Fetch.postJSON(
      `/api/duns-requests/${dunsRequestId}/submit`,
      dunsRequest
    );
  }

  markAsSeen(dunsRequestId) {
    return Fetch.patchJSON(`/api/duns-requests/${dunsRequestId}/seen`, {});
  }
}

export default new DunsRequest();
