import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrivateRoute } from 'react-common/components';
import { Switch } from 'react-router';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import { viewGrants } from '../_constants/grants';

import UniverseListContainer from './UniverseList/UniverseListContainer';
import SingleUniverseContainer from './SingleUniverse/SingleUniverseContainer';
import CreateUniverseContainer from './CreateUniverse/CreateUniverseContainer';
import { withPermissions } from '../_components/Grant/Grant';
import PageNotFound from '../PageNotFound';

class UniverseContainer extends Component {
  render() {
    return (
      <AppWrapper>
        <Switch>
          <PrivateRoute
            path="/universes"
            exact
            component={UniverseListContainer}
          />
          <PrivateRoute
            path="/universes/new"
            exact
            component={CreateUniverseContainer}
          />
          <PrivateRoute
            path="/universes/:universeId"
            exact
            component={SingleUniverseContainer}
          />
        </Switch>
      </AppWrapper>
    );
  }
}

export default withPermissions(viewGrants.MONITORING, PageNotFound)(
  connect()(UniverseContainer)
);
