import React from 'react';
import SearchComponent from '../../_components/Search/Search';
import ClaimResults from '../../_components/Search/Templates/ClaimResults';
import ClaimNoResults from '../../_components/Search/Templates/ClaimNoResults';

const Find = ({ claimedCompany, onClaimRequest }) => (
  <div className="ClaimInfo">
    <h4 className="text-center">
      Excellent! Now fill out a few easy steps to claim and link your company on
      MyCial.
    </h4>

    <div className="ClaimForm">
      {!claimedCompany && (
        <SearchComponent
          itemsPerPage={10}
          ResultsTemplate={ClaimResults}
          NoResultsTemplate={ClaimNoResults}
          onSelect={onClaimRequest}
          cialOnly={true}
        />
      )}
    </div>
  </div>
);

export default Find;
