export const getIsSavingDunsRequest = state =>
  (state.api.patchDunsRequest && state.api.patchDunsRequest.isFetching) ||
  (state.api.submitDunsRequest && state.api.submitDunsRequest.isFetching) ||
  false;

export const getIsFetchingDbiaAccount = state =>
  state.api.getDbiaAccount && state.api.getDbiaAccount.isFetching;

export const getIsFetchingDbiaTransactions = state =>
  state.api.getDbiaTransactions && state.api.getDbiaTransactions.isFetching;
