import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { login, setInitialUrl } from '../_actions';
import Login from './Login';

class LoginContainer extends Component {
  submit = user => {
    const { dispatchLogin } = this.props;
    return dispatchLogin(user);
  };

  componentDidMount() {
    const { previousUrl, dispatchSetInitialUrl } = this.props;
    dispatchSetInitialUrl(previousUrl && previousUrl.pathname);
  }

  render() {
    // redirect to home if already authorized
    if (Auth.isUserAuthenticated()) {
      return <Redirect to="/home" />;
    }
    return <Login {...this.props} onSubmit={this.submit} />;
  }
}

function mapStateToProps(state, props) {
  let { location: { state: { from: previousUrl } = {} } = {} } = props;

  return {
    previousUrl
  };
}

const mapDispatchToProps = {
  dispatchLogin: login,
  dispatchSetInitialUrl: setInitialUrl
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
