import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { updateHeaderBreadcrumbs } from '../../_actions/ui';
import {
  patchDunsRequest,
  uploadDunsRequestFile,
  deleteDunsRequestFile,
  submitDunsRequest,
  forceAddressErrorCleanup
} from '../../_actions/dunsRequests';
import {
  getHasErrors,
  getSyncErrors,
  getIsDirty,
  getSelectedCountryRegionName,
  getSelectedReasonOfRequest,
  getSelectedLegalStructure,
  getMetadata,
  getAcceptLegal,
  getIsUserLegalRepresentative,
  getSelectedCialCountry
} from '../../_selectors/dunsRequestForm';
import { getUser } from '../../_selectors/user';
import { getIsSavingDunsRequest } from '../../_selectors/api';
import { getDunsFileUploadsState } from '../../_selectors/ui';
import { getDunsRequestFiles } from '../../_selectors/dunsRequests';
import { getDunsRequest } from '../../_selectors/dunsRequests';
import { wizardFormSections } from 'react-common/constants';
import { stringToPhoneInfo, phoneInfoToString } from '../../_utils/string';
import { withEnums } from 'react-common/decorators';
import { goToTop } from '../../_utils/ui';
import { DunsForm } from 'react-common/components';

class DunsNumberFormContainer extends Component {
  state = {
    formStep: wizardFormSections.COMPANY
  };

  handleClickBack = () => {
    const { dispatchPush } = this.props;
    const { formStep } = this.state;

    if (formStep === wizardFormSections.COMPANY) {
      dispatchPush('/get-started');
    } else {
      this.setState({ formStep: wizardFormSections.COMPANY }, goToTop);
    }
  };

  getInitialFormData = () => {
    const { dunsRequest } = this.props;
    const initialValues = { ...dunsRequest };
    initialValues.company.addresses =
      initialValues.company.addresses &&
      initialValues.company.addresses.filter(Boolean).length
        ? initialValues.company.addresses
        : [{}];
    const phoneDetails = stringToPhoneInfo(initialValues.account.phone);

    initialValues.account.phone = phoneDetails.phone;
    initialValues.account.phoneExt = phoneDetails.ext;
    initialValues.company.addresses[0].hasSingleAddress =
      initialValues.company.addresses.length === 1 ? 'yes' : 'no';

    return initialValues;
  };

  prepareFormValues = values => {
    const { dunsRequestType } = this.props;
    const valuesCopy = cloneDeep(values);

    const data = {
      ...valuesCopy,
      account: {
        ...valuesCopy.account,
        phone: phoneInfoToString({
          phone: valuesCopy.account.phone,
          ext: valuesCopy.account.phoneExt
        })
      },
      duns_type: dunsRequestType
    };

    delete data.acceptLegal;
    delete data.account.phoneExt;
    delete data.company.addresses[0].hasSingleAddress;

    return data;
  };

  handleClickContinue = values => {
    const { formStep } = this.state;
    const { dispatchSubmitDunsRequest, dunsRequest } = this.props;

    if (formStep === wizardFormSections.COMPANY) {
      this.setState({ formStep: wizardFormSections.OPERATIONS }, goToTop);
      return;
    }

    const data = this.prepareFormValues(values);
    this.handleValueChange.cancel();
    return dispatchSubmitDunsRequest(dunsRequest._id, data);
  };

  handleValueChange = debounce(values => {
    const {
      dunsRequest,
      dispatchPatchDunsRequest,
      isSavingDunsRequest,
      syncErrors,
      dispatchForceNewSyncErrors
    } = this.props;
    // dissallow parallel save requests
    if (isSavingDunsRequest) {
      return;
    }

    const hasErrorInBillingAddress =
      syncErrors.company &&
      syncErrors.company.addresses &&
      syncErrors.company.addresses[1];
    const onlyMainAddressValuesPresent = values.company.addresses.length === 1;

    if (hasErrorInBillingAddress && onlyMainAddressValuesPresent) {
      dispatchForceNewSyncErrors(syncErrors);
    }

    const data = this.prepareFormValues(values);
    dispatchPatchDunsRequest(dunsRequest._id, data);
  }, 1000);

  handleFileUpload = (fileName, file) => {
    const { dispatchUploadDunsRequestFile, dunsRequest } = this.props;
    const fileData = new FormData();
    fileData.append(fileName, file);

    dispatchUploadDunsRequestFile(dunsRequest._id, fileName, fileData);
  };

  handleFileDelete = (fileType, fileId) => {
    const { fileMap, dispatchDeleteDunsRequestFile, dunsRequest } = this.props;

    if (fileMap[fileType]) {
      dispatchDeleteDunsRequestFile(dunsRequest._id, fileId);
    }
  };

  render() {
    const {
      user,
      selectedCountryRegionName,
      selectedReasonOfRequest,
      selectedCialCountry,
      selectedLegalStructure,
      selectedAcceptLegal,
      metadata,
      fileMap,
      fileState,
      formHasErrors,
      isSavingDunsRequest,
      isUserLegalRepresentative
    } = this.props;
    const { formStep } = this.state;
    const initialValues = this.getInitialFormData();

    return (
      <div className="GetDunsNumber WizardPage">
        <DunsForm
          initialValues={initialValues}
          user={user}
          onSubmit={this.handleClickContinue}
          onValuesChange={this.handleValueChange}
          onFileUpload={this.handleFileUpload}
          onFileDelete={this.handleFileDelete}
          formStep={formStep}
          fileMap={fileMap}
          fileState={fileState}
          metadata={metadata}
          selectedCountryRegionName={selectedCountryRegionName}
          selectedReasonOfRequest={selectedReasonOfRequest}
          selectedCialCountry={selectedCialCountry}
          selectedLegalStructure={selectedLegalStructure}
          selectedAcceptLegal={selectedAcceptLegal}
          formHasErrors={formHasErrors}
          handleClickBack={this.handleClickBack}
          saveInProgress={isSavingDunsRequest}
          isUserLegalRepresentative={isUserLegalRepresentative}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { countries, reasonsOfRequest, countryRegions } = props;

  return {
    formHasErrors: getHasErrors(state),
    syncErrors: getSyncErrors(state),
    isDirty: getIsDirty(state),
    fileMap: getDunsRequestFiles(state),
    fileState: getDunsFileUploadsState(state),
    selectedCountryRegionName: getSelectedCountryRegionName(state, countries),
    selectedReasonOfRequest: getSelectedReasonOfRequest(
      state,
      countries,
      reasonsOfRequest
    ),
    selectedCialCountry: getSelectedCialCountry(state, countries),
    selectedLegalStructure: getSelectedLegalStructure(state),
    metadata: getMetadata(state, countries, countryRegions),
    dunsRequest: getDunsRequest(state),
    selectedAcceptLegal: getAcceptLegal(state),
    isSavingDunsRequest: getIsSavingDunsRequest(state),
    isUserLegalRepresentative: getIsUserLegalRepresentative(state),
    user: getUser(state)
  };
};

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchPush: push,
  dispatchForceNewSyncErrors: forceAddressErrorCleanup,
  dispatchPatchDunsRequest: patchDunsRequest,
  dispatchSubmitDunsRequest: submitDunsRequest,
  dispatchUploadDunsRequestFile: uploadDunsRequestFile,
  dispatchDeleteDunsRequestFile: deleteDunsRequestFile
};

DunsNumberFormContainer.propTypes = {
  user: PropTypes.object.isRequired,
  selectedCountryRegionName: PropTypes.string.isRequired,
  selectedReasonOfRequest: PropTypes.object,
  selectedCialCountry: PropTypes.bool.isRequired,
  selectedLegalStructure: PropTypes.string,
  selectedAcceptLegal: PropTypes.string,
  metadata: PropTypes.object.isRequired,
  fileMap: PropTypes.object.isRequired,
  formHasErrors: PropTypes.bool.isRequired,
  isSavingDunsRequest: PropTypes.bool.isRequired,
  isUserLegalRepresentativ: PropTypes.bool
};

export default withEnums('countries', 'reasonsOfRequest', 'countryRegions')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DunsNumberFormContainer)
);
