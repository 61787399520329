import searchConstants from '../_constants/search';
import { UDM } from '../_models/udm';

const defaultState = {
  name: '',
  country: '',
  state: '',
  results: [],
  page: 1,
  size: 10,
  total: 0
};

export function search(state = defaultState, action) {
  switch (action.type) {
    case searchConstants.SEARCH_RESET:
      if (!action.fieldName) {
        return { ...defaultState, ...{ size: state.size } };
      }

      return {
        ...state,
        [action.fieldName]: ''
      };
    case searchConstants.SEARCH_RESET_RESULTS:
      return {
        ...defaultState,
        name: action.params.name,
        country: action.params.country,
        state: action.params.state
      };
    case searchConstants.SEARCH_UPDATE:
      return {
        ...state,
        ...action.params
      };
    case searchConstants.SEARCH_NAME_UPDATE:
      return {
        ...state,
        name: action.name,
        page: defaultState.page
      };
    case searchConstants.SEARCH_COUNTRY_UPDATE:
      return {
        ...state,
        country: action.country,
        page: defaultState.page
      };
    case searchConstants.SEARCH_STATE_UPDATE:
      return {
        ...state,
        state: action.state,
        page: defaultState.page
      };
    case searchConstants.SEARCH_PAGE_UPDATE:
      return {
        ...state,
        page: action.page
      };
    case searchConstants.SEARCH_SIZE_UPDATE:
      return {
        ...state,
        size: action.size
      };
    case searchConstants.SEARCH:
      return {
        ...state,
        total: action.data.data.totalItems,
        results: action.data.data.items.map(h => new UDM(h))
      };
    default:
      return state;
  }
}
