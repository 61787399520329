import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './_reducers';
import loginConstants from './_constants/login';

let middleware = [thunkMiddleware];

if (process.env.REACT_APP_ENV !== 'production') {
  middleware = [...middleware, createLogger({ collapsed: true })];
}

const composeEnhancers =
  process.env.REACT_APP_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore(history) {
  const appReducer = createRootReducer(history);

  const rootReducer = (state, action) => {
    if (action.type === loginConstants.LOGOUT) {
      state = {
        enums: state.enums
      };
    }

    return appReducer(state, action);
  };

  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware, routerMiddleware(history)))
  );
}
