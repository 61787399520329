import dbiaConstants from '../_constants/dbia';
import { Util } from 'react-common/services';

const defaultState = {
  orders: [],
  companies: {},
  order: null,
  report: null,
  account: null,
  transactions: []
};

export function dbia(state = defaultState, action) {
  switch (action.type) {
    case dbiaConstants.PRODUCTS:
      return {
        ...state,
        companies: {
          ...state.companies,
          ...Util.convertArrayToMap([action.data] || [], 'Duns')
        }
      };
    case dbiaConstants.ORDERS:
      return {
        ...state,
        orders: action.data
      };
    case dbiaConstants.SHOW_REPORT:
      return {
        ...state,
        order: action.order
      };
    case dbiaConstants.HIDE_REPORT:
      return {
        ...state,
        order: null,
        report: null
      };
    case dbiaConstants.REPORT:
      return {
        ...state,
        report: action.data.report
      };
    case dbiaConstants.ACCOUNT:
      return {
        ...state,
        account: action.data
      };
    case dbiaConstants.TRANSACTIONS:
      return {
        ...state,
        transactions: action.data
      };
    default:
      return state;
  }
}
