import React, { Component } from 'react';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import DbiaAccess from './DbiaAccess';
import DbiaCredentials from './DbiaCredentials';
import './Dbia.css';

class Dbia extends Component {
  render() {
    const { user, location } = this.props;

    return (
      <AppWrapper>
        {location.pathname === '/dbia' && (
          <DbiaAccess opalCredentialsExist={user.hasOpalCredentials()} />
        )}
        {location.pathname === '/dbia/credentials' && (
          <DbiaCredentials opalCredentials={user.getOpalCredentials()} />
        )}
      </AppWrapper>
    );
  }
}

export default Dbia;
