export const viewGrants = {
  ONBOARDINGS: 'ONBOARDINGS',
  HOME: 'HOME',
  GET_STARTED: 'GET_STARTED',
  DUNS: 'DUNS',
  CLAIM: 'CLAIM',
  DBIA: 'DBIA',
  SEARCH: 'SEARCH',
  MONITORING: 'MONITORING',
  DBIA_ORDERS: 'DBIA_ORDERS',
  ANALYST: 'ANALYST',
  UPDATE: 'UPDATE'
};

export const grantTypes = {
  ANY: 'ANY',
  EVERY: 'EVERY'
};
