import { push } from 'connected-react-router';
import { Toast } from 'react-common/services';
import universeConstants from '../_constants/universe';
import UniverseService from '../_services/Universe';
import { makeRequest } from './api';

export function fetchAllUniverses() {
  return makeRequest({
    type: universeConstants.FETCH_ALL,
    apiCall: UniverseService.fetchAll,
    params: []
  });
}

export function fetchUniverseById(universeId) {
  return makeRequest({
    type: universeConstants.FETCH_BY_ID,
    apiCall: UniverseService.fetchById,
    params: [universeId]
  });
}

export function fetchChangesForUniverse(universeId) {
  return makeRequest({
    type: universeConstants.FETCH_CHANGES_BY_UNIVERSE_ID,
    apiCall: UniverseService.fetchChangesByUniverseId,
    params: [universeId]
  });
}

export function fetchAllChanges() {
  return makeRequest({
    type: universeConstants.FETCH_ALL_CHANGES,
    apiCall: UniverseService.fetchAllChanges,
    params: []
  });
}

export function addDunsToUniverse(universeId, duns, isNew) {
  return makeRequest({
    type: universeConstants.ADD_DUNS_TO_UNIVERSE,
    apiCall: UniverseService.addDunsToUniverse.bind(UniverseService),
    params: [universeId, duns, isNew],
    onSuccess: () => Toast.showSuccess('Saved to list.'),
    onFailure: () => Toast.showWarning(`Error in adding company to list.`)
  });
}

export function removeDunsFromUniverse(universeId, duns, successMsg) {
  return makeRequest({
    type: universeConstants.REMOVE_DUNS_FROM_UNIVERSE,
    apiCall: UniverseService.removeDunsFromUniverse,
    params: [universeId, duns],
    onSuccess: () => Toast.showSuccess(successMsg),
    onFailure: () => Toast.showWarning(`Error in removing company from list.`)
  });
}

export function createUniverse(name, fields, duns) {
  return makeRequest({
    type: universeConstants.CREATE_UNIVERSE,
    apiCall: UniverseService.createUniverse,
    params: [name, fields, duns]
  });
}

export function deleteUniverse(universeId) {
  return makeRequest({
    type: universeConstants.DELETE_UNIVERSE,
    apiCall: UniverseService.deleteUniverse,
    params: [universeId],
    onSuccess: successfulDeleting(),
    onFailure: () => Toast.showWarning(`Error in deleting list.`)
  });
}

function successfulDeleting() {
  Toast.showSuccess('Successfully deleted.');
  return dispatch => dispatch(push('/universes'));
}
