import { Toast } from 'react-common/services';
import CompanyService from '../_services/Company';
import companyConstants from '../_constants/company';
import { makeRequest } from './api';

export function getCompanyByIdOrDuns(companyIdOrDuns, onEnd) {
  return makeRequest({
    type: companyConstants.COMPANY,
    apiCall: CompanyService.getCompanyByIdOrDuns,
    params: [companyIdOrDuns],
    onRequestEnd: () => onEnd && onEnd()
  });
}

export function changeCompanyLogo(companyId, companyLogo) {
  return makeRequest({
    type: companyConstants.COMPANY,
    apiCall: CompanyService.changeCompanyLogo,
    params: [companyId, companyLogo],
    onSuccess: () =>
      Toast.onSuccess('Your account has been successfully updated'),
    onFailure: (dispatch, error) => Toast.showWarning(`${error.error}`)
  });
}
