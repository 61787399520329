import { hasActiveOnboardings, hasCompletedAllOnboarding } from './workflows';
import { dunsRequestStatuses, wizardSteps } from '../_constants/dunsRequest';

export const getDunsRequests = state => state.dunsRequests.items;
export const getDunsRequest = state => {
  const requests = getDunsRequests(state);
  const firstId = Object.keys(requests)[0];

  return requests[firstId];
};

export const getDunsRequestFiles = state => {
  const dunsRequest = getDunsRequest(state);
  const files = dunsRequest && dunsRequest.files && dunsRequest.files._files;
  if (!files) {
    return {};
  }

  return files.reduce((map, file) => {
    if (map[file.type]) {
      map[file.type].push(file);
    } else {
      map[file.type] = [file];
    }

    return map;
  }, {});
};

export const isPendingDuns = (dunsRequest = {}) => {
  return (
    (dunsRequest.status !== dunsRequestStatuses.DRAFT &&
      dunsRequest.status !== dunsRequestStatuses.COMPLETED) ||
    (dunsRequest.status === dunsRequestStatuses.COMPLETED &&
      dunsRequest.step === wizardSteps.DELIVERY)
  );
};

export const hasPendingDuns = state => {
  const dunsRequest = getDunsRequest(state);
  if (!dunsRequest) {
    return false;
  }
  const {
    user: { user }
  } = state;

  const hasCompletedOnboarding =
    !user.hasCompany() &&
    hasActiveOnboardings(state) &&
    hasCompletedAllOnboarding(state);

  return isPendingDuns(dunsRequest) || hasCompletedOnboarding;
};

export const hasCompletedDunsRequest = state => {
  const dunsRequest = getDunsRequest(state);
  return !!(
    dunsRequest &&
    dunsRequest.status === dunsRequestStatuses.COMPLETED &&
    dunsRequest.step === wizardSteps.FINISHED
  );
};
