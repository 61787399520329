import merge from 'lodash/merge';
import get from 'lodash/get';

const defaultCompany = {
  info: {
    name: ''
  },
  udm: {}
};

export class Company {
  constructor(company = defaultCompany) {
    merge(this, company);
  }

  getId() {
    return this._id;
  }

  getLogo() {
    return get(this, 'info.logo', '');
  }

  getLegalName() {
    return get(this, 'legal_name');
  }

  getDunsNumber() {
    return get(this, 'info.duns');
  }
}
