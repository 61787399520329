import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Joyride, { EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import WalkthroughService from '../../_services/Walkthrough';
import { getCompletedWalkthroughSteps } from '../../_selectors/ui';
import {
  saveCompletedWalkthroughStep,
  uiWalkthroughMounted,
  uiWalkthroughUnmounted
} from '../../_actions/ui';

const styles = {
  options: {
    overlayColor: 'rgba(0, 0, 0, 0.4)',
    primaryColor: '#00d2c7',
    textColor: '#003c53',
    zIndex: 1002
  },
  buttonBack: {
    border: '1px solid transparent',
    borderRadius: '2px',
    color: '#333',
    background: '#f2f2f2',
    padding: '6px 16px',
    fontSize: '14px',
    height: '36px'
  },
  buttonNext: {
    border: '1px solid transparent',
    borderRadius: '2px',
    color: '#fff',
    background: '#00b2a9',
    padding: '6px 16px',
    fontSize: '14px',
    height: '36px'
  }
};

class Walkthrough extends Component {
  state = {
    run: true
  };

  componentDidMount() {
    const { dispatchUiWalkthroughMounted } = this.props;
    dispatchUiWalkthroughMounted();
  }

  componentWillUnmount() {
    const { dispatchUiWalkthroughUnmounted } = this.props;
    dispatchUiWalkthroughUnmounted();
  }

  handleStepChange = event => {
    const { dispatchSaveCompletedStep } = this.props;

    if (event.type === EVENTS.STEP_AFTER && event.step) {
      dispatchSaveCompletedStep(event.step.id);
      this.refreshSteps();
    }
  };

  refreshSteps = () => {
    const defferedStateReset = () =>
      setTimeout(() => {
        this.setState({ run: true });
      });

    this.setState({ run: false }, defferedStateReset);
  };

  render() {
    const { availableSteps, completedSteps } = this.props;
    const { run } = this.state;

    const steps = WalkthroughService.filterCompletedSteps(
      availableSteps,
      completedSteps
    ).map(WalkthroughService.createJoyrideStep);

    return (
      <Joyride
        run={run}
        steps={steps}
        callback={this.handleStepChange}
        styles={styles}
      />
    );
  }
}

Walkthrough.propTypes = {
  completedSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableSteps: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      stepData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

Walkthrough.defaultProps = {
  availableSteps: []
};

const mapStateToProps = state => ({
  completedSteps: getCompletedWalkthroughSteps(state)
});

const mapDispatchToProps = {
  dispatchSaveCompletedStep: saveCompletedWalkthroughStep,
  dispatchUiWalkthroughMounted: uiWalkthroughMounted,
  dispatchUiWalkthroughUnmounted: uiWalkthroughUnmounted
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Walkthrough);
