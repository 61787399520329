import React, { Component } from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Localize } from 'react-common/services';
import LanguageComponent from '../../LanguageComponent/LanguageComponent';

import '../Header.css';
import './PublicHeader.css';

class PublicHeader extends Component {
  state = {
    currentLanguage: Localize.getLanguage()
  };

  render() {
    const isRegisterUrl = window.location.pathname === '/register';
    const { className = 'col-md-9' } = this.props;
    return (
      <Navbar
        className={`Header PublicHeader hidden-xs hidden-sm ${className}`}
      >
        <Nav className="Header-nav Public-navbar" pullRight>
          <LanguageComponent />
          <LinkContainer to={isRegisterUrl ? '/login' : '/register'}>
            <NavItem eventKey={2}>
              <span className="navbar-text">
                {isRegisterUrl
                  ? 'Already have an account?'
                  : "Don't have an account?"}
              </span>
              <button className="Btn-outline Btn-outline-primary">
                {isRegisterUrl ? 'Login' : 'Sign up now'}
              </button>
            </NavItem>
          </LinkContainer>
        </Nav>
      </Navbar>
    );
  }
}

export default PublicHeader;
