import React from 'react';
import { Link } from 'react-router-dom';
import phoneImg from '../../_images/phone.svg';

const Confirmation = userPhone => (
  <div className="call-verification-step">
    <h4>
      Perfect! You are now one step away from successfully connecting your
      company with MyCial.
    </h4>

    <p>
      One of our business consultants will be in touch with you to confirm your
      details. <br />
      <br />
      You should expect a call at{' '}
      <strong notranslate="true">{userPhone}</strong>
      . <br />
      To change your number go to{' '}
      <Link to="/settings/profile">account settings</Link>.
    </p>
    <p className="text-center">
      <img
        alt="Verification call"
        className="svg-confirmation-call"
        src={phoneImg}
      />
    </p>
  </div>
);

export default Confirmation;
