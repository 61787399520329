import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearFields, change } from 'react-common/libs/reduxForm';
import InitialInvestigationForm from './InitialInvestigationForm';
import Grant from '../../Grant/Grant';
import { viewGrants } from '../../../_constants/grants';
import { deliveryCodes, productTypes } from '../../../_constants/dbia';
import { createOrder, getCountryProducts } from '../../../_actions/dbia';
import { resetSearch } from '../../../_actions/search';
import { getUser } from '../../../_selectors/user';
import '../Search.css';

const steps = {
  NO_RESULTS: 'NO_RESULTS',
  INITIAL_INVESTIGATION_FORM: 'INITIAL_INVESTIGATION_FORM',
  INITIAL_INVESTIGATION_PLACED: 'INITIAL_INVESTIGATION_PLACED'
};

class DefaultNoResults extends Component {
  state = {
    step: steps.NO_RESULTS,
    ordering: false,
    loadingCountryProducts: false,
    countryProducts: []
  };

  handleInvestigate = () => {
    this.setState({ step: steps.INITIAL_INVESTIGATION_FORM });
  };

  handleCancelInvestigation = () => {
    this.setState({ step: steps.NO_RESULTS });
  };

  handleInvestigationPlaced = () => {
    this.setState({ step: steps.INITIAL_INVESTIGATION_PLACED });
  };

  fetchCountryProducts = countryCode => {
    const { dispatchGetCountryProducts } = this.props;

    this.setState({ loadingCountryProducts: true });

    const options = {
      onSuccess: products => {
        this.setState({ countryProducts: products });
        this.initializeProductDropdowns(products);
      }
    };

    dispatchGetCountryProducts(countryCode, options)
      .then(() => this.setState({ loadingCountryProducts: false }))
      .catch(() => this.setState({ loadingCountryProducts: false }));
  };

  initializeProductDropdowns = products => {
    const { dispatchChangeField } = this.props;
    const initialProduct = products.find(product => product.Code === 'UBIR')
      ? 'UBIR'
      : products[0].Code;
    const initialLanguage = 'EN';

    dispatchChangeField('initialInvestigation', 'product', initialProduct);
    dispatchChangeField(
      'initialInvestigation',
      'productLanguage',
      initialLanguage
    );
  };

  clearProductFields = () => {
    const { dispatchClearFields } = this.props;
    dispatchClearFields(
      'initialInvestigation',
      true,
      true,
      'product',
      'productLanguage'
    );
  };

  handleCountryChange = (event, newValue) => {
    this.clearProductFields();
    this.fetchCountryProducts(newValue);
  };

  getCountryProductOptions = () => {
    const { countryProducts } = this.state;

    return countryProducts.map(product => ({
      label: product.Description,
      value: product.Code
    }));
  };

  getProductLanguageOptions = () => {
    const { countryProducts } = this.state;

    if (!countryProducts.length) return [];

    return countryProducts[0].Languages.map(lang => ({
      label: lang.Description,
      value: lang.Code
    }));
  };

  handleSubmit = formData => {
    const { ordering } = this.state;

    if (ordering) {
      return;
    }

    const { user, dispatchCreateOrder } = this.props;
    const order = {
      // language,
      reportType: productTypes.BIR,
      deliveryCode: deliveryCodes.WEBHOOK,
      emails: [user.info.email],
      companyName: formData.companyName,
      countryCode: formData.country,
      city: formData.city,
      state: formData.state,
      postcode: formData.postcode,
      phoneNumber: formData.phoneNumber,
      comments: formData.taxId ? `TAX ID: ${formData.taxId}` : ''
    };
    const options = {
      onStart: () => this.setState({ ordering: true }),
      onEnd: () => this.setState({ ordering: false }),
      onSuccess: this.handleInvestigationPlaced
    };

    dispatchCreateOrder({ order, options });
  };

  renderNoResults = () => {
    const { state, country } = this.props.searchParams;

    if (!state && ['USA', 'CAN'].includes(country)) {
      return (
        <div className="NoResults">
          <h4>Please choose a state</h4>
          <p>This country requires a state to be chosen to find matches.</p>
        </div>
      );
    }

    return (
      <div className="NoResults">
        <h4>No results found</h4>
        <p>
          We can't find that company. Try searching under an alternate name.
        </p>
        <Grant permissions={viewGrants.DBIA_ORDERS}>
          <>
            <p>
              You can also order an investigation, and CIAL will attempt to find
              your target company.
            </p>
            <button
              className="Btn-default Btn-default-primary"
              onClick={this.handleInvestigate}
            >
              Place an investigation
            </button>
          </>
        </Grant>
      </div>
    );
  };

  renderInitialInvestigationForm = () => {
    const { ordering, loadingCountryProducts } = this.state;
    const { searchParams, user } = this.props;
    const productOptions = this.getCountryProductOptions();
    const languageOptions = this.getProductLanguageOptions();

    return (
      <InitialInvestigationForm
        initialValues={{
          country: searchParams.country,
          state: searchParams.state,
          companyName: searchParams.name
        }}
        user={user}
        disableSubmit={ordering}
        onSubmit={this.handleSubmit}
        handleCancel={this.handleCancelInvestigation}
        onCountryChange={this.handleCountryChange}
        loadingProducts={loadingCountryProducts}
        productOptions={productOptions}
        languageOptions={languageOptions}
      />
    );
  };

  renderInvestigationPlaced = () => {
    const { dispatchResetSearch } = this.props;

    return (
      <>
        <h4>Investigation has been placed</h4>
        <p>
          Thank you for placing an investigation. <br />
          We will notify you as soon as it has been completed.
        </p>
        {/* This button destroys everything and starts a blank search */}
        <button
          onClick={() => dispatchResetSearch()}
          className="Btn-default Btn-default-primary"
        >
          Start new search
        </button>
      </>
    );
  };

  render() {
    const { step } = this.state;

    return (
      <>
        {step === steps.NO_RESULTS && this.renderNoResults()}
        {step === steps.INITIAL_INVESTIGATION_FORM &&
          this.renderInitialInvestigationForm()}
        {step === steps.INITIAL_INVESTIGATION_PLACED &&
          this.renderInvestigationPlaced()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: getUser(state)
});

const mapDispatchToProps = {
  dispatchCreateOrder: createOrder,
  dispatchResetSearch: resetSearch,
  dispatchGetCountryProducts: getCountryProducts,
  dispatchClearFields: clearFields,
  dispatchChangeField: change
};

DefaultNoResults.propTypes = {
  dispatchCreateOrder: PropTypes.func.isRequired,
  dispatchResetSearch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  searchParams: PropTypes.shape({
    country: PropTypes.string,
    state: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultNoResults);
