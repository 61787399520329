import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import OnboardingComponent from '../_components/Onboarding/Onboarding';
import { updateHeaderBreadcrumbs } from '../_actions';
import { viewGrants } from '../_constants/grants';
import './Onboarding.css';
import { withPermissions } from '../_components/Grant/Grant';
import PageNotFound from '../PageNotFound';

class Onboarding extends Component {
  componentDidMount() {
    const { dispatchUpdateHeaderBreadcrumbs } = this.props;
    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Onboardings'
      }
    ]);
  }

  render() {
    const { workflows } = this.props;

    return (
      <AppWrapper>
        <div className="onboarding">
          <OnboardingComponent workflows={workflows.active_onboardings} />
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { workflows } = state;
  return { workflows };
}

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs
};

Onboarding = connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding);

export default withPermissions(viewGrants.ONBOARDINGS, PageNotFound)(
  Onboarding
);
