import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ComponentWithLoader,
  CountryDropdown,
  StateDropdown
} from 'react-common/components';
import { DunsNumberAbbrText } from 'react-common/components/DunsAbbr/DunsAbbr';
import {
  resetSearch,
  updateSearchCountry,
  updateSearchName,
  updateSearchResults,
  updateSearchState,
  updateSearchSize,
  updateSearchPage
} from '../../_actions';

import './Search.css';
import Pagination from 'react-js-pagination';
import Alert from '../Alert';
import debounce from 'lodash/debounce';
import DefaultResults from './Templates/DefaultResults';
import DefaultNoResults from './Templates/DefaultNoResults';
import { getCountriesList } from '../../_services/Cometa';

class SearchComponent extends Component {
  componentDidMount() {
    const { dispatchUpdateSearchSize, itemsPerPage } = this.props;
    dispatchUpdateSearchSize(itemsPerPage);
    getCountriesList()
      .then(countries => this.setState({ countries }))
      .catch(error => {
        console.error('Error during GET from /api/cometa/country:', error);
        this.setState({ countries: null });
      });
  }

  componentWillUnmount() {
    const { dispatchResetSearch } = this.props;
    dispatchResetSearch();
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props;

    if (JSON.stringify(prevProps.search) === JSON.stringify(search)) {
      return;
    }

    if (prevProps.search.name !== search.name) {
      // debounce keystrokes
      return this.debouncedInitSearch();
    }

    this.initSearch();
  }

  initSearch = () => {
    const { search, dispatchUpdateSearchResults } = this.props;

    dispatchUpdateSearchResults({ cialOnly: false, ...search });
  };

  debouncedInitSearch = debounce(() => {
    this.initSearch();
  }, 250);

  onNameChange = name => {
    const { dispatchUpdateSearchName } = this.props;
    dispatchUpdateSearchName(name);
  };

  onCountryChange = country => {
    const { dispatchUpdateSearchCountry } = this.props;
    dispatchUpdateSearchCountry(country);
  };

  onStateChange = state => {
    const { dispatchUpdateSearchState } = this.props;
    dispatchUpdateSearchState(state);
  };

  resetNameField = () => {
    const { dispatchResetSearch } = this.props;
    dispatchResetSearch('name');
  };

  render() {
    const {
      search,
      apiState,
      cialOnly,
      ResultsTemplate,
      NoResultsTemplate,
      additionalProps,
      dispatchUpdateSearchPage,
      onSelect
    } = this.props;

    const { name, country, state, results, total, size, page } = search;
    const hasQuery = name;

    return (
      <div>
        <div className="search-form">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <CountryDropdown
                  id="input-search-select-country"
                  isClearable
                  cialOnly={cialOnly}
                  name="country"
                  value={country}
                  onChange={this.onCountryChange}
                  placeholder="Choose Country"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div notranslate="true" className="form-group">
                <StateDropdown
                  id="input-search-select-state"
                  isClearable
                  name="state"
                  value={state}
                  onChange={this.onStateChange}
                  country={country}
                  placeholder="Choose Area"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="form-group">
                <input
                  id="input-search-query"
                  type="text"
                  value={name}
                  onChange={event => this.onNameChange(event.target.value)}
                  className="form-control"
                  name="search"
                  placeholder={`Enter Company Name or ${DunsNumberAbbrText}`}
                />
                {name && (
                  <i
                    onClick={this.resetNameField}
                    className="search-reset icon-ic_close"
                  />
                )}
                <i className="search-submit icon-ic_search" />
              </div>
            </div>
          </div>
        </div>

        <ComponentWithLoader showLoader={apiState.isFetching}>
          {!country && hasQuery && (
            <Alert type="info" icon="eye" closeIconVisible={false}>
              Default searches (when no Country is selected) show results for
              Latin America and the Caribbean only.
            </Alert>
          )}

          {!!results.length && (
            <ResultsTemplate
              results={results}
              countries={this.state.countries}
              onSelect={onSelect}
              searchParams={{
                country,
                state,
                name
              }}
              {...additionalProps}
            />
          )}
        </ComponentWithLoader>

        {!!total && hasQuery && (
          <Pagination
            activePage={page}
            itemsCountPerPage={size}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={dispatchUpdateSearchPage}
          />
        )}

        {!total && hasQuery && (
          <div className="row">
            <div className="col-xs-12">
              <NoResultsTemplate
                searchParams={{
                  country,
                  state,
                  name
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { api, search } = state;
  const apiState = api[updateSearchResults.name] || {};
  return { search, apiState, ...props };
}

const mapDispatchToProps = {
  dispatchResetSearch: resetSearch,
  dispatchUpdateSearchCountry: updateSearchCountry,
  dispatchUpdateSearchName: updateSearchName,
  dispatchUpdateSearchResults: updateSearchResults,
  dispatchUpdateSearchState: updateSearchState,
  dispatchUpdateSearchSize: updateSearchSize,
  dispatchUpdateSearchPage: updateSearchPage
};

SearchComponent.propTypes = {
  name: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  additionalProps: PropTypes.object,
  cialOnly: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  ResultsTemplate: PropTypes.func,
  NoResultsTemplate: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      duns: PropTypes.string,
      legal_name: PropTypes.string,
      status: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        street: PropTypes.string,
        municipality: PropTypes.string
      })
    })
  )
};

SearchComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchComponent);
SearchComponent.defaultProps = {
  ResultsTemplate: DefaultResults,
  NoResultsTemplate: DefaultNoResults,
  cialOnly: false,
  itemsPerPage: 20
};

export default SearchComponent;
