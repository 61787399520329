import React, { Component } from 'react';
import { DunsNumberAbbr, Sidebar } from 'react-common/components';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'react-common/libs/reduxForm';

import PublicHeader from '../_components/Header/PublicHeader';
import logo from '../_images/mycial-logo.svg';
import logoColor from '../_images/mycial-logo-color.svg';

import './Login.css';

class LoginForm extends Component {
  render() {
    const { handleSubmit, onSubmit, submitting } = this.props;
    return (
      <div className="Login">
        <Sidebar
          logo={logo}
          logoAlt="MyCIAL"
          className="col-md-3 hidden-sm hidden-xs"
          headerText="Login to your account"
          descriptionText={
            <>
              <p>In MyCIAL you can:</p>
              <ul>
                <li>
                  Manage your CIAL Dun & Bradstreet commercial profile & update
                  your company data
                </li>
                <li>
                  Look up & request a <DunsNumberAbbr />
                </li>
                <li>Search for other organizations</li>
                <li>
                  Verify <DunsNumberAbbr />
                </li>
              </ul>
            </>
          }
          showContact={true}
        />

        <div className="col-md-9">
          <div className="row">
            <PublicHeader />
            <div className="col-md-6 col-md-offset-3 col-lg-5 col-lg-offset-3">
              <form onSubmit={handleSubmit(onSubmit)} className="Login-form">
                <div>
                  <img
                    src={logoColor}
                    alt="MyCIAL"
                    className="logo2 visible-xs visible-sm"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    className="form-control Login-input"
                    name="email"
                    component="input"
                    placeholder="example@cialdnb.com"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    className="form-control Login-input"
                    name="password"
                    component="input"
                    placeholder="Enter Password"
                    autoComplete="off"
                  />
                </div>

                <div className="row">
                  <div className="col-xs-5 col-xs-offset-7 form-group forgot-pass">
                    <Link
                      to="/reset-password"
                      className="btn-forgot-pass pull-right"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="Btn-default Btn-default-primary form-control"
                  >
                    Login
                  </button>
                </div>
                <div className="link-div visible-sm visible-xs">
                  <Link className="signup-link" to="/register">
                    Sign up
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Login = reduxForm({
  form: 'login'
})(LoginForm);

export default Login;
