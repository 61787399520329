import { Fetch } from 'react-common/services';

const getHeaders = accountId => ({ account_id: accountId });

class BirRequest {
  getAdminBirRequestById(birRequestId, accountId) {
    return Fetch.getJSON(`/api/admin/bir-requests/${birRequestId}`, {
      headers: getHeaders(accountId)
    });
  }

  updateAdminBirRequestFieldById(birRequestId, changedData, accountId) {
    return Fetch.putJSON(`/api/new-bir-requests/${birRequestId}`, changedData, {
      headers: getHeaders(accountId)
    });
  }

  updateAdminBirRequestById(birRequestId, changedData, accountId) {
    return Fetch.putJSON(
      `/api/admin/bir-requests/${birRequestId}`,
      changedData,
      {
        headers: getHeaders(accountId)
      }
    );
  }

  uploadAdminBirRequestFile(birRequestId, file, accountId) {
    return Fetch.patch(`/api/new-bir-requests/${birRequestId}/files`, file, {
      headers: getHeaders(accountId)
    });
  }

  deleteAdminBirRequestFile(birRequestId, fileName, accountId) {
    return Fetch.deleteJSON(
      `/api/new-bir-requests/${birRequestId}/files?fileName=${fileName}`,
      {
        headers: getHeaders(accountId)
      }
    );
  }
}

export default new BirRequest();
