import React, { Component } from 'react';
import { Field, reduxForm } from 'react-common/libs/reduxForm';
import { Util } from 'react-common/services';
import { UploadcareDialog } from 'react-common/components';
import './UserProfile.css';

class UserForm extends Component {
  render() {
    const {
      handleSubmit,
      change,
      formValues = { info: {} },
      language
    } = this.props;

    return (
      <div className="panel Profile">
        <h2 className="panel-heading Title-table">Profile</h2>
        <div className="panel-content Content-table-last">
          <form onSubmit={handleSubmit}>
            <label>Profile Picture</label>

            <div
              className="Image-container"
              style={
                formValues.info &&
                formValues.info.image && {
                  backgroundImage: `url(${formValues.info.image})`
                }
              }
              onClick={() => this.uploader.openDialog()}
            >
              {!formValues.info.image && (
                <div className="Colored-circle">
                  <var>{Util.getInitials(formValues.info.name)}</var>
                </div>
              )}
              <div className="Image-overlay">
                <div className="Overlay-text">
                  {(formValues.info.image && 'Change') || 'Upload'}
                </div>
              </div>
              <UploadcareDialog
                value={formValues.info.image}
                ref={uploader => (this.uploader = uploader)}
                onUploadComplete={info => change('info.image', info.cdnUrl)}
                language={language}
              />
            </div>

            <label htmlFor="info.name">Full Name</label>
            <Field
              type="text"
              name="info.name"
              className="form-control"
              component="input"
              placeholder="Full Name"
            />

            <label htmlFor="info.email">Email</label>
            <Field
              type="text"
              name="info.email"
              className="form-control"
              component="input"
              placeholder="Email"
              disabled={true}
            />

            <div className="row">
              <div className="col-md-8">
                <label htmlFor="info.phone_work">Phone</label>
                <Field
                  type="text"
                  name="info.phone_work"
                  className="form-control"
                  component="input"
                  placeholder="Phone"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="info.phone_ext">Phone Ext.</label>
                <Field
                  type="text"
                  name="info.phone_ext"
                  className="form-control"
                  component="input"
                  placeholder="Phone Ext"
                />
              </div>
            </div>

            <button type="submit" className="Btn-default Btn-default-primary">
              Update changes
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const UserProfile = reduxForm({
  form: 'user',
  enableReinitialize: true
})(UserForm);

export default UserProfile;
