import React, { Component } from 'react';
import { PasswordField, Sidebar } from 'react-common/components';
import { Validation } from 'react-common/services';
import { reduxForm } from 'react-common/libs/reduxForm';

import logo from '../_images/mycial-logo.svg';
import PublicHeader from '../_components/Header/PublicHeader';
import './ChangePassword.css';

const minPasswordLength = Validation.minLength(7);

class ChangePasswordForm extends Component {
  render() {
    const { handleSubmit, submitting, invalid } = this.props;

    return (
      <div className="ChangePassword">
        <Sidebar
          logo={logo}
          className="col-md-3 hidden-sm hidden-xs"
          headerText="Create new password"
          descriptionText="Enter and confirm your new password"
          showContact={true}
        />
        <div className="col-md-9">
          <div className="row">
            <PublicHeader />
            <div className="col-md-5 col-md-offset-3">
              <form onSubmit={handleSubmit} className="ChangePassword-form">
                <div className="form-group">
                  <PasswordField
                    name="password"
                    className="form-control"
                    label="New Password"
                    placeholder="7+ characters"
                  />
                </div>
                <div className="form-group">
                  <PasswordField
                    name="confirmPassword"
                    className="form-control"
                    label="Confirm New Password"
                    placeholder="7+ characters"
                    autoComplete="off"
                    validate={[Validation.required, minPasswordLength]}
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={submitting || invalid}
                    className="Btn-default Btn-default-primary form-control"
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ChangePassword = reduxForm({
  form: 'change-password'
})(ChangePasswordForm);

export default ChangePassword;
