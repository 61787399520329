import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCompanyByDunsNumber } from '../../../_selectors/universe';

import SingleChange from './SingleChange';

class SingleChangeContainer extends Component {
  render() {
    const { company, change } = this.props;
    return change && <SingleChange change={change} company={company} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    company: getCompanyByDunsNumber(state, props.change.duns)
  };
};

export default connect(mapStateToProps)(SingleChangeContainer);
