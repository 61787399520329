import { DunsNumberAbbrText } from 'react-common/components/DunsAbbr/DunsAbbr';

const companySearch = {
  selectCountry: {
    id: 'COMPANY_SEARCH.selectCountry',
    content: 'Select country to narrow down your search'
  },
  enterSearchText: {
    id: 'COMPANY_SEARCH.enterSearchText',
    content: `Search by company name or ${DunsNumberAbbrText}`
  },
  showDetails: {
    id: 'COMPANY_SEARCH.showDetails',
    content: 'Click here to view more information and see available reports'
  },
  orderReport: {
    id: 'COMPANY_SEARCH.orderReport',
    content:
      'Order reports to view them on screen or send them in an email to multiple recipients'
  }
};
const reportOrders = {
  pendingOrder: {
    id: 'REPORT_ORDERS.pendingOrder',
    content:
      'You will receive a notification when pending reports are completed'
  }
};

export const walkthroughSteps = {
  companySearch,
  reportOrders
};
