import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDbiaOrderReport } from '../_selectors/dbia';
import { getDbiaReport } from '../_actions/dbia';
import { ComponentWithLoader } from 'react-common/components';

class ReportContainer extends Component {
  state = {
    error: null
  };

  componentDidMount() {
    const { dispatchGetDbiaReport, orderId } = this.props;

    dispatchGetDbiaReport(orderId, (dispatch, error) =>
      this.setState({ error: error.error })
    );
  }

  render() {
    const { orderId, apiState } = this.props;
    let { report } = this.props;
    const { error } = this.state;

    // Wrap plaintext reports with <pre> coz they are formatted with whitespace
    if (report && !report.toLowerCase().includes('<html')) {
      report = `<pre>${report}</pre>`;
    }

    return (
      <ComponentWithLoader showLoader={!report && apiState.isFetching}>
        {error && (
          <div className="col-xs-12 Container-animated">
            Something went wrong with order <b>{orderId}</b>, please contact
            support.
            <br />
            <pre>{error}</pre>
          </div>
        )}

        {!!report && <div dangerouslySetInnerHTML={{ __html: report }} />}
      </ComponentWithLoader>
    );
  }
}

const mapStateToProps = (state, props) => ({
  report: getDbiaOrderReport(state),
  orderId: props.orderId || props.match.params.orderId,
  apiState: state.api[getDbiaReport.name] || {}
});

const mapDispatchToProps = {
  dispatchGetDbiaReport: getDbiaReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportContainer);
