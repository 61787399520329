import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import AlertContainer from '../_components/Alert/AlertContainer';
import { reloadUser, updateHeaderBreadcrumbs } from '../_actions';
import ProductOptionBox from '../_components/ProductOption/ProductOptionBox';
import { dunsRequestIssueDuration } from '../_constants/dunsRequest';
import { viewGrants } from '../_constants/grants';
import imgConnect from '../_images/ic_connect.svg';
import imgGetDuns from '../_images/ic_get_duns.svg';
import './GetStarted.css';
import { withPermissions } from '../_components/Grant/Grant';
import PageNotFound from '../PageNotFound';
import { ComponentWithLoader, DunsNumberAbbr } from 'react-common/components';
import { getInitialUrl } from '../_selectors/ui';

class GetStarted extends Component {
  componentDidMount() {
    const { dispatchUpdateHeaderBreadcrumbs } = this.props;
    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Get Started'
      }
    ]);
  }

  render() {
    const { apiState, initialUrl } = this.props;

    if (initialUrl) {
      return <Redirect to={initialUrl} />;
    }

    return (
      <ComponentWithLoader showLoader={apiState.isFetching}>
        <AppWrapper>
          <div className="Home text-center">
            <AlertContainer />
            <h1 className="mtop-50">Welcome to MyCIAL!</h1>

            <p>
              Search for your Company and connect it with MyCIAL.
              <br />
              If you can’t find it, you need a <DunsNumberAbbr />.
            </p>

            <div className="col-xs-12 col-lg-offset-1 col-lg-10 mtop-30">
              <div className="row">
                <div className="col-xs-12 col-md-4">
                  <ProductOptionBox
                    green
                    img={imgConnect}
                    title="Search and connect your Company"
                    body={
                      <span>
                        If you already have a <DunsNumberAbbr /> you can start
                        the process of connecting your company with MyCIAL.
                      </span>
                    }
                    linkTo="/claims"
                    linkText="Search & Connect"
                  />
                </div>

                <div className="col-xs-12 col-md-4">
                  <ProductOptionBox
                    highlighted
                    img={imgGetDuns}
                    title={
                      <span>
                        Get <DunsNumberAbbr /> within&nbsp;
                        {dunsRequestIssueDuration.EXPRESS} days
                      </span>
                    }
                    body={
                      <span>
                        You will receive your <DunsNumberAbbr />{' '}
                        {dunsRequestIssueDuration.EXPRESS}
                        &nbsp;days after CIAL Dun & Bradstreet receives your
                        data.
                      </span>
                    }
                    linkTo="/duns?type=express"
                    linkText={
                      <span>
                        Get <DunsNumberAbbr />
                      </span>
                    }
                  />
                </div>

                <div className="col-xs-12 col-md-4">
                  <ProductOptionBox
                    img={imgGetDuns}
                    title={
                      <span>
                        Get <DunsNumberAbbr /> within&nbsp;
                        {dunsRequestIssueDuration.FREE} days
                      </span>
                    }
                    body={
                      <span>
                        You will receive your <DunsNumberAbbr />{' '}
                        {dunsRequestIssueDuration.FREE}
                        &nbsp;days after CIAL Dun & Bradstreet receives your
                        data.
                      </span>
                    }
                    linkTo="/duns?type=free"
                    linkText={
                      <span>
                        Get <DunsNumberAbbr />
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </AppWrapper>
      </ComponentWithLoader>
    );
  }
}

function mapStateToProps(state) {
  const { api } = state;
  const apiState = api[reloadUser.name] || {};

  return {
    initialUrl: getInitialUrl(state),
    apiState
  };
}

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs
};

GetStarted = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetStarted);

export default withPermissions(viewGrants.GET_STARTED, PageNotFound)(
  GetStarted
);
