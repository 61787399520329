import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { updateHeaderBreadcrumbs } from '../_actions';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import UserProfile from './UserProfile';
import Password from './Password';
import CompanyProfile from './CompanyProfile';
import './Settings.css';

class Settings extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      updateHeaderBreadcrumbs([
        {
          title: 'My account'
        }
      ])
    );
  }

  handleSelect = section => {
    const { dispatch } = this.props;
    dispatch(push(`/settings/${section}`));
  };

  render() {
    return (
      <AppWrapper>
        <Tabs
          className="col-md-6 col-md-offset-3 col-lg-5 col-lg-offset-3"
          id="user-settings-tabs"
          activeKey={this.props.match.params[0]}
          onSelect={this.handleSelect}
        >
          <Tab eventKey="profile" title="Profile">
            <UserProfile />
          </Tab>
          <Tab eventKey="company" title="Company">
            <CompanyProfile />
          </Tab>
          <Tab eventKey="password" title="Password">
            <Password />
          </Tab>
        </Tabs>
      </AppWrapper>
    );
  }
}

export default connect()(Settings);
