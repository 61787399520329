const dunsRequestConstants = {
  GET_DUNS_REQUESTS_FOR_ACCOUNT: 'GET_DUNS_REQUESTS_FOR_ACCOUNT',
  CREATE_DUNS_REQUEST: 'CREATE_DUNS_REQUEST',
  PATCH_DUNS_REQUEST: 'PATCH_DUNS_REQUEST',
  UPLOAD_DUNS_REQUEST_FILE: 'UPLOAD_DUNS_REQUEST_FILE',
  DELETE_DUNS_REQUEST_FILE: 'DELETE_DUNS_REQUEST_FILE',
  SUBMIT_DUNS_REQUEST: 'SUBMIT_DUNS_REQUEST',
  MARK_DUNS_REQUEST_AS_SEEN: 'MARK_DUNS_REQUEST_AS_SEEN'
};

export const dunsRequestIssueDuration = {
  EXPRESS: '3',
  FREE: '30'
};

export const dunsRequestStatuses = {
  DRAFT: 'draft',
  SALES: 'sales',
  REVIEW: 'review',
  PENDING: 'pending',
  COMPLETED: 'completed'
};

export const wizardSteps = {
  FORM: 'form',
  CALL: 'call',
  PAYMENT: 'payment',
  VERIFICATION: 'verification',
  DELIVERY: 'delivery',
  FINISHED: 'finished'
};

export default dunsRequestConstants;
