import apiConstants from '../_constants/api';
import { getErrorFromResponse, getCallerName } from '../_utils/api';

export function apiStart(type = apiConstants.API, key = apiConstants.KEY) {
  return {
    type: type + apiConstants.START,
    key
  };
}

export function apiEnd(type = apiConstants.API, key = apiConstants.KEY) {
  return {
    type: type + apiConstants.END,
    key
  };
}

export function apiFailure(
  type = apiConstants.API,
  key = apiConstants.KEY,
  error
) {
  return {
    type: type + apiConstants.FAILURE,
    key,
    error
  };
}

export function apiSuccess(type = apiConstants.API, key = apiConstants.KEY) {
  return {
    type: type + apiConstants.SUCCESS,
    key
  };
}

export function apiReset(key = apiConstants.KEY) {
  return {
    type: apiConstants.RESET,
    key
  };
}

export function apiSendData(type = apiConstants.API, data) {
  return {
    type,
    data
  };
}

export function makeRequest({
  type = apiConstants.API,
  apiCall = async () => {},
  params = [],
  onRequestStart,
  onRequestEnd,
  onSuccess,
  onFailure
}) {
  const key = getCallerName();

  return dispatch => {
    dispatch(apiStart(type, key));
    onRequestStart && onRequestStart(dispatch);

    return apiCall
      .apply(apiCall, params)
      .then(data => {
        dispatch(apiSendData(type, data));
        dispatch(apiSuccess(type, key));
        onSuccess && onSuccess(dispatch, data);
        return data;
      })
      .catch(async response => {
        const error = await getErrorFromResponse(response);
        dispatch(apiFailure(type, key, error));

        if (process.env.REACT_APP_ENV !== 'production') {
          console.error(`HTTP request "${type}" failed with:`, error);
        }

        onFailure && onFailure(dispatch, error);
      })
      .then(data => {
        dispatch(apiEnd(type, key));
        onRequestEnd && onRequestEnd(dispatch);
        return data;
      });
  };
}
