import { claimStatuses, wizardSteps } from '../_constants/claim';

export const getClaims = state => state.claims.items;
export const getClaim = state => {
  const claims = getClaims(state);
  const firstId = Object.keys(claims)[0];

  return claims[firstId];
};

export const isPendingClaim = claim => {
  return (
    claim &&
    claim.status !== claimStatuses.COMPLETED &&
    claim.step !== wizardSteps.FINISHED
  );
};

export const isCompletedClaim = claim => {
  return (
    claim &&
    claim.status === claimStatuses.COMPLETED &&
    claim.step === wizardSteps.FINISHED
  );
};

export const hasCompletedClaim = state => {
  const claim = getClaim(state);
  return isCompletedClaim(claim);
};

export const hasPendingClaim = state => {
  const claim = getClaim(state);
  return isPendingClaim(claim);
};
