import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ComponentWithLoader } from 'react-common/components';

import {
  fetchUniverseById,
  fetchChangesForUniverse,
  deleteUniverse,
  removeDunsFromUniverse
} from '../../_actions';
import { getUniverseById } from '../../_selectors/universe';

import SingleUniverse from './SingleUniverse';

class SingleUniverseContainer extends Component {
  componentDidMount() {
    const {
      dispatchGetUniverseById,
      dispatchGetChangesForUniverse
    } = this.props;
    const universeId = this.props.match.params.universeId;
    dispatchGetChangesForUniverse(universeId);
    dispatchGetUniverseById(universeId);
  }

  render() {
    const {
      universe,
      apiState,
      dispatchDeleteUniverse,
      dispatchRemoveDunsFromUniverse
    } = this.props;

    const removeDuns = (duns, companyName) => {
      const successMsg = `${companyName} successfully removed from list: ${
        universe.name
      }`;
      dispatchRemoveDunsFromUniverse(universe['_id'], duns, successMsg);
    };

    return (
      <ComponentWithLoader showLoader={apiState.isFetching}>
        {universe && (
          <SingleUniverse
            universe={universe}
            deleteUniverse={dispatchDeleteUniverse}
            removeDuns={removeDuns}
          />
        )}
      </ComponentWithLoader>
    );
  }
}

const mapStateToProps = (state, props) => ({
  universe: getUniverseById(state, props.match.params.universeId),
  apiState: state.api[fetchUniverseById.name] || {}
});

const mapDispatchToProps = {
  dispatchGetUniverseById: fetchUniverseById,
  dispatchGetChangesForUniverse: fetchChangesForUniverse,
  dispatchDeleteUniverse: deleteUniverse,
  dispatchRemoveDunsFromUniverse: removeDunsFromUniverse
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleUniverseContainer);
