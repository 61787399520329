import filter from 'lodash/filter';
import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Table } from 'react-bootstrap';
import { MomentDate } from 'react-common/components';
import { DunsNumberAbbrText } from 'react-common/components/DunsAbbr/DunsAbbr';
import { showDbiaReportModal, emailDbiaReport } from '../_actions/dbia';
import { connect } from 'react-redux';
import EmailModal from '../_components/EmailModal/EmailModal';
import { walkthroughSteps } from '../_constants/walkthrough';
import Walkthrough from '../_components/Walkthrough/Walkthrough';
import Alert from '../_components/Alert';
import { getCountriesList } from '../_services/Cometa';

const availableWalkthroughSteps = [
  {
    target: '#pending-order-badge',
    stepData: walkthroughSteps.reportOrders.pendingOrder
  }
];

class Orders extends Component {
  state = {
    fromDate: moment()
      .subtract(7, 'days')
      .format(this.props.dateFormat),
    toDate: moment().format(this.props.dateFormat),
    timeFilteredOrders: [],
    searchFilteredOrders: []
  };

  componentDidMount() {
    const { orders } = this.props;
    this.resetSearch(this.filterOrders(orders));

    getCountriesList()
      .then(countries => this.setState({ countries }))
      .catch(error => {
        console.error('Error during GET from /api/cometa/country:', error);
        this.setState({ countries: null });
      });
  }

  filterOrders = orders => {
    const { fromDate, toDate } = this.state;
    const { dateFormat } = this.props;
    const timeFilteredOrders = orders.filter(order => {
      const creationDate = moment(order.created_at).format(dateFormat);
      return toDate >= creationDate && creationDate >= fromDate;
    });
    this.setState({ timeFilteredOrders });
    return timeFilteredOrders;
  };

  handleDateFromChange = e => {
    this.setState({ fromDate: e.target.value }, () => {
      this.handleSearch(
        this.state.searchTerm,
        this.filterOrders(this.props.orders)
      );
    });
  };

  handleDateToChange = e => {
    this.setState({ toDate: e.target.value }, () => {
      this.handleSearch(
        this.state.searchTerm,
        this.filterOrders(this.props.orders)
      );
    });
  };

  handleChange = e => {
    this.handleSearch(e.target.value);
  };

  handleReset = () => {
    this.resetSearch(this.state.timeFilteredOrders);
  };

  resetSearch = timeFilteredOrders => {
    this.setState({
      searchTerm: '',
      searchFilteredOrders: timeFilteredOrders
    });
  };

  handleSearch = (searchTerm, timeFilteredOrders = this.timeFilteredOrders) => {
    const regex = new RegExp(searchTerm, 'i');
    const searchFilteredOrders = filter(
      timeFilteredOrders,
      r => r.name.match(regex) || r.duns.match(regex)
    );

    this.setState({
      searchTerm,
      searchFilteredOrders
    });
  };

  viewReport = order => {
    const { dispatchShowDbiaReportModal } = this.props;
    return dispatchShowDbiaReportModal(order);
  };

  openEmailModal = orderId =>
    this.setState({
      showEmailModal: true,
      selectedOrderId: orderId
    });

  hideEmailModal = () => this.setState({ showEmailModal: false });

  emailReport = (orderId, emails) => {
    const { dispatchEmailDbiaReport } = this.props;
    return dispatchEmailDbiaReport(orderId, emails);
  };

  findCountry = countryCode => {
    const { countries } = this.state;
    return countries
      ? this.state.countries.find(country => country.code === countryCode)
          .title_en
      : countryCode;
  };

  renderOrder = ({
    _id,
    name,
    product,
    country,
    language,
    created_at,
    report
  }) => (
    <tr key={_id} id={_id}>
      <td>{name}</td>
      <td>{product.product_description}</td>
      <td>{this.findCountry(country)}</td>
      <td>{language}</td>
      <td>
        <MomentDate format=" DD/MM/YYYY" date={created_at} />
      </td>
      <td>
        {!report && (
          <span className="badge danger" id="pending-order-badge">
            Pending
          </span>
        )}
        {report && <span className="badge success">Completed</span>}
      </td>
      <td>
        {report && (
          <>
            <button
              className="Btn-outline-small Btn-outline-primary"
              onClick={() => this.viewReport({ _id })}
            >
              View report
            </button>
            <button
              className="Btn-outline-small Btn-outline-primary"
              onClick={() => this.openEmailModal(_id)}
            >
              Email report
            </button>
          </>
        )}
      </td>
    </tr>
  );

  render() {
    const {
      searchFilteredOrders = [],
      searchTerm = '',
      showEmailModal = false,
      selectedOrderId,
      fromDate,
      toDate
    } = this.state || {};
    const { dateFormat, orders } = this.props;

    return (
      <>
        <Row className="UsageHeader">
          <Walkthrough availableSteps={availableWalkthroughSteps} />
          <Col xs={12} sm={8}>
            <Row>
              <Col xs={12}>
                <label>Search orders</label>
              </Col>
              <Col xs={12} sm={8} className="form-group">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={this.handleChange}
                  className="form-control"
                  name="search"
                  placeholder={`Search for a company by name or ${DunsNumberAbbrText}`}
                />
                <i
                  onClick={this.handleChange}
                  className="search-reset icon-ic_close"
                />
                <i className="search-submit icon-ic_search" />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={4}>
            <Row>
              <Col xs={12} sm={6}>
                <label>Start date</label>
                <input
                  className="form-control"
                  type="date"
                  value={fromDate}
                  onChange={this.handleDateFromChange}
                  max={toDate}
                />
              </Col>
              <Col xs={12} sm={6}>
                <label>End date</label>
                <input
                  className="form-control"
                  type="date"
                  value={toDate}
                  onChange={this.handleDateToChange}
                  max={moment().format(dateFormat)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table className="reportsPurchaseTableInner">
              <thead>
                <tr>
                  <th className="th-company">Company</th>
                  <th className="th-report">Report</th>
                  <th className="th-type">Country</th>
                  <th className="th-type">Language</th>
                  <th className="th-orderdate">Order Date</th>
                  <th className="th-deliverydate">Status</th>
                  <th className="th-actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {searchFilteredOrders.map(this.renderOrder)}

                <EmailModal
                  showModal={showEmailModal}
                  onHideModal={this.hideEmailModal}
                  onSubmit={emails => {
                    this.emailReport(selectedOrderId, emails);
                    this.hideEmailModal();
                  }}
                />
              </tbody>
            </Table>
          </Col>
        </Row>
        {orders &&
          orders.length > 0 &&
          searchFilteredOrders &&
          searchFilteredOrders.length === 0 && (
            <Alert type="info" icon="eye" closeIconVisible={false}>
              Only orders for the past 7 days are displayed by default.
            </Alert>
          )}
        {orders && orders.length === 0 && (
          <Alert type="info" icon="eye" closeIconVisible={false}>
            You have no orders.
          </Alert>
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  dispatchShowDbiaReportModal: showDbiaReportModal,
  dispatchEmailDbiaReport: emailDbiaReport
};

Orders.defaultProps = { orders: [] };

export default connect(
  null,
  mapDispatchToProps
)(Orders);
