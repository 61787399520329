export const wizardSteps = {
  FIND: 'find',
  VERIFICATION: 'verification',
  DELIVERY: 'delivery',
  FINISHED: 'finished'
};

export const claimStatuses = {
  DRAFT: 'draft',
  SALES: 'sales',
  REVIEW: 'review',
  PENDING: 'pending',
  COMPLETED: 'completed'
};

export const claimConstants = {
  GET_CLAIMS_FOR_ACCOUNT: 'GET_CLAIMS_FOR_ACCOUNT',
  CLAIM_COMPANY: 'CLAIM_COMPANY',
  MARK_CLAIM_REQUEST_AS_SEEN: 'MARK_CLAIM_REQUEST_AS_SEEN'
};

export default claimConstants;
