import React from 'react';
import { Auth } from 'react-common/services';
import { Sidebar } from 'react-common/components';

import AppWrapper from '../AppWrapper/AppWrapper';
import serverErrorImage from '../../_images/server-error.svg';
import logo from '../../_images/mycial-logo.svg';

import PublicHeader from '../Header/PublicHeader';

import './ErrorFallback.css';

const ErrorFallback = () => {
  const errorContent = (
    <div className="ErrorFallback">
      <img src={serverErrorImage} alt="Error" />
      <p>Oops! An error has occurred.</p>
      <p>
        Please reload the page and try again. If the error persists,{' '}
        <a href="/contact" className="Contact-link">
          contact us.
        </a>
      </p>
    </div>
  );

  if (Auth.isUserAuthenticated()) {
    return <AppWrapper>{errorContent}</AppWrapper>;
  }

  return (
    <div>
      <Sidebar
        logo={logo}
        logoAlt="MyCIAL"
        className="col-md-3 hidden-sm hidden-xs"
        headerText="An error occurred"
        subHeaderText="We apologize for the inconvenience"
      />
      <div className="col-md-9">
        <div className="row">
          <PublicHeader />
          <div className="ErrorFallback-public">{errorContent}</div>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
