import React from 'react';
import trackImg from '../../_images/track.svg';
import shareImg from '../../_images/share.svg';
import buyImg from '../../_images/buy.svg';

const Connected = ({ onClaimSeen }) => (
  <div className="delivery-step">
    <div className="row">
      <div className="col-xs-12">
        <h4>Your company is now connected with MyCIAL</h4>
        <h4>You now have full access to MyCIAL!</h4>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-4">
        <img src={trackImg} alt="track-data" className="mtop-30 mbot-20" />
        <p className="delivery-step-text text-center">
          Track your company's data on the dashboard.
        </p>
      </div>
      <div className="col-xs-12 col-sm-4">
        <img src={buyImg} alt="buy-reports" className="mtop-30 mbot-20" />
        <p className="delivery-step-text text-center">
          Request reports and get to know your clients better.
        </p>
      </div>
      <div className="col-xs-12 col-sm-4">
        <img src={shareImg} alt="share-reports" className="mtop-30 mbot-20" />
        <p className="delivery-step-text text-center">
          Download and share all your reports.
        </p>
      </div>
      <div className="col-xs-12">
        <p className="text-center">
          <button
            onClick={onClaimSeen}
            className="Btn-default Btn-default-primary connect-button mtop-30 mbot-20"
          >
            Access MyCIAL
          </button>
        </p>
      </div>
    </div>
    {/* TODO: MARK MyCIAL Unlimited AS SEEN ON CLICK, find in the old frontend
    what does duns.markAsSeen() do. Redirect to /dashboard on click. Delete
    me later. */}
  </div>
);

export default Connected;
