import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  dunsRequestStatuses,
  dunsRequestIssueDuration
} from '../../_constants/dunsRequest';
import { getDunsRequest } from '../../_selectors/dunsRequests';
import { dunsRequestType } from 'react-common/constants';
import { DunsNumberAbbr } from 'react-common/components';
import hourglassImg from '../../_images/hourglass.svg';
import phoneImg from '../../_images/phone.svg';

class DunsNumberVerification extends Component {
  renderPendingStep = () => {
    const { dunsRequest } = this.props;
    const dunsNumberIssueDuration =
      dunsRequest.duns_type === dunsRequestType.EXPRESS
        ? dunsRequestIssueDuration.EXPRESS
        : dunsRequestIssueDuration.FREE;

    return (
      <div className="GetDunsNumber WizardPage">
        <div className="delivery-step">
          <div className="row">
            <div className="col-md-12">
              <img alt="Thank you" src={hourglassImg} />
              <h1>Thank you!</h1>
              <p>
                You’ll get your <DunsNumberAbbr /> in {dunsNumberIssueDuration}{' '}
                days
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { dunsRequest, userPhone } = this.props;

    if (!dunsRequest) {
      return;
    }

    if (dunsRequest.status === dunsRequestStatuses.PENDING) {
      return this.renderPendingStep();
    }

    return (
      <div className="GetDunsNumber WizardPage">
        <div className="call-verification-step">
          <h1>
            Almost done! You are one call away from receiving your{' '}
            <DunsNumberAbbr /> and joining the world's largest business network!
          </h1>
          <p>
            One of our business consultants will be in touch with you to confirm
            your details. <br />
            <br />
            You should expect a call at{' '}
            <strong notranslate="true">{userPhone}</strong>
            . <br />
            To change your number go to{' '}
            <Link to="/settings/profile">account settings</Link>.
          </p>
          <p className="text-center">
            <img
              alt="Verification call"
              className="svg-confirmation-call"
              src={phoneImg}
            />
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dunsRequest: getDunsRequest(state)
});

export default connect(mapStateToProps)(DunsNumberVerification);
