import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  Breadcrumb,
  MenuItem,
  Nav,
  Navbar,
  NavDropdown,
  NavItem
} from 'react-bootstrap';
import { accountRoles } from 'react-common/constants';
import { Auth, Util } from 'react-common/services';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { logout, changeAccountSettings } from '../../../_actions';
import UserComponent from '../../../_components/UserComponent';
import LanguageComponent from '../../LanguageComponent/LanguageComponent';
import CompanyComponent from '../../../_components/CompanyComponent';
import BoxHeader from '../../BoxHeader';
import MyCialAdminDomains from '../../../_constants/domain';
import AdminLink from '../../AdminLink/AdminLink';
import { clearCompletedWalkthroughSteps } from '../../../_actions/ui';
import { getMountedWalkthroughsCount } from '../../../_selectors/ui';

import logoColor from '../../../_images/mycial-logo-color.svg';
import '../Header.css';
import './AuthorizedHeader.css';

const UserHeader = () => (
  <MenuItem componentClass="div" className="Dropdown-header Header-with-link">
    <BoxHeader title={'My Profile'} />
  </MenuItem>
);

const UserContent = ({ user }) => (
  <MenuItem className="Dropdown-content">
    <UserComponent user={user} />
  </MenuItem>
);

const CompanyHeader = () => (
  <MenuItem componentClass="div" className="Dropdown-header Header-with-link">
    <BoxHeader title={'My company'} />
  </MenuItem>
);

const CompanyContent = ({ company }) => (
  <MenuItem className="Dropdown-content">
    <CompanyComponent company={company} />
  </MenuItem>
);

const MobileNavbar = ({ adminLink, name, companies, user, doLogout }) => (
  <Nav className="Header-nav visible-xs">
    <NavDropdown
      eventKey={1}
      title={
        <span>
          <span className="Hamburger-colored-circle">
            <var>{Util.getInitials(name)}</var>
          </span>
          <var>{name}</var>
        </span>
      }
      id="basic-nav-dropdown"
      ignore="true"
    >
      {companies.map((company, index) => (
        <MenuItem key={index} eventKey={index}>
          My Company: <var>{company.company.legal_name}</var>
        </MenuItem>
      ))}

      {Auth.isAccountConfirmed() && (
        <MenuItem
          eventKey={companies.length + 1}
          href="/settings/profile"
          className="Edit-account-small-header"
        >
          Edit Account
        </MenuItem>
      )}
    </NavDropdown>

    <NavItem eventKey={1} href="/home" className="Header-navitem">
      Get Started
    </NavItem>
    <NavItem eventKey={3} href="/search" className="Header-navitem">
      Company Search
    </NavItem>

    {user.getAccountRole() === accountRoles.ADMIN && (
      <AdminLink link={adminLink}>{'Admin Panel'}</AdminLink>
    )}

    <NavItem
      className="Header-navitem"
      id="logout"
      onClick={doLogout}
      eventKey={5}
    >
      Log out
    </NavItem>
  </Nav>
);

class AuthorizedHeader extends Component {
  doLogout = () => {
    const { dispatchLogout } = this.props;
    return dispatchLogout();
  };

  handleOnLanguageSelect = selectedLanguage => {
    const { dispatchChangeAccountSettings } = this.props;
    dispatchChangeAccountSettings({
      preferences: { language: selectedLanguage }
    });
  };

  handleShowWalkthrough = event => {
    const { dispatchClearCompletedWalkthroughSteps } = this.props;
    dispatchClearCompletedWalkthroughSteps();
    event.preventDefault();
  };

  render() {
    const {
      sidebarOpened,
      showSidebarToggle,
      toggleSidebar,
      headerBreadcrumbs,
      className,
      user,
      walkthroughsCount
    } = this.props;
    const { companies } = user;
    const myCialAdminDomain = MyCialAdminDomains[process.env.REACT_APP_ENV];
    const showWalkthroughResetBtn = walkthroughsCount > 0;

    const headerTitle = (
      <Breadcrumb>
        {headerBreadcrumbs.map((breadcrumb, index) => {
          const noTranslateAttr = breadcrumb.notranslate && {
            notranslate: 'true'
          };
          if (headerBreadcrumbs.length !== index + 1) {
            return (
              <LinkContainer
                to={breadcrumb.path}
                key={index}
                {...noTranslateAttr}
              >
                <Breadcrumb.Item>{breadcrumb.title}</Breadcrumb.Item>
              </LinkContainer>
            );
          }
          return (
            <Breadcrumb.Item active key={index} {...noTranslateAttr}>
              {breadcrumb.title}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );

    return (
      <Navbar
        className={`Header navbar-inverse ${className} ${
          sidebarOpened ? '' : 'Header-wide'
        }`}
        fluid
      >
        <Navbar.Header>
          <div className="visible-xs">
            <Link to="/">
              <img src={logoColor} alt="MyCIAL" className="Logo-xs" />
            </Link>
          </div>
          {showSidebarToggle && (
            <div className="hidden-xs">
              <i className="icon-ic_hamburger" onClick={toggleSidebar} />
              {headerTitle}
            </div>
          )}
        </Navbar.Header>
        <Navbar.Toggle />
        <Navbar.Collapse>
          {companies && (
            <MobileNavbar
              adminLink={myCialAdminDomain}
              name={user.getName()}
              companies={companies}
              user={user}
              doLogout={this.doLogout}
            />
          )}

          <Nav className="Header-nav hidden-xs" pullRight>
            <Nav
              className={classnames('Walkthrough-resetBtn', {
                show: showWalkthroughResetBtn
              })}
            >
              <NavItem onClick={this.handleShowWalkthrough}>Show help</NavItem>
            </Nav>
            <LanguageComponent onLanguageSelect={this.handleOnLanguageSelect} />
            <NavDropdown
              pullRight
              title={user.getName()}
              id="user-dropdown"
              notranslate="true"
            >
              <UserHeader />
              <UserContent user={user} />

              {companies && (
                <React.Fragment>
                  {!isEmpty(companies) && <CompanyHeader />}
                  {companies.map((companyInfo, index) => (
                    <CompanyContent key={index} company={companyInfo.company} />
                  ))}
                </React.Fragment>
              )}
              <MenuItem id="logout" onClick={this.doLogout}>
                Log out
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  const {
    user: { user },
    ui: { headerBreadcrumbs } = {}
  } = state;

  return {
    user,
    headerBreadcrumbs,
    walkthroughsCount: getMountedWalkthroughsCount(state)
  };
}

const mapDispatchToProps = {
  dispatchLogout: logout,
  dispatchChangeAccountSettings: changeAccountSettings,
  dispatchClearCompletedWalkthroughSteps: clearCompletedWalkthroughSteps
};

AuthorizedHeader.propTypes = {
  user: PropTypes.shape({
    info: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string
    }),
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        company: PropTypes.shape({
          legal_name: PropTypes.string.isRequired,
          image: PropTypes.string
        })
      })
    ),
    supplier360: PropTypes.shape({
      bir: PropTypes.shape({
        used: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired
      }),
      score: PropTypes.shape({
        used: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired
      }),
      product: PropTypes.string.isRequired
    })
  }),
  sidebarOpened: PropTypes.bool,
  showSidebarToggle: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  headerBreadcrumbs: PropTypes.any,
  className: PropTypes.string
};

AuthorizedHeader.defaultProps = {
  sidebarOpened: true,
  showSidebarToggle: true,
  headerBreadcrumbs: [],
  className: ''
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedHeader);
