import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Util } from 'react-common/services/index';
import { DunsNumberAbbr } from 'react-common/components';
import './CompanyProfile.css';

const scrollNavLinks = ['General Information', 'Company Control', 'Operations'];

class CompanyProfile extends Component {
  state = {
    activeScrollIndex: 0
  };

  handleScrollClick = (reference, index) => {
    const { onScroll } = this.props;
    this.setState({ activeScrollIndex: index });
    onScroll(reference);
  };

  render() {
    const { company, udm, scrollRef } = this.props;

    return (
      <Row className="CompanyProfile">
        <Col sm={9}>
          <div className="panel Company GeneralInformation">
            <h2 className="panel-heading Title-table">General Information</h2>
            <div className="panel-content Content-table-last">
              <div className="align-items-center">
                <div>
                  {company.getLogo() && (
                    <img
                      className="User-image"
                      src={company.getLogo()}
                      alt={udm.getLegalName()}
                    />
                  )}
                  {!company.getLogo() && (
                    <div className="Colored-circle">
                      <var>{Util.getInitials(udm.getLegalName())}</var>
                    </div>
                  )}
                </div>
                <div className="Company-details" notranslate="true">
                  <div className="align-items-center justify-content-space-between">
                    <h4>{company.getLegalName()}</h4>
                    {udm.getStatus() && (
                      <div className="badges">
                        <div
                          className={
                            udm.getStatus() !== 'out_of_business'
                              ? 'badge active'
                              : 'badge'
                          }
                        >
                          {udm.getStatus().replace(/_/g, ' ')}
                        </div>
                      </div>
                    )}
                  </div>
                  {udm.getCommercialNames() && (
                    <p>
                      <strong>Trade Name(s): </strong>
                      <span>{udm.getCommercialNames().join(', ')}</span>
                    </p>
                  )}
                  {udm.getAddress() && (
                    <p>
                      <strong>Address: </strong>
                      <span>{udm.getFullAddress()}</span>
                    </p>
                  )}
                </div>
              </div>
              <hr />
              <Row>
                <Col sm={4}>
                  <label>
                    <DunsNumberAbbr />:
                  </label>
                  <span>{udm.getDunsNumber()}</span>
                </Col>
                {udm.getTaxIds() && (
                  <Col sm={4}>
                    <label>Tax ID {udm.getTaxIds()[0].type}:</label>
                    <span>
                      {udm.getTaxIds().map((taxId, index) => (
                        <div key={index}>
                          <span>{taxId.value}</span>
                          <br />
                        </div>
                      ))}
                    </span>
                  </Col>
                )}
                {udm.getCompanyEmails() && (
                  <Col sm={4}>
                    <label>Company email address:</label>
                    <span>
                      {udm.getCompanyEmails().map((email, index) => (
                        <div key={index}>
                          <span>{email.address}</span>
                          <br />
                        </div>
                      ))}
                    </span>
                  </Col>
                )}
                {udm.getTelephones() && (
                  <Col sm={4}>
                    <label>Telephone(s):</label>
                    {udm.getTelephones().map((telephone, index) => (
                      <div key={index}>
                        <span>{telephone.number}</span>
                        <br />
                      </div>
                    ))}
                  </Col>
                )}
                {udm.getFoundationDate() && (
                  <Col sm={4}>
                    <label>Foundation Date:</label>
                    <span>{udm.getFoundationDate()}</span>
                  </Col>
                )}
                {udm.getEmployeeCount() && (
                  <Col sm={4}>
                    <label>Employee count:</label>
                    <span>{udm.getEmployeeCount()}</span>
                  </Col>
                )}
              </Row>
              <div className="gmap-embed">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3286.602120473279!2d-58.467909184275975!3d-34.53830718047646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb6a11f9db5af%3A0x928323fde358ab1f!2sPiso+17%2C+Arias+1639%2C+C1429DWA+CABA%2C+Argentina!5e0!3m2!1sen!2shr!4v1554728953628!5m2!1sen!2shr"
                  width="100%"
                  height="250"
                  frameBorder="0"
                  title="Map"
                  allowFullScreen
                />
              </div>
            </div>
          </div>

          {udm.getFinancialStrength() && (
            <div className="panel Company CompanyRating">
              <h2 className="panel-heading Title-table">Company Rating</h2>
              <div className="panel-content Content-table-last">
                <Row>
                  <Col sm={6}>
                    <div className="align-items-center justify-content-space-between">
                      <h5>Commercial score</h5>
                      <div className="badge danger">Average</div>
                    </div>
                    <div className="badges badges-5">
                      <div className="badge">1</div>
                      <div className="badge">2</div>
                      <div className="badge danger">3</div>
                      <div className="badge">4</div>
                      <div className="badge">5</div>
                    </div>
                    <div className="align-items-center justify-content-space-between">
                      <p className="light-text">Low score (1)</p>
                      <p className="light-text">High score (5)</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}

          <div className="panel Company CompanyControl">
            <h2 className="panel-heading Title-table">Company Control</h2>
            <div className="panel-content Content-table-last">
              {udm.getPeople() && (
                <Row>
                  <Col sm={6}>
                    <h5>People</h5>
                    {udm.getPeople().map((people, index) => (
                      <div key={index}>
                        <span>{people.forename} </span>
                        <span>{people.surname}</span>
                        <br />
                      </div>
                    ))}
                  </Col>
                </Row>
              )}
              {udm.getShareholders() && (
                <Row>
                  <Col xs={12}>
                    <h5>Shareholders</h5>
                  </Col>
                  <Col sm={4}>
                    Le chart.js
                    {/* Chart.js goes here BUT leave this for last, it's going to be complicated */}
                  </Col>
                  <Col sm={8}>
                    <span>Amy Shareholder</span>
                    <br />
                    <span>Amy Shareholder</span>
                    <br />
                    <span>Amy Shareholder</span>
                    <br />
                    {/* This is an array, UDM can have multiple values */}
                  </Col>
                </Row>
              )}
            </div>
          </div>

          {udm.getIndustryCodes() && (
            <div className="panel Company Operations">
              <h2 className="panel-heading Title-table">Operations</h2>
              <div className="panel-content Content-table-last">
                <label>Line of business:</label>
                <ul>
                  {udm.getIndustryCodes().map((iCodes, index) => (
                    <li key={index}>
                      {iCodes.type}, {iCodes.code}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Col>
        <Col sm={3} className="inpage-nav hidden-xs">
          <ul>
            {scrollNavLinks.map((navLink, index) => (
              <li
                className={
                  this.state.activeScrollIndex === index ? 'active-li' : ''
                }
                key={index}
              >
                <div
                  ref={scrollRef}
                  onClick={scrollRef =>
                    this.handleScrollClick(scrollRef, index)
                  }
                >
                  {navLink}
                </div>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    );
  }
}

export default CompanyProfile;
