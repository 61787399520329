import React, { Component } from 'react';
import { SingleModal } from 'react-common/components';
import ChangesContainer from '../Changes/ChangesContainer';
import CreateUniverseContainer from '../CreateUniverse/CreateUniverseContainer';
import CompanyInfo from './CompanyInfo/CompanyInfoContainer';

import './SingleUniverse.css';

class SingleUniverse extends Component {
  state = {
    shouldShowDeleteModal: false
  };

  toggleDeleteUniverseContainer = () => {
    this.setState({
      shouldShowDeleteModal: !this.state.shouldShowDeleteModal
    });
  };

  render() {
    const { universe, deleteUniverse, removeDuns } = this.props;
    return (
      <div className="SingleUniverse">
        <div>
          <h1>{universe.name}</h1>

          <div className="row">
            <div className="col-xs-12 col-md-6">
              <CreateUniverseContainer selectedUniverseId={universe['_id']} />
            </div>
            <div className="col-xs-12 col-md-6">
              <button
                className="Btn-default Btn-default-neutral"
                onClick={this.toggleDeleteUniverseContainer}
              >
                Delete List
              </button>
            </div>
          </div>
        </div>

        <div className="info-container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="company-container">
                <div className="Title-table">
                  <b>Monitored Companies</b>
                </div>
                <div className="Content-table-last">
                  {universe.duns.map(duns => (
                    <div className="company-info" key={duns}>
                      <CompanyInfo duns={duns} removeDuns={removeDuns} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              <div className="Changes">
                <div className="Title-table">
                  <b>Latest Changes</b>
                </div>
                <div className="Content-table-last">
                  {universe.duns.map(duns => (
                    <ChangesContainer duns={duns} key={duns} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SingleModal
          title={`Delete Monitoring List - ${universe.name}`}
          body="Are you sure you want to delete this monitoring list? You won't receive any more updates."
          primaryButtonClassName="Btn-default-danger"
          show={this.state.shouldShowDeleteModal}
          handleClose={this.toggleDeleteUniverseContainer}
          handleSubmit={() => deleteUniverse(universe['_id'])}
        />
      </div>
    );
  }
}

export default SingleUniverse;
