import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table } from 'react-bootstrap';
import { RadioButton } from 'react-common/components';
import { getFullAddress } from '../../../_utils/string';

class ClaimResults extends Component {
  state = {
    selectedResult: undefined
  };

  selectResult = id => {
    this.setState({ selectedResult: id });
  };

  claim = () => {
    const { onSelect, results } = this.props;
    const { selectedResult } = this.state;
    const company = results.find(r => r.id === selectedResult);
    if (company && onSelect) {
      onSelect(company);
    }
  };

  renderResult = result => {
    const { selectedResult } = this.state;
    const isSelected = result.id === selectedResult;

    return (
      <tr
        key={result.id}
        className={classnames({ active: isSelected })}
        onClick={() => this.selectResult(result.id)}
      >
        <td className="td-radio">
          <RadioButton readOnly type="radio" checked={isSelected} label="" />
        </td>
        <td className="td-legalName">{result.legal_name}</td>
        <td className="td-address">
          {getFullAddress(result.address.main || result.address)}
        </td>
        <td className="td-Action">
          {isSelected && (
            <div className="Action">
              <button
                type="button"
                className="Btn-default Btn-default-primary"
                onClick={this.claim}
              >
                Claim
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  };

  render() {
    const { results } = this.props;

    return (
      <div className="SearchResults">
        <Table responsive>
          <thead>
            <tr>
              <th />
              <th>Company Name</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>{results.map(this.renderResult)}</tbody>
        </Table>
      </div>
    );
  }
}

ClaimResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      duns: PropTypes.string,
      legal_name: PropTypes.string,
      status: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        street: PropTypes.string,
        municipality: PropTypes.string
      })
    })
  )
};

ClaimResults.defaultProps = { results: [] };

export default ClaimResults;
