import { Fetch } from 'react-common/services';
import moment from 'moment';

class DbiaService {
  getCountryProducts(countryCode) {
    return Fetch.getJSON(`/api/dbia/products/${countryCode}`);
  }

  getCompany(countryCode, dunsNumber) {
    return Fetch.getJSON(`/api/dbia/companies/${countryCode}/${dunsNumber}`);
  }

  getDbiaReport(orderId) {
    return Fetch.get(`/api/dbia/orders/${orderId}/report`);
  }

  getOrders() {
    return Fetch.getJSON(`/api/dbia/orders`);
  }

  getOrdersWithQuery({ company, product, language } = {}) {
    return Fetch.getJSON(
      `/api/dbia/orders?duns=${company.Duns}&productCode=${encodeURIComponent(
        product.Code
      )}&language=${language.Code}`
    );
  }

  createOrder({
    reportType,
    countryCode,
    dunsNumber,
    companyName,
    deliveryCode,
    language,
    emails,
    forceInvestigation,
    doNotInvestigate,
    city,
    state,
    postcode,
    phoneNumber,
    comments,
    shareholders
  }) {
    return Fetch.postJSON(`/api/dbia/orders`, {
      reportType,
      countryCode,
      dunsNumber,
      companyName,
      deliveryCode,
      language,
      emails,
      forceInvestigation,
      doNotInvestigate,
      city,
      state,
      postcode,
      phoneNumber,
      comments,
      shareholders
    });
  }

  emailReport(orderId, emails) {
    return Fetch.postJSON(`/api/dbia/orders/${orderId}/report/send`, {
      emails
    });
  }

  getAccount() {
    return Fetch.getJSON(`/api/dbia/account`);
  }

  /**
   * Fetch transaction data from DBIA API.
   * Parameters are standard date string that can be read with momentjs.
   * DBI API accepts the dates in the 'YYYY/MM/DD' format hence the conversion.
   * @param {string} fromDate
   * @param {string} toDate
   */
  getTransactions(fromDate, toDate) {
    const DBIA_API_DATE_FORMAT = 'YYYY/MM/DD';
    const from = moment(fromDate).format(DBIA_API_DATE_FORMAT);
    const to = moment(toDate).format(DBIA_API_DATE_FORMAT);

    return Fetch.getJSON(
      `/api/dbia/transactions?fromDate=${encodeURIComponent(
        from
      )}&toDate=${encodeURIComponent(to)}`
    );
  }
}

export default new DbiaService();
