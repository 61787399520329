import merge from 'lodash/merge';
import first from 'lodash/first';
import get from 'lodash/get';

export class UDM {
  constructor(udm = {}) {
    merge(this, udm);
  }

  getId() {
    return this._id;
  }

  getLegalName() {
    return get(this, 'legal_name');
  }

  getStatus() {
    return get(this, 'status');
  }

  getCommercialNames() {
    return get(this, 'commercial_names');
  }

  getAddress() {
    return get(this, 'address.main', {});
  }

  getFullStreetName() {
    return [
      this.getAddress()._meta_street_type,
      this.getAddress()._meta_street_name
    ]
      .filter(Boolean)
      .join(' ');
  }

  getAddressLine1() {
    const address = this.getAddress();

    const manuallyConstructed = [
      this.getFullStreetName(),
      address._meta_street_number
    ]
      .filter(Boolean)
      .join(' ');

    // It's done like this coz of 2 problems Autobahn/UDM currently has:
    // .address_line_1 for BRA and ARG === ._meta_street_name
    // ._meta_* fields don't exist in non-key markets (CCS; except PER & CHL)
    return manuallyConstructed || address.address_line_1;
  }

  getAddressLine2() {
    return this.getAddress().address_line_2;
  }

  getShortAddress() {
    return [this.getAddressLine1(), this.getAddressLine2()]
      .filter(Boolean)
      .join(', ');
  }

  getFullAddress() {
    return [
      this.getShortAddress(),
      this.getAddressNeighborhood(),
      this.getCity()
    ]
      .filter(Boolean)
      .join(', ');
  }

  getCountry() {
    return this.getAddress().country;
  }

  getCity() {
    const country = this.getCountry();
    switch (country) {
      case 'BRA':
        return this.getAddress().municipality;
      case 'PER':
      case 'ECU':
      case 'BOL':
        return this.getAddress().commune || this.getAddress().city;
      case 'MEX':
      default:
        return this.getAddress().city;
    }
  }

  getAddressNeighborhood() {
    return this.getAddress().neighborhood;
  }

  getArea() {
    const address = this.getAddress();

    return (
      address.state || address.region || address.province || address.department
    );
  }

  getDunsNumber() {
    return get(this, 'duns');
  }

  getTaxIds() {
    return get(this, 'tax_ids');
  }

  getCompanyEmails() {
    return get(this, 'emails');
  }

  getTelephones() {
    return get(this, 'telephones', []);
  }

  getPhone() {
    return first(this.getTelephones());
  }

  getFoundationDate() {
    return get(this, 'established_at');
  }

  getEmployeeCount() {
    return get(this, 'employee_count.total.amount');
  }

  getPeople() {
    return get(this, 'people');
  }

  getShareholders() {
    return get(this, 'shareholders');
  }

  getFinancialStrength() {
    return get(this, 'scores.dnb.financial_strength');
  }

  getIndustryCodes() {
    return get(this, 'industry_codes');
  }
}
