import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserProfile from './UserProfile';

import { changeAccountSettings, apiReset } from '../../_actions';
import { phoneInfoToString } from '../../_utils/string';

class UserProfileContainer extends Component {
  componentWillUnmount() {
    const { dispatchApiReset } = this.props;
    dispatchApiReset(changeAccountSettings.name);
  }

  submitAccount = account => {
    const { dispatchChangeAccountSettings } = this.props;
    const { name, image, phone_work, phone_ext } = account.info;
    const phone = phoneInfoToString({
      phone: phone_work,
      ext: phone_ext
    });

    return dispatchChangeAccountSettings({
      info: {
        name,
        image,
        phone
      }
    });
  };

  render() {
    return <UserProfile onSubmit={this.submitAccount} {...this.props} />;
  }
}

function mapStateToProps(state) {
  const {
    user: { user },
    form: { user: { values } = {} } = {}
  } = state;
  const { accountChange } = state.api;

  const initialValues = cloneDeep(user);

  return {
    accountChange,
    initialValues: initialValues,
    formValues: values,
    language: user.getLanguage()
  };
}

const mapDispatchToProps = {
  dispatchApiReset: apiReset,
  dispatchChangeAccountSettings: changeAccountSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);
