export const stringToPhoneInfo = phoneNumString => {
  // default parameter doesn't work for null
  phoneNumString = phoneNumString || '';
  const matches = phoneNumString.split(/(\s+)ext/);

  return {
    phone: matches[0],
    ext: matches[2]
  };
};

export const phoneInfoToString = ({ phone, ext }) => {
  if (ext) {
    return `${phone} ext${ext}`;
  }

  return phone;
};

export const capitalize = str => {
  str = str.replace('_', ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// TODO: create udm-company class and move there
export const getFullAddress = a => {
  return [a.address_line_1, a.address_line_2, a.city, a.country]
    .filter(Boolean)
    .join(', ');
};
