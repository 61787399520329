import React, { Component } from 'react';
import { connect } from 'react-redux';
import Password from './Password';
import { changePassword } from '../../_actions';

class PasswordContainer extends Component {
  onChangePassword = resetPasswordData => {
    const { dispatchChangePassword } = this.props;
    return dispatchChangePassword(
      resetPasswordData.oldPassword,
      resetPasswordData.password
    );
  };

  render() {
    const { resetPassword } = this.props;
    return (
      <Password
        resetPassword={resetPassword}
        onSubmit={this.onChangePassword}
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchChangePassword: changePassword
};

export default connect(
  null,
  mapDispatchToProps
)(PasswordContainer);
