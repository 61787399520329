import { Fetch } from 'react-common/services';
import queryString from 'query-string';

class SearchService {
  searchCompanies(params) {
    const query = queryString.stringify(params);
    return Fetch.get('/api/search/companies?' + query);
  }
}

export default new SearchService();
