import uiConstants, { urlConstants } from '../_constants/ui';
import dunsRequestConstants from '../_constants/dunsRequest';
import updateRequestConstants from '../_constants/updateRequest';
import claimConstants from '../_constants/claim';
import { isPendingDuns } from '../_selectors/dunsRequests';
import { isPendingClaim } from '../_selectors/claims';
import { isDraftUpdateRequest } from '../_selectors/updateRequests';

export function ui(
  state = {
    headerBreadcrumbs: [],
    initialUrl: urlConstants.HOME,
    dunsRequest: {
      files: {}
    },
    completedWalkthroughSteps: [],
    mountedWalkthroughsCount: 0
  },
  action
) {
  switch (action.type) {
    case uiConstants.UI_HEADER_BREADCRUMBS_UPDATE:
      return {
        ...state,
        headerBreadcrumbs: action.headerBreadcrumbs
      };
    case uiConstants.UI_DUNS_REQUEST_FILE_UPLOAD_START:
      return {
        ...state,
        dunsRequest: {
          ...state.dunsRequest,
          files: {
            ...state.dunsRequest.files,
            [action.fileName]: {
              uploading: true
            }
          }
        }
      };
    case uiConstants.UI_DUNS_REQUEST_FILE_UPLOAD_END:
      return {
        ...state,
        dunsRequest: {
          ...state.dunsRequest,
          files: {
            ...state.dunsRequest.files,
            [action.fileName]: {
              ...state.dunsRequest.files[action.fileName],
              uploading: false
            }
          }
        }
      };
    case dunsRequestConstants.UPLOAD_DUNS_REQUEST_FILE:
      const files = action.data.files && action.data.files._files;
      const fileMap = !files
        ? {}
        : files.reduce((acc, file) => {
            acc[file.type] = { file, uploading: false };
            return acc;
          }, {});

      return {
        ...state,
        dunsRequest: {
          ...state.dunsRequest,
          files: {
            ...state.dunsRequest.files,
            ...fileMap
          }
        }
      };

    case uiConstants.INITIAL_URL:
      return {
        ...state,
        initialUrl: action.url
      };

    case uiConstants.LOCATION_CHANGE:
      return {
        ...state,
        initialUrl:
          state.initialUrl &&
          state.initialUrl.indexOf(action.payload.location.pathname) > -1
            ? ''
            : state.initialUrl
      };

    case claimConstants.GET_CLAIMS_FOR_ACCOUNT:
      return {
        ...state,
        initialUrl:
          action.data && action.data.length && isPendingClaim(action.data[0])
            ? urlConstants.CLAIM
            : state.initialUrl
      };

    case dunsRequestConstants.GET_DUNS_REQUESTS_FOR_ACCOUNT:
      return {
        ...state,
        initialUrl:
          action.data && action.data.length && isPendingDuns(action.data[0])
            ? urlConstants.DUNS
            : state.initialUrl
      };

    case updateRequestConstants.GET_UPDATE_REQUESTS_FOR_ACCOUNT:
      return {
        ...state,
        initialUrl:
          action.data &&
          action.data.length &&
          isDraftUpdateRequest(action.data[0])
            ? urlConstants.UPDATE
            : state.initialUrl
      };

    case uiConstants.UI_WALKTHROUGH_REFRESH_COMPLETED_STEPS:
      return {
        ...state,
        completedWalkthroughSteps: action.completedSteps
      };

    case uiConstants.UI_WALKTHROUGH_SAVE_COMPLETED_STEP:
      const completedSteps = state.completedWalkthroughSteps;
      const alreadySaved = !!completedSteps.find(
        stepId => stepId === action.stepId
      );

      return {
        ...state,
        completedWalkthroughSteps: alreadySaved
          ? completedSteps
          : completedSteps.concat(action.stepId)
      };

    case uiConstants.UI_WALKTHROUGH_CLEAR_COMPLETED_STEPS:
      return {
        ...state,
        completedWalkthroughSteps: []
      };

    case uiConstants.UI_WALKTHROUGH_MOUNTED:
      return {
        ...state,
        mountedWalkthroughsCount: state.mountedWalkthroughsCount + 1
      };

    case uiConstants.UI_WALKTHROUGH_UNMOUNTED:
      return {
        ...state,
        mountedWalkthroughsCount: state.mountedWalkthroughsCount - 1
      };

    default:
      return state;
  }
}
