import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getAdminUpdateRequestById,
  updateAdminUpdateRequestById
} from '../_actions/updateRequests';
import {
  getUpdateRequest,
  getUpdateRequestFiles
} from '../_selectors/updateRequests';
import { getUser } from '../_selectors/user';

import get from 'lodash/get';
import { BirFormContainer } from 'react-common/components';

class AdminUpdateRequest extends Component {
  state = {
    updated: false
  };

  componentDidMount() {
    const {
      dispatchGetUpdateRequestById,
      updateRequestId,
      accountId
    } = this.props;

    if (updateRequestId && accountId) {
      dispatchGetUpdateRequestById(updateRequestId, accountId);
    }
  }

  onSubmit = updateRequestData => {
    this.setState({ updated: false });
    const {
      dispatchUpdateUpdateRequest,
      updateRequestId,
      accountId
    } = this.props;
    const data = { company: updateRequestData };

    return dispatchUpdateUpdateRequest(updateRequestId, data, accountId, () =>
      this.setState({ updated: true })
    );
  };

  onChange = (field, data) => {
    const {
      company,
      dispatchUpdateUpdateRequest,
      updateRequestId,
      accountId
    } = this.props;

    return dispatchUpdateUpdateRequest(
      updateRequestId,
      { company: { ...company, [field]: data } },
      accountId
    );
  };

  render() {
    const { updateRequest, company, files, currentStep, user } = this.props;

    if (!updateRequest) {
      return <div> UPDATE REQUEST NOT FOUND </div>;
    }

    let initialValues = { ...company };
    for (let key in files) {
      initialValues[key] = files[key];
    }

    return (
      <Fragment>
        <BirFormContainer
          initialValues={initialValues}
          country="CIALOnlineInvestigation"
          onChange={this.onChange}
          onStepSubmit={() => {}}
          onSubmit={this.onSubmit}
          currentStep={currentStep}
          hideSubmitButton={false}
          files={files}
          language="pt-BR"
        />
        {this.state.updated && (
          <div className="text-success">Request successfully updated</div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const urlParams = new URLSearchParams(window.location.search);
  const updateRequestId = urlParams.get('updateRequestId');
  const accountId = urlParams.get('accountId');
  const updateRequest = getUpdateRequest(state);

  return {
    updateRequestId,
    accountId,
    updateRequest,
    company: get(updateRequest, 'company', {}),
    files: getUpdateRequestFiles(state),
    currentStep: get(updateRequest, 'step', ''),
    user: getUser(state)
  };
};

const mapDispatchToProps = {
  dispatchGetUpdateRequestById: getAdminUpdateRequestById,
  dispatchUpdateUpdateRequest: updateAdminUpdateRequestById
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUpdateRequest);
