import '@babel/polyfill';
import 'react-app-polyfill/ie9';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'open-sans-all';
import 'react-common/index.css';
import './_css/fontello.css';
import './index.css';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App/App';
import ErrorFallback from './_components/ErrorFallback/ErrorFallback';
import * as serviceWorker from './serviceWorker';

export let bugsnagClient;
let appComponent = <App />;

if (process.env.REACT_APP_BUGSNAG_KEY) {
  bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    releaseStage: process.env.REACT_APP_ENV
  });
  bugsnagClient.use(bugsnagReact, React);
  const ErrorBoundary = bugsnagClient.getPlugin('react');

  appComponent = (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  );
}

ReactDOM.render(appComponent, document.getElementById('root'));
serviceWorker.register();
