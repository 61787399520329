import { combineReducers } from 'redux';
import { reducer as formReducer } from 'react-common/libs/reduxForm';
import { connectRouter } from 'connected-react-router';

import { enums } from './enums';
import { user } from './user';
import { company } from './company';
import { ui } from './ui';
import { nextIdentify } from './nextIdentify';
import { search } from './search';
import { birRequests } from './birRequests';
import { dunsRequests } from './dunsRequest';
import { updateRequests } from './updateRequests';
import { claims } from './claim';
import { api } from './api';
import { workflows } from './workflow';
import { universe } from './universe';
import { dbia } from './dbia';

export default history =>
  combineReducers({
    enums,
    user,
    company,
    ui,
    nextIdentify,
    dunsRequests,
    birRequests,
    updateRequests,
    claims,
    search,
    api,
    workflows,
    universe,
    dbia,
    form: formReducer,
    router: connectRouter(history)
  });
