import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { reduxForm } from 'react-common/libs/reduxForm';
import {
  CountryDropdownField,
  TextField,
  DropdownField,
  Loader
} from 'react-common/components';
import { Validation } from 'react-common/services';

const InitialInvestigationForm = ({
  user,
  disableSubmit,
  handleSubmit,
  handleCancel,
  loadingProducts,
  onCountryChange,
  productOptions,
  languageOptions
}) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12}>
        <h4>Place an investigation</h4>
      </Col>
      <Col md={6}>
        <TextField
          validate={[Validation.required]}
          label="Company Name*"
          name="companyName"
          placeholder="Enter Company Name"
        />
        <TextField label="Tax ID" name="taxId" placeholder="Enter Tax ID" />
        <CountryDropdownField
          onChange={onCountryChange}
          validate={[Validation.required]}
          label="Country*"
          name="country"
          placeholer="Select one of the following"
          user={user}
        />
        <div style={{ position: 'relative' }}>
          <Loader loading={loadingProducts || false} withOverlay />
          <DropdownField
            isDisabled={!productOptions.length}
            options={productOptions}
            validate={[Validation.required]}
            label="Product*"
            name="product"
            placeholder={
              productOptions.length
                ? 'Select product'
                : 'Please select country first'
            }
          />
          <DropdownField
            isDisabled={!productOptions.length}
            options={languageOptions}
            validate={[Validation.required]}
            label="Product language*"
            name="productLanguage"
            placeholder={
              languageOptions.length
                ? 'Select product language'
                : 'Please select country first'
            }
          />
        </div>
        <TextField
          validate={[Validation.required]}
          label="City*"
          name="city"
          placeholder="Enter City"
        />
        <TextField label="State" name="state" placeholder="Enter State" />
        <TextField
          label="Postal Code"
          name="postcode"
          placeholder="Enter Postal Code"
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          placeholder="Enter Phone Number"
        />
        <div className="form-group">
          <button
            disabled={disableSubmit}
            className="Btn-default Btn-default-primary"
            type="submit"
          >
            Place an investigation
          </button>
          <button
            className="Btn-default Btn-default-neutral"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </Col>
    </Row>
  </form>
);

InitialInvestigationForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  handleCancel: PropTypes.func
};

InitialInvestigationForm.defaultProps = {
  productOptions: [],
  languageOptions: []
};

export default reduxForm({
  form: 'initialInvestigation',
  enableReinitialize: true
})(InitialInvestigationForm);
