import React, { Component } from 'react';
import { Sidebar } from 'react-common/components';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'react-common/libs/reduxForm';

import PublicHeader from '../_components/Header/PublicHeader';
import logo from '../_images/mycial-logo.svg';
import logo2 from '../_images/mycial-logo-color.svg';

import './ForgotPassword.css';

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className="ForgotPassword">
        <Sidebar
          logo={logo}
          logoAlt="MyCIAL"
          className="col-md-3 hidden-sm hidden-xs"
          headerText="Forgot password?"
          descriptionText="Enter your email address and we will help you create a new password"
          showContact={true}
        />
        <div className="col-md-9">
          <div className="row">
            <PublicHeader />
            <div className="col-md-5 col-md-offset-3">
              <form onSubmit={handleSubmit} className="Login-form">
                <div>
                  <img
                    src={logo2}
                    alt="MyCIAL"
                    className="logo2 visible-xs visible-sm"
                  />
                </div>
                <div className="form-group enter-email">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    className="form-control Login-input"
                    name="email"
                    component="input"
                    placeholder="Enter your email"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="Btn-default Btn-default-primary form-control"
                  >
                    Request link for a new password
                  </button>
                </div>
                <div className="form-group back-login">
                  Already have an account?&nbsp;
                  <Link className="login-link" to="/login">
                    Log in
                  </Link>
                </div>
                <div className="form-group back-login">
                  Don't have an account?&nbsp;
                  <Link className="login-link" to="/register">
                    Sign up now
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ForgotPassword = reduxForm({
  form: 'forgot-password'
})(ForgotPasswordForm);

export default ForgotPassword;
