const dbiaConstants = {
  COUNTRY_PRODUCTS: 'COUNTRY_PRODUCTS',
  PRODUCTS: 'PRODUCTS',
  ORDERS: 'ORDERS',
  ORDER: 'ORDER',
  SHOW_REPORT: 'SHOW_REPORT',
  HIDE_REPORT: 'HIDE_REPORT',
  REPORT: 'REPORT',
  EMAIL_REPORT: 'EMAIL_REPORT',
  ACCOUNT: 'ACCOUNT',
  TRANSACTIONS: 'TRANSACTIONS',
  CHECK: 'CHECK'
};

export const deliveryCodes = {
  VIEW_ON_SCREEN: '0001',
  STORE_ONLY: '0002',
  VIEW_ON_SCREEN_AND_EMAIL: '0003',
  EMAIL: '0006',
  WEBHOOK: '0007'
};

export const productTypes = {
  BIR: 'UBIR', // "D&B Report"
  BIR_OLD: '0011', // "D&B Report"
  CKR: 'SFR', // "Cial Key Report" (a.k.a. short form)
  SQR: 'SQR', // "Supplier Qualifier Report"
  CAR: 'CAR', // "Credit Analysis Report"
  RAF: '0016', // "RAF"
  RAC: '0114', // "RAC"
  COMPREHENSIVE: '0120', // "D&B Comprehensive Report",
  CFR: 'CFR' // "Compliance Findings Report"
};

export default dbiaConstants;
