import merge from 'lodash/merge';
import get from 'lodash/get';
import first from 'lodash/first';
import { supplierStatuses } from 'react-common/constants';

export class Workflow {
  constructor(workflow = {}) {
    merge(this, workflow);
  }

  getId() {
    return this._id;
  }

  getSuppliers() {
    return get(this, 'suppliers');
  }

  getOwnerCompanyName() {
    return get(this, 'owner.company.name');
  }

  hasSuppliers() {
    const suppliers = this.getSuppliers();
    return suppliers && suppliers.length > 0;
  }

  hasCompleted() {
    return (
      this.hasSuppliers() &&
      first(this.getSuppliers()).status === supplierStatuses.COMPLETED
    );
  }
}
