export const getUniverse = state => state.universe.items;
export const getUniverseById = (state, universeId) => {
  return getUniverse(state) ? getUniverse(state)[universeId] : {};
};

export const getChanges = state => state.universe.changes;
export const getChangesForDuns = (state, duns) => {
  return getChanges(state) ? getChanges(state)[duns] : [];
};

export const getDuns = state => state.universe.duns;
export const getCompanyByDunsNumber = (state, duns) => {
  return getDuns(state) ? getDuns(state)[duns] : {};
};
