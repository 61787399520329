const LOCAL_STORAGE_ITEM_KEY = 'completedWalkthroughSteps';

class WalkthroughService {
  getCompletedSteps() {
    const saveStepsString = window.localStorage.getItem(LOCAL_STORAGE_ITEM_KEY);
    return (saveStepsString && JSON.parse(saveStepsString)) || [];
  }

  hasCompletedStep(stepId) {
    return this.getCompletedSteps().some(savedStepId => savedStepId === stepId);
  }

  saveCompletedSteps(steps) {
    const stepsString = JSON.stringify(steps);
    window.localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, stepsString);
  }

  saveCompletedStep(stepId) {
    const savedSteps = this.getCompletedSteps();

    if (this.hasCompletedStep(stepId)) {
      return;
    }

    this.saveCompletedSteps(savedSteps.concat(stepId));
  }

  clearCompletedSteps() {
    window.localStorage.removeItem(LOCAL_STORAGE_ITEM_KEY);
  }

  filterCompletedSteps(stepArray = [], completedSteps = []) {
    return stepArray.filter(step => !completedSteps.includes(step.stepData.id));
  }

  createJoyrideStep({ target, stepData }) {
    return {
      ...stepData,
      target
    };
  }
}

export default new WalkthroughService();
