import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import imgRecommend from '../../_images/recommend.svg';
import './ProductOptionBox.css';

export const ProductOptionBox = ({
  highlighted,
  disabled,
  green,
  img,
  title,
  body,
  linkTo,
  linkText
}) => (
  <div
    className={classnames('ProductOptionBox', {
      'ProductOptionBox--highlighted': highlighted
    })}
  >
    {highlighted && (
      <img alt="Recommended" src={imgRecommend} className="recommend" />
    )}
    <div className="ProductOptionBox-header">
      <img alt="Product" src={img} />
      <div
        className={classnames('ProductOptionBox-title', {
          'ProductOptionBox-title-green': green
        })}
      >
        {title}
      </div>
    </div>
    <div className="ProductOptionBox-body">{body}</div>
    <div className="ProductOptionBox-button">
      <Link to={linkTo}>
        <button
          disabled={disabled}
          className={classnames('Btn-default', {
            'Btn-default-primary': !green,
            'Btn-default-success': green
          })}
        >
          {linkText}
        </button>
      </Link>
    </div>
  </div>
);

ProductOptionBox.propTypes = {
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  green: PropTypes.bool,
  img: PropTypes.string,
  title: PropTypes.node,
  body: PropTypes.node,
  linkTo: PropTypes.string,
  linkText: PropTypes.node
};

ProductOptionBox.defaultProps = {
  highlighted: false,
  disabled: false,
  green: false
};

export default ProductOptionBox;
