import claimConstants from '../_constants/claim';
import ClaimService from '../_services/Claim';
import { makeRequest } from './api';
import { push } from 'connected-react-router';

export function getClaimsForAccount() {
  return makeRequest({
    type: claimConstants.GET_CLAIMS_FOR_ACCOUNT,
    apiCall: ClaimService.getClaimsForAccount,
    params: []
  });
}

export function claimCompany(company, email, companyPosition) {
  return makeRequest({
    type: claimConstants.CLAIM_COMPANY,
    apiCall: ClaimService.claimCompany,
    params: [company, email, companyPosition]
  });
}

function markAsSeenSuccess() {
  return dispatch => dispatch(push('/dashboard'));
}

export function markClaimRequestAsSeen(id) {
  return makeRequest({
    type: claimConstants.MARK_CLAIM_REQUEST_AS_SEEN,
    apiCall: ClaimService.markAsSeen,
    params: [id],
    onSuccess: markAsSeenSuccess()
  });
}
