import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  getFormValues
} from 'react-common/libs/reduxForm';

import './DbiaConfirmModal.css';

class ScreenModal extends Component {
  renderShareholderInput = ({ fields }) => (
    <>
      <button
        className="icon-ic_plus btn Btn-add-shareholder"
        type="button"
        onClick={() => fields.push('')}
      >
        Add shareholder
      </button>
      {fields.map((field, index) => (
        <Fragment key={index}>
          <Field
            type="text"
            className="form-control AddShareholder-input"
            component="input"
            placeholder="Full Name"
            name={field}
          />
          <i
            className="AddShareholder-btn-delete icon-ic_close"
            type="button"
            onClick={() => fields.remove(index)}
          />
        </Fragment>
      ))}
    </>
  );

  render() {
    const {
      show,
      handleClose,
      onSubmit,
      formValues: { shareholders = [] } = {}
    } = this.props;

    return (
      <Modal className="Modal-screen" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="ModalTitle">Order report</Modal.Title>
          <i
            className="icon-ic_close pull-right Close-modal-icon"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="Info-text">
            <p>Add shareholders you want to screen. </p>
          </div>

          <div className="AddShareholderRow">
            <FieldArray
              name="shareholders"
              component={this.renderShareholderInput}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="pull-right">
            <button
              type="button"
              className="Btn-default-small Btn-default-neutral"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="Btn-default-small Btn-default-success"
              onClick={() => {
                handleClose();
                onSubmit(shareholders.filter(Boolean));
              }}
            >
              Order report
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('screening-shareholders')(state)
});

const AddShareholderForm = reduxForm({
  form: 'screening-shareholders'
})(ScreenModal);

export default connect(mapStateToProps)(AddShareholderForm);
