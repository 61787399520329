import React, { Component } from 'react';
import { Sidebar } from 'react-common/components';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { updateHeaderBreadcrumbs } from '../_actions';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import PublicHeader from '../_components/Header/PublicHeader';

import logo from '../_images/mycial-logo.svg';
import logoColor from '../_images/mycial-logo-color.svg';
import './Contact.css';

const ContactItem = ({ title, values }) => (
  <div>
    <h4 className="Contact-h4">{title}</h4>
    {values.map((val, index) => (
      <p key={index}>
        <a href={`tel:${val.replace(/\(|\)| |-/g, '')}`}>{val}</a>
      </p>
    ))}
  </div>
);

class Contact extends Component {
  contactData = {
    argentina: {
      'Tel:': ['+54 11 5984 2700']
    },
    bolivia: {
      'Tel:': ['+511 7063200']
    },
    brazil: {
      'Tel:': ['+55 11 4933-7500']
    },
    chile: {
      'Tel:': ['+54 11 5984 2700']
    },
    ecuador: {
      'Tel:': ['+511 7063200']
    },
    mexico: {
      'Tel:': ['+52 55 4166-3000']
    },
    paraguay: {
      'Tel:': ['+54 11 5984 2700']
    },
    peru: {
      'Tel:': ['+511 7063200']
    },
    uruguay: {
      'Tel:': ['+54 11 5984 2700']
    },
    other: {
      'Tel:': ['+1 954-507-1116']
    }
  };

  state = {
    country: 'argentina',
    contactInfo: this.contactData.argentina
  };

  driftApi = null;

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      updateHeaderBreadcrumbs([
        {
          title: 'Contact us'
        }
      ])
    );

    window.analytics &&
      window.analytics.ready &&
      window.analytics.ready(() => {
        window.drift.on('ready', api => (this.driftApi = api));
      });
  }

  changeContactData = country => {
    this.setState({ country: country, contactInfo: this.contactData[country] });
  };

  openChat = () => {
    if (this.driftApi) {
      this.driftApi.sidebar.open();
    }
  };

  render() {
    const contactItems = Object.keys(this.state.contactInfo).map(
      (key, index) => (
        <ContactItem
          key={index}
          title={key}
          values={this.state.contactInfo[key]}
        />
      )
    );

    const contactContent = (
      <div className="col-md-6 col-md-offset-3 col-lg-5 col-lg-offset-3 Contact-form">
        <div>
          <img
            src={logoColor}
            alt="MyCIAL"
            className="logo2 visible-xs visible-sm"
          />
        </div>
        <p className="Title-paragraph">
          Customer Service & Technical Support 24/7
        </p>
        <label htmlFor="country-selector">Choose your country</label>
        <Dropdown
          id="country-selector"
          className="form-control dropdown Dropdown-form-control countries-list"
          onSelect={this.changeContactData}
        >
          <Dropdown.Toggle className="form-control Dropdown-form-control">
            {this.state.country}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem eventKey="argentina">Argentina</MenuItem>
            <MenuItem eventKey="bolivia">Bolivia</MenuItem>
            <MenuItem eventKey="brazil">Brazil</MenuItem>
            <MenuItem eventKey="chile">Chile</MenuItem>
            <MenuItem eventKey="ecuador">Ecuador</MenuItem>
            <MenuItem eventKey="mexico">Mexico</MenuItem>
            <MenuItem eventKey="paraguay">Paraguay</MenuItem>
            <MenuItem eventKey="peru">Peru</MenuItem>
            <MenuItem eventKey="uruguay">Uruguay</MenuItem>
            <MenuItem eventKey="other">Other</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
        {contactItems}
        <hr className="Contact-hr" />

        <h3 className="Title-paragraph">Chat with us</h3>
        <div className="form-group">
          <button
            onClick={this.openChat}
            type="submit"
            className="Btn-default Btn-default-primary form-control"
          >
            Chat now
          </button>
        </div>

        {!Auth.isUserAuthenticated() && (
          <div className="form-group back-login">
            Back to
            <Link className="login-link" to="/login">
              {' '}
              Login{' '}
            </Link>
            <span>
              {' '}
              or{' '}
              <Link className="login-link" to="/login">
                {' '}
                Sign up
              </Link>{' '}
              now
            </span>
          </div>
        )}
      </div>
    );

    if (Auth.isUserAuthenticated()) {
      return <AppWrapper className="Contact">{contactContent}</AppWrapper>;
    }

    return (
      <div className="Contact">
        <Sidebar
          logo={logo}
          logoAlt="MyCIAL"
          className="col-md-3 hidden-sm hidden-xs"
          headerText="Contact us"
          subHeaderText="Questions, Comments or Help?"
        />
        <div className="col-md-9">
          <div className="row">
            <PublicHeader />
            {contactContent}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Contact);
