import { Auth, Fetch, Segment } from 'react-common/services';

class LoginService {
  login(email, password) {
    return Fetch.postJSON('/auth/login', { email, password }).then(response => {
      const { token, user } = response;
      Auth.authenticateUser(token, user);
      return user;
    });
  }

  register(user) {
    return Fetch.postJSON('/auth/register', user);
  }

  resendEmail(accountId) {
    return Fetch.postJSON('/auth/resend-verification', {
      account_id: accountId
    });
  }

  confirmEmail(token) {
    return Fetch.postJSON('/auth/confirm', { token });
  }

  check(email, password) {
    return Fetch.postJSON('/auth/check', { email, password });
  }

  logout() {
    Auth.deauthenticateUser();
    Segment.reset();
  }

  forgotPassword(email) {
    return Fetch.postJSON('/password-reset', { email });
  }

  resetPassword(token, password) {
    return Fetch.postJSON('/password-new', { token, password });
  }
}

export default new LoginService();
